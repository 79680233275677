import React, { useState } from 'react'
import { useMain } from '../../../hooks'
import { SupportView } from './SupportView'
import { TicketingView } from './TicketingView'

const Support = () => {
	const { showTicketingView } = useMain()
	const [view, setView] = useState('create')

	return showTicketingView ? (
		<TicketingView {...{ view, setView }} />
	) : (
		<SupportView {...{ view, setView }} />
	)
}

export default Support
