import React, { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import profile from '../../../../assets/svg/Default_pfp.svg'
import arrow from '../../../../assets/svg/arrow.svg'
import user from '../../../../assets/svg/user.svg'
import { useAuth } from '../../../../hooks'
import d from '../../../../styles/Dashboard.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf } from '../../../../utils'

const SettingsThick = ({ tag, right = false }) => {
	return (
		<span
			className={cf(
				s.wMax,
				s.flex,
				s.flexLeft,
				d.settingsThick,
				right ? d.settingsThickRight : d.settingsThickLeft
			)}
		>
			{tag}
		</span>
	)
}

const SettingsLight = ({ tag }) => {
	return (
		<span className={cf(s.wMax, s.flex, s.flexLeft, d.settingsLight)}>
			{tag}
		</span>
	)
}

const SettingsInfo = ({
	title,
	text,
	val,
	children,
	neutral = false,
	isValRight = false,
}) => {
	return (
		<div className={cf(s.wMax, s.flex, s.flexLeft, d.settingsInfo)}>
			<div
				className={cf(
					s.flex,
					s.flexLeft,
					d.settingsInfoLeft,
					neutral ? d.neutral : ''
				)}
			>
				<SettingsThick {...{ tag: title }} />
				<SettingsLight {...{ tag: text }} />
			</div>
			<div
				className={cf(
					s.flex,
					s.flexLeft,
					d.settingsInfoLeft,
					neutral ? d.neutral : ''
				)}
			>
				{!children ? (
					<SettingsThick {...{ tag: val, right: isValRight }} />
				) : (
					children
				)}
			</div>
		</div>
	)
}

const ChangePfp = ({ pfp, handler }) => {
	const { userDashboard } = useAuth()
	return (
		<FileUploader
			handleChange={handler}
			name={'pfp'}
			fileOrFiles={pfp}
			types={['JPEG', 'JPG', 'PNG']}
			classes={cf(s.flex, s.flexCenter, d.settingsUpload)}
			multiple={false}
			required={false}
			maxSize={5}
		>
			<div className={cf(s.flex, s.flexLeft, d.settingsUploadButton)}>
				<div className={cf(s.flex, s.flexCenter, d.settingsInnerPfpCon)}>
					<img
						src={
							userDashboard.user
								? userDashboard.user.image
									? userDashboard.user.image
									: profile
								: profile
						}
						alt={'settings profile'}
						className={cf(s.flex, s.flexCenter, d.settingsProfileImage)}
					/>
				</div>
				<div className={cf(s.flex, s.flexCenter, d.settingsUploadTag)}>
					<span className={cf(s.flex, s.flexCenter, d.settingUploadTxt)}>
						Upload
					</span>
					<img
						src={arrow}
						alt={'settings profile arrow'}
						className={cf(s.flex, s.flexCenter, d.settingsProfileArrow)}
					/>
				</div>
			</div>
		</FileUploader>
	)
}

const Account = () => {
	const [pfp, setPfp] = useState(profile)
	const {
		userDashboard: ux,
		makeRequest,
		update,
		getUserDetails,
		getUserDashboard,
		userDetails: ud,
	} = useAuth()

	const handler = async (file) => {
		setPfp((x) => file)
		const res = await makeRequest(
			{
				path: 'user/upload',
				method: 'post',
				body: file,
			},
			true
		)
		if (res.data.url) {
			const { id, createdAt, updatedAt, authMode, ...user } = ux.user
			await update(
				{
					...user,
					image: res.data.url,
				},
				false
			)
			getUserDetails()
			getUserDashboard()
		} else {
		}
	}

	return (
		<div className={cf(s.flex, s.flexCenter, d.settingsView)}>
			<div className={cf(s.wMax, s.flex, s.flexLeft, d.settingsProfileCon)}>
				<div
					className={cf(s.flex, s.flexCenter, s.g10, d.settingsProfileIconCon)}
				>
					<img
						src={user}
						alt={'settings profile icon'}
						className={cf(d.settingsProfileIcon)}
					/>
				</div>
				<div
					className={cf(
						s.flex,
						s.flexLeft,
						s.flexOne,
						d.settingsProfileInfoCon
					)}
				>
					<span
						className={cf(s.wMax, s.flex, s.flexLeft, d.settingsProfileTitle)}
					>
						Profile Settings
					</span>
					<span
						className={cf(
							s.wMax,
							s.flex,
							s.flexLeft,
							d.settingsProfileSubTitle
						)}
					>
						To update personal details, please contact us via support
					</span>
				</div>
			</div>
			<SettingsInfo
				{...{
					title: 'Profile Photo',
					text: 'Min 400x400px, PNG, JPG, JPEG Format. (Max Size-5MB)',
					neutral: true,
				}}
			>
				<ChangePfp {...{ pfp, handler }} />
			</SettingsInfo>
			<SettingsInfo
				{...{
					title: 'Legal Name',
					text: 'Your full legal name as shown on your identification document',
					val: ux.user ? ux.user.firstName + ' ' + ux.user.lastName : '',
				}}
			/>{' '}
			<SettingsInfo
				{...{
					title: 'Email Address',
					text: 'An easily accessible email address',
					val: ux.user ? ux.user.email : '',
				}}
			/>
			<SettingsInfo
				{...{
					title: 'Phone Number',
					text: 'An accessible phone number for OTP',
					val: ux.user ? ux.user.phoneNumber : '',
				}}
			/>
			<SettingsInfo
				{...{
					title: 'Residential Address',
					text: 'Current mailing address',
					val: ud.kyc ? ud.kyc.address : '',
				}}
			/>
			<SettingsInfo
				{...{
					title: 'Nationality/Citizenship',
					text: '',
					val: ux.user ? ux.user.country : '',
				}}
			/>
		</div>
	)
}

export default Account
