import React from 'react'
import d from '../../styles/Dashboard.module.css'
import p from '../../styles/Properties.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import { useAuth, useMain } from '../../hooks'

const InvestNow = ({ setShowInvest, isDisabled }) => {
	const { userDetails } = useAuth()
	const { alertThis } = useMain()
	return (
		<button
			className={cf(
				s.wMax,
				s.flex,
				s.spaceXBetween,
				s.spaceYCenter,
				p.iViewDetailsBtn
			)}
			onClick={() => {
				const kycExists =
					userDetails && userDetails.kyc && userDetails.kyc.status
				if (kycExists && userDetails.kyc.status === 'approved') {
					setShowInvest((x) => true)
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: 'smooth',
					})
				} else if (kycExists && userDetails.kyc.status === 'completed') {
					alertThis({
						message: `Your KYC submission is under review, you'll be able to perform transactions once approved`,
						forConfirmation: false,
						canClear: false,
					})
				} else if (kycExists && userDetails.kyc.status === 'rejected') {
					alertThis({
						message: `Your KYC submission wasn't approved, please visit the identity page on KYC to upload your valid documents.`,
						forConfirmation: false,
						canClear: false,
					})
				} else {
					alertThis({
						message: `Please complete your KYC in order to perform transactions`,
						forConfirmation: false,
						canClear: false,
					})
				}
			}}
			disabled={isDisabled}
		>
			<span className={cf(s.flex, s.flexCenter)}>Invest Now</span>
			<svg
				width='24'
				height='25'
				viewBox='0 0 24 25'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				className={cf(s.flex, d.sPlus, d.iPlus)}
			>
				<g id='Icon/plus'>
					<path
						id='Path 2'
						d='M20 12.5H4'
						stroke='white'
						strokeLinecap='round'
					/>
					<path
						id='Path 2_2'
						d='M12 4.5V20.5'
						stroke='white'
						strokeLinecap='round'
					/>
				</g>
			</svg>
		</button>
	)
}

export default InvestNow
