import React, { useState } from 'react'
import { SettingsSwitch } from '../../../components/SettingsSwitch'
import d from '../../../styles/Dashboard.module.css'
import s from '../../../styles/Shared.module.css'
import { cf } from '../../../utils'
import { Account } from './Account'
import { Privacy } from './Privacy'

const SettingsPage = () => {
	const [view, setView] = useState('base')
	const [showAccount, setShowAccount] = useState(true)

	return (
		<div className={cf(s.wMax, s.flex, s.flexTop, d.innerMain)}>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.tMainTitleCon
				)}
			>
				<div className={cf(s.flex, s.flex_dColumn, s.flexTop, d.tTitleCon)}>
					<h1 className={cf(s.flex, s.m0, s.p0, s.flexLeft, d.tTitle)}>
						Account Settings
					</h1>
					<span className={cf(s.flex, s.flexLeft, d.tSubTitle)}>
						Choose between categories.
					</span>
				</div>
			</div>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYStart,
					d.settingsViewCon
				)}
			>
				<SettingsSwitch {...{ showAccount, setShowAccount, setView }} />
				{showAccount ? <Account /> : <Privacy {...{ view, setView }} />}
			</div>
		</div>
	)
}

export default SettingsPage
