import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import bath from '../../../../assets/svg/bath.svg'
import bed from '../../../../assets/svg/bed.svg'
import building from '../../../../assets/svg/building.svg'
import chevronWhite from '../../../../assets/svg/chevronWhite.svg'
import download from '../../../../assets/svg/download.svg'
import pictures from '../../../../assets/svg/pictures.svg'
import sqm from '../../../../assets/svg/sqm.svg'
import whitePin from '../../../../assets/svg/whitePin.svg'
import offerBegins from '../../../../assets/svg/offerBegins.svg'
import offerEnds from '../../../../assets/svg/offerEnds.svg'
import { InvestNow } from '../../../../components/InvestNow'
import { useAuth, useMain } from '../../../../hooks'
import d from '../../../../styles/Dashboard.module.css'
import o from '../../../../styles/Onboarding.module.css'
import p from '../../../../styles/Properties.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf, currencyFormat } from '../../../../utils'

const Offer = ({ time, begins }) => {
	const [t, setT] = useState('-')
	const [d, setD] = useState('-')

	useEffect(() => {
		let date = new Date(time)
		let hours = date.getHours()
		let newFormat = hours >= 12 ? 'PM' : 'AM'
		hours = hours % 12
		hours = hours ? hours : 12
		setT((x) => hours + newFormat)
		setD((x) => date.toString().slice(0, 15))
	}, [time])

	return (
		<div className={cf(s.flex, s.flexLeft, p.xOffer)}>
			<img
				src={begins ? offerBegins : offerEnds}
				alt={begins ? 'open padlock' : 'closed padlock'}
				className={cf(s.flex, s.flexCenter, p.xOfferIcon)}
			/>
			<div className={cf(s.flex, s.flexLeft, p.xOfferDetails)}>
				<span
					className={cf(s.wMax, s.tLeft, s.flex, s.flexLeft, p.xOfferTitle)}
				>
					{begins ? 'OFFER BEGINS' : 'OFFER ENDS'}
				</span>
				<span
					className={cf(s.wMax, s.tLeft, s.flex, s.flexLeft, p.xOfferValue)}
				>
					{t} - {d}
				</span>
			</div>
		</div>
	)
}

const ViewPictures = () => {
	const { isSmall, setShowImagesView } = useMain()
	return (
		<button
			className={cf(
				s.flex,
				s.flexCenter,
				p.pViewPicturesBtn,
				isSmall ? p.pViewPicturesBtnMini : ''
			)}
			onClick={() => setShowImagesView((x) => true)}
		>
			<span className={cf(s.flex, s.flexCenter, p.pViewPicturesTxt)}>
				View photos
			</span>
			<img
				src={pictures}
				alt={'view pictures button'}
				className={cf(s.flex, s.flexCenter, p.pViewPicturesIcon)}
			/>
		</button>
	)
}

const ShowMore = ({ setShowMore }) => {
	return (
		<span
			className={cf(s.flex, s.flexCenter, p.showMore, s.pointer)}
			onClick={() => setShowMore((x) => true)}
		>
			Read More
		</span>
	)
}

const PropDetails = ({ val, tag, path, multi = true } = {}) => {
	return (
		<div className={cf(s.flex, s.flexLeft, s.g10, p.propertyDetail)}>
			<img
				src={path}
				alt={'property detail icon'}
				className={cf(s.flex, s.flexCenter, p.pMPropertyFeatIcon)}
			/>
			{multi ? (
				<span className={cf(s.flex, s.flexRight, p.pPMPropertyFeatTxt)}>
					{val} {tag}
					{val > 1 ? 's' : ''}
				</span>
			) : (
				<span className={cf(s.flex, s.flexRight, p.pPMPropertyFeatTxt)}>
					{val}
					{tag}
				</span>
			)}
		</div>
	)
}

const PropertyPage = ({ setShowInvest }) => {
	const navigate = useNavigate()
	const {
		isTiny,
		isSmall,
		property: x,
		getProperty,
		setShowImagesView,
		startWaiting,
		stopWaiting,
		originPath,
	} = useMain()
	const { authorized } = useAuth()
	const container = cf(s.wMax, s.flex, p.innerMain)
	const [showMore, setShowMore] = useState(false)

	const progressRef = useRef()
	const propertyRef = useRef()
	const location = useLocation()

	useEffect(() => {
		const doSomething = async () => {
			// if (!authorized) {
			// 	navigate('/')
			// 	return
			// }
			const id = Number(
				String(location.pathname).slice('/properties/id/'.length)
			)
			if (!Object.keys(x).length) {
				if (!isNaN(id)) {
					startWaiting()
					const res = await getProperty(id)
					if (!res) {
						navigate('/404')
					}
					stopWaiting()
				} else {
					navigate('/404')
				}
			} else if (x.id) {
				if (Number(x.id) !== id) {
					if (!isNaN(id)) {
						startWaiting()
						const res = await getProperty(id)
						if (!res) {
							navigate('/404')
						}
						stopWaiting()
					} else {
						navigate('/404')
					}
				} else {
					return
				}
			}
		}
		doSomething()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const _ = Number(x.price) * Number(x.sold)
		const __ = Number(x.price) * Number(x.volume)
		const ___ = (_ / __) * 100
		const ____ = Math.round(100 - ___)

		// timer.current = setTimeout(() => {
		if (progressRef.current) progressRef.current.style.right = `${____}%`
		// }, 2000)
		if (propertyRef.current && x.image) {
			propertyRef.current.style.background = `url(${x.image[0]})`
			propertyRef.current.style.backgroundPosition = 'center'
			propertyRef.current.style.backgroundRepeat = 'no-repeat'
			propertyRef.current.style.backgroundSize = 'cover'
		}
	}, [x.volume, x.price, x.sold, x.image])

	useEffect(() => {
		if (isSmall === false && showMore === true) {
			setShowMore((x) => false)
		}
	}, [isSmall, showMore])

	useEffect(() => {
		return () => {
			setShowImagesView((x) => false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={cf(s.wMax, s.flex, s.flex_dColumn, s.flexLeft)}>
			<div
				className={cf(s.wMax, s.flex, s.flexCenter, p.propertyBannerCon)}
				ref={propertyRef}
			>
				<div className={cf(container, s.hMax, s.flexCenter)}>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.hMax,
							s.flex_dColumn,
							s.spaceXBetween,
							s.spaceYStart,
							p.propertyBanner
						)}
					>
						<div className={cf(s.wMax, s.flex, s.spaceXBetween, s.spaceYEnd)}>
							<Link
								to={originPath || '/properties'}
								className={cf(s.flex, s.flexCenter, p.pPGoBackBtn)}
							>
								<img
									src={chevronWhite}
									alt={'go back icon'}
									className={cf(s.flex, s.flexCenter, s.pPGoBackIcon)}
								/>
								{!isSmall ? (
									<span className={cf(s.flex, s.flexCenter, p.pPGoBackTxt)}>
										Go back
									</span>
								) : (
									<></>
								)}
							</Link>
							{isSmall ? <ViewPictures /> : <></>}
						</div>
						<div className={cf(s.wMax, s.flex, s.spaceXBetween, s.spaceYEnd)}>
							<div
								className={cf(
									s.flex,
									s.flexOne,
									s.wMax,
									s.flexLeft,
									s.flex_dColumn,
									s.flexTop,
									p.pPLocationInfoCon
								)}
							>
								<h1 className={cf(s.wMax, s.flex, s.m0, s.p0, p.propertyTheme)}>
									{x.name}
								</h1>
								<div className={cf(s.wMax, s.flex, s.flexTop, p.pPLocationCon)}>
									<img
										src={whitePin}
										alt={'property location icon'}
										className={cf(s.flex, s.flexCenter, p.pPLocationIcon)}
									/>
									<span
										className={cf(
											s.wMax,
											s.flex,
											s.flexOne,
											s.flexLeft,
											p.pPLocation
										)}
									>
										{x.state}
									</span>
								</div>
							</div>
							{!isSmall ? <ViewPictures /> : <></>}
						</div>
					</div>
				</div>
			</div>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.flexCenter,
					p.pMDetailsCon,
					p.container,
					s.p_relative
				)}
			>
				<div className={cf(container, s.spaceXBetween, s.spaceYStart)}>
					<div
						className={cf(
							s.flex,
							s.flex_dColumn,
							s.flexLeft,
							p.pMPropertyInfoCon
						)}
					>
						<div
							className={cf(
								s.wMax,
								s.flex,
								s.spaceXBetween,
								s.spaceYCenter,
								p.pMPropertyFeaturesCon
							)}
						>
							<PropDetails
								{...{ tag: 'Bathroom', val: x.bathroom, path: bath }}
							/>
							<PropDetails {...{ tag: 'Bedroom', val: x.bedroom, path: bed }} />
							<PropDetails
								{...{ tag: 'sqm', val: x.sqm, path: sqm, multi: false }}
							/>
							<div className={cf(s.flex, s.flexLeft, s.g10, p.propertyDetail)}>
								<img
									src={building}
									alt={'property detail icon'}
									className={cf(s.flex, s.flexCenter, p.pMPropertyFeatIcon)}
								/>
								<span className={cf(s.flex, s.flexRight, p.pPMPropertyFeatTxt)}>
									{x.type}
								</span>
							</div>
						</div>
						<div
							className={cf(
								s.wMax,
								s.flex,
								s.spaceXBetween,
								s.spaceYCenter,
								p.pMPropertyFeaturesCon,
								p.xOffers
							)}
						>
							<Offer {...{ time: x.offerStart, begins: true }} />
							<Offer {...{ time: x.offerEnd, begins: false }} />
						</div>
						<div
							className={cf(
								s.wMax,
								s.flex,
								s.flexCenter,
								p.pMPropertyInformationCon
							)}
						>
							<span
								className={cf(s.wMax, s.flex, s.flexLeft, p.pMPropInfoTitle)}
							>
								Property Information
							</span>
							<p
								className={cf(
									s.wMax,
									s.tLeft,
									p.pMPropInfoContent,
									s.p_relative,
									isSmall && !showMore ? p.showLess : ''
								)}
							>
								{x.description ? (
									x.description.map((el, i) => (
										<span
											className={cf(s.wMax, s.mT10, s.tLeft, s.dInlineBlock)}
											key={i}
										>
											{el}
										</span>
									))
								) : (
									<></>
								)}
								{isSmall && !showMore ? (
									<ShowMore {...{ setShowMore }} />
								) : (
									<></>
								)}
							</p>
						</div>
					</div>
					<div className={cf(s.flex, s.flexTop, p.pMPropertyMetricsParent)}>
						<div
							className={cf(
								s.flex,
								s.flexTop,
								p.pMPropertyMetricsCon,
								p.noPadding
							)}
						>
							<div className={cf(s.wMax, p.pPropertyDetails)}>
								<div className={cf(s.wMax, s.flex, s.flexCenter)}>
									<div
										className={cf(
											s.wMax,
											s.flex,
											s.spaceXBetween,
											s.spaceYCenter,
											p.pPropMetricCon,
											p.pP22px
										)}
									>
										{isTiny ? (
											<span
												className={cf(
													s.wMax,
													s.flex,
													s.flexLeft,
													p.propDocTitle,
													p.m19
												)}
											>
												Property Details
											</span>
										) : (
											<></>
										)}
										<div
											className={cf(
												s.flex,
												s.flex_dColumn,
												s.flexTop,
												p.firstPropMetric
											)}
										>
											<span className={cf(s.flex, s.flexLeft, p.propMetricTag)}>
												Price per share
											</span>
											<span
												className={cf(s.flex, s.flexRight, p.propMetricVal)}
											>
												R {currencyFormat(x.price)}
											</span>
										</div>
										<div
											className={cf(
												s.flex,
												s.flexOne,
												s.flex_dColumn,
												s.flexBottom
											)}
										>
											<span className={cf(s.flex, s.flexLeft, p.propMetricTag)}>
												Market Valuation
											</span>
											<span
												className={cf(s.flex, s.flexRight, p.propMetricVal)}
											>
												R {currencyFormat(x.marketCap)}
											</span>
										</div>
									</div>
									<div
										className={cf(
											s.wMax,
											s.flex,
											s.spaceXBetween,
											s.spaceYCenter,
											p.pPropMetricCon,
											p.pP22px
										)}
									>
										<div
											className={cf(
												s.flex,
												s.flex_dColumn,
												s.flexTop,
												p.firstPropMetric
											)}
										>
											<span className={cf(s.flex, s.flexLeft, p.propMetricTag)}>
												Total Supply
											</span>
											<span
												className={cf(s.flex, s.flexRight, p.propMetricVal)}
											>
												{currencyFormat(x.volume, false)}
											</span>
										</div>
										<div
											className={cf(
												s.flex,
												s.flexOne,
												s.flex_dColumn,
												s.flexBottom
											)}
										>
											<span className={cf(s.flex, s.flexLeft, p.propMetricTag)}>
												Available Supply
											</span>
											<span
												className={cf(s.flex, s.flexRight, p.propMetricVal)}
											>
												{currencyFormat(
													Number(x.volume) - Number(x.sold),
													false
												)}
											</span>
										</div>
									</div>
									<div
										className={cf(
											s.wMax,
											s.flex,
											s.spaceXBetween,
											s.spaceYCenter,
											p.pPropMetricCon,
											p.pP22px
										)}
									>
										<div
											className={cf(
												s.flex,
												s.flex_dColumn,
												s.flexTop,
												p.firstPropMetric
											)}
										>
											<span className={cf(s.flex, s.flexLeft, p.propMetricTag)}>
												Total ROI
											</span>
											<span
												className={cf(s.flex, s.flexRight, p.propMetricVal)}
											>
												{x.roi}%
											</span>
										</div>
										<div
											className={cf(
												s.flex,
												s.flexOne,
												s.flex_dColumn,
												s.flexBottom
											)}
										>
											<span className={cf(s.flex, s.flexLeft, p.propMetricTag)}>
												Net Yield
											</span>
											<span
												className={cf(s.flex, s.flexRight, p.propMetricVal)}
											>
												{x.netYield}%
											</span>
										</div>
									</div>
									<div
										className={cf(
											s.wMax,
											s.flex,
											s.spaceXBetween,
											s.spaceYCenter,
											p.pPropMetricCon,
											p.pP22px
										)}
									>
										<div
											className={cf(
												s.flex,
												s.flex_dColumn,
												s.flexTop,
												p.firstPropMetric
											)}
										>
											<span className={cf(s.flex, s.flexLeft, p.propMetricTag)}>
												Gross Yield
											</span>
											<span
												className={cf(s.flex, s.flexRight, p.propMetricVal)}
											>
												{x.grossYield}%
											</span>
										</div>
									</div>
									<div
										className={cf(
											s.wMax,
											s.flex,
											s.spaceXBetween,
											s.spaceYCenter,
											p.propertyListing,
											p.propertyListing_
										)}
									>
										<span className={cf(s.flex, s.flexLeft, p.propMetricTag)}>
											Investment Target:
										</span>
										<span className={cf(s.flex, s.flexRight, p.propMetricVal)}>
											R {currencyFormat(Number(x.volume) * Number(x.price))}
										</span>
									</div>
									<div
										className={cf(
											s.wMax,
											s.flex,
											s.flexCenter,
											o.onboardingProgress,
											d.iPropertyProgress
										)}
									>
										<div className={cf(s.wMax, s.hMax, s.flex, s.flexCenter)}>
											<div
												className={cf(
													s.wMax,
													s.flex,
													s['flex_dColumn'],
													s.flexLeft,
													s.g10,
													o.progressCon2
												)}
											>
												<div
													className={cf(
														s.wMax,
														s.hMax,
														s.flex,
														s.flexCenter,
														o.progressBarCon
													)}
												>
													<div
														className={cf(s.wMax, o.progressBarComplete2, o.l0)}
														ref={progressRef}
													></div>
												</div>
											</div>
										</div>
									</div>
									{!isTiny ? (
										<div
											className={cf(
												s.wMax,
												s.flex,
												s.flexCenter,
												p.pMInvestNowCon
											)}
										>
											<InvestNow
												{...{
													setShowInvest,
													isDisabled: Number(x.volume) - Number(x.sold) === 0,
												}}
											/>
										</div>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
						<div
							className={cf(
								s.flex,
								s.flexTop,
								p.pMPropertyMetricsCon,
								p.secondMetricCon,
								p.noPadding
							)}
						>
							<span className={cf(s.wMax, s.flex, s.flexLeft, p.propDocTitle)}>
								Property Documents
							</span>
							{x?.documents?.length ? (
								<>
									{x?.documents?.map((el, i) => (
										<button
											className={cf(
												s.wMax,
												s.flex,
												s.spaceXBetween,
												s.spaceYCenter,
												p.propDownloadBtn
											)}
											// href={el.link}
											// download={el.link.split('/').pop()}
											// target={'_blank'}
											// rel={'noreferrer'}
											key={i}
											onClick={() => {
												fetch(el.link)
													.then((res) => res.blob)
													.then((blob) => {
														const blobURL = window.URL.createObjectURL(
															new Blob([blob])
														)
														const fileName = (el.link ?? '').split('/').pop()
														const aTag = document.createElement('a')
														aTag.href = blobURL
														aTag.setAttribute('download', fileName)
														document.body.appendChild(aTag)
														aTag.click()
														aTag.remove()
													})
													.catch(err =>{
														const fileName = (el.link ?? '').split('/').pop()
														const aTag = document.createElement('a')
														aTag.href = el.link
														aTag.setAttribute('download', fileName)
														document.body.appendChild(aTag)
														aTag.click()
														aTag.remove()
													})
											}}
										>
											<span className={cf(s.flex, s.flexCenter, p.propDTxt)}>
												{el.title}
											</span>
											<img
												src={download}
												alt={'download icon'}
												className={cf(s.flex, s.flexCenter, p.dIcon)}
											/>
										</button>
									))}
								</>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
				{isTiny ? (
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.flexCenter,
							p.pMInvestNowCon,
							p.pMInvestNowCon_,
							s.p_fixed,
							p.container
						)}
					>
						<InvestNow
							{...{
								setShowInvest,
								isDisabled: Number(x.volume) - Number(x.sold) === 0,
							}}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	)
}

export default PropertyPage
