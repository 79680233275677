import React, { useEffect, useState, useRef } from 'react'
import profileImage from '../../assets/svg/Default_pfp.svg'
import { useAuth, useMain } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import ProfileGroup from './ProfileGroup'

const ProfileButton = ({ dark }) => {
	const { userDetails } = useAuth()
	const { showProfileGroup, setShowProfileGroup, showingTinyNavBar } = useMain()

	const [isSmall, setIsSmall] = useState(false)
	const buttonRef = useRef(null)

	useEffect(() => {
		const viewReset = () => {
			setIsSmall(window.innerWidth <= 820)
		}

		const handleClickOutside = (event) => {
			if (buttonRef.current && !buttonRef.current.contains(event.target)) {
				setShowProfileGroup(false)
			}
		}

		window.addEventListener('resize', viewReset)
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			window.removeEventListener('resize', viewReset)
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [setShowProfileGroup])

	return (
		<div
			ref={buttonRef}
			className={cf(
				s.flex,
				s.spaceXBetween,
				s.spaceYCenter,
				d.profileButtonCon,
				s.pointer,
				s.p_relative
			)}
			onClick={() =>
				setShowProfileGroup((x) => (showingTinyNavBar ? false : !x))
			}
		>
			<img
				src={
					userDetails.user
						? userDetails.user.image
							? userDetails.user.image
							: profileImage
						: profileImage
				}
				alt={'Profile icon'}
				className={cf(s.flex, s.flexCenter, d.profileIcon)}
			/>
			{!isSmall && (
				<span
					className={cf(
						s.flex,
						s.flexCenter,
						d.userName,
						dark ? d.profileGroupDark : ''
					)}
				>
					{userDetails.user ? userDetails.user?.firstName : ''}&nbsp;
					{userDetails.user ? userDetails.user?.lastName : ''}
				</span>
			)}
			{!isSmall && (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='25'
					viewBox='0 0 24 25'
					fill='none'
					className={cf(
						d.chevronDown,
						showProfileGroup ? d.oStatusFlip : '',
						dark ? d.profileGroupDark : ''
					)}
				>
					<path
						d='M6 10.5L12 16.5L18 10.5'
						stroke='white'
						strokeLinecap='round'
					/>
				</svg>
			)}
			{showProfileGroup && <ProfileGroup {...{ setShowProfileGroup, dark }} />}
		</div>
	)
}

export default ProfileButton
