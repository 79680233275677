import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { InputField } from '../../../components/InputField'
import { useAuth, useMain } from '../../../hooks'
import a from '../../../styles/Account.module.css'
import s from '../../../styles/Shared.module.css'
import { cf, request } from '../../../utils'

const Reset = () => {
	const [requestBody, setRequestBody] = useState({})
	const [isDisabled, setIsDisabled] = useState(true)
	const [showResetForm, setShowResetForm] = useState(false)
	const [accessToken, setAccessToken] = useState(null)
	const { setBigText, setSmallText, startWaiting, stopWaiting, alertThis } = useMain()
	const { calledReset, setCalledReset, reset, setResendToEmail } = useAuth()
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		if (!calledReset) {
			startWaiting()
			const token = location.search.slice(
				location.search.lastIndexOf('?token=') + '?token='.length
			)
			if (token) {
				setShowResetForm((x) => true)
				setAccessToken((x) => token)
			} else {
				navigate('/404')
			}
			stopWaiting()
		}
		return () => {
			setCalledReset((x) => false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handler = async (e) => {
		const name = e.target.name
		let value = e.target.value

		const validatorObj = {
			email: true,
			password: true,
			confirm: true,
		}

		switch (name) {
			default:
				if (validatorObj[name])
					setRequestBody((e) => ({
						...e,
						[name]: value,
					}))
				break
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		setResendToEmail((x) => requestBody['email'])
		if (!showResetForm) {
			reset(requestBody['email'])
		} else {
			const res = await request({
				path: 'auth/reset-password',
				method: 'post',
				body: {
					password: requestBody['password'],
					token: accessToken,
				},
			})

			if (res.success) {
				navigate('/')
			} else {
				alertThis({
					message: res.message,
					forConfirmation: false,
					canClear: false,
				})
			}
		}
	}

	useEffect(() => {
		setBigText((x) => 'Invest and track earnings')
		setSmallText(
			(x) =>
				`By investing and diligently tracking your earnings, you can work towards building a more secure financial future.`
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setIsDisabled((x) =>
			showResetForm
				? !(
						requestBody['password'] &&
						requestBody['confirm'] &&
						requestBody['password'] === requestBody['confirm']
				  )
				: !requestBody['email']
		)
	}, [showResetForm, requestBody])

	return (
		<div className={cf(s.flex, s.flexCenter, a.accountChild)}>
			<h1 className={cf(s.wMax, s.tLeft, s.m0, s.p0, a.theme)}>
				{showResetForm ? 'Enter New Password' : 'Reset Password'}
			</h1>

			{!showResetForm ? (
				<form
					className={cf(s.wMax, s.flex, s.flexCenter, a.form)}
					onSubmit={handleSubmit}
				>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.flex_dColumn,
							s.flexCenter,
							a.inputFieldCon
						)}
					>
						<InputField
							tag={'email'}
							state={requestBody}
							handler={handler}
							type={'email'}
							placeholder={'Email Address'}
						/>
					</div>

					<div
						className={cf(
							s.wMax,
							s.flex,
							s.spaceXBetween,
							s.spaceYCenter,
							a.resetBtnCon
						)}
					>
						<Link
							to={'/account/login'}
							type={'button'}
							className={cf(s.flex, s.flexCenter, s.shadow, a.retraceSteps)}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='30'
								height='30'
								viewBox='0 0 30 30'
								fill='none'
								className={cf(a.retrace)}
							>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M27 15C27 15.8284 26.3284 16.5 25.5 16.5H8.1225L11.5607 19.9393C12.1014 20.4801 12.143 21.3309 11.6854 21.9193L11.5607 22.0607C11.0199 22.6014 10.1691 22.643 9.58065 22.1854L9.43934 22.0607L3.43934 16.0607L3.37724 15.9947L3.2945 15.893L3.18701 15.7261L3.10684 15.557L3.05377 15.399L3.01025 15.1762L3 15L3.00418 14.8871L3.03036 14.6989L3.07461 14.5318L3.14051 14.3652L3.21899 14.2189L3.31918 14.0749C3.3565 14.0273 3.39664 13.982 3.43934 13.9393L9.43934 7.93934C10.0251 7.35355 10.9749 7.35355 11.5607 7.93934C12.1014 8.48007 12.143 9.33091 11.6854 9.91935L11.5607 10.0607L8.1225 13.5H25.5C26.3284 13.5 27 14.1716 27 15Z'
									fill='black'
								/>
							</svg>
						</Link>
						<button
							type={'submit'}
							className={cf(
								s.wMax,
								s.flex,
								s.flexCenter,
								a.proceedToForget,
								!isDisabled ? s.shadow : '',
								isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
							)}
							disabled={isDisabled}
						>
							{isDisabled ? 'Fill the form' : 'Proceed'}
						</button>
					</div>
				</form>
			) : (
				<form
					className={cf(s.wMax, s.flex, s.flexCenter, a.form)}
					onSubmit={handleSubmit}
				>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.flex_dColumn,
							s.flexCenter,
							a.inputFieldCon
						)}
					>
						<InputField
							tag={'password'}
							state={requestBody}
							handler={handler}
							type={'password'}
							placeholder={'Enter password'}
						/>
						<InputField
							tag={'confirm'}
							state={requestBody}
							handler={handler}
							type={'password'}
							placeholder={'Confirm password'}
						/>
					</div>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.spaceXBetween,
							s.spaceYCenter,
							a.resetBtnCon
						)}
					>
						<button
							type={'submit'}
							className={cf(
								s.wMax,
								s.flex,
								s.flexCenter,
								a.proceedToForget,
								a.proceedToForgetFull,
								!isDisabled ? s.shadow : '',
								isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
							)}
							disabled={isDisabled}
						>
							{isDisabled ? 'Fill the form' : 'Proceed'}
						</button>
					</div>
				</form>
			)}

			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='482'
				height='2'
				viewBox='0 0 482 2'
				fill='none'
				className={cf(s.wMax, a.strike)}
			>
				<path
					d='M0 1H482'
					stroke='#B2B2B2'
					strokeWidth='0.5'
				/>
			</svg>

			<span className={cf(s.wMax, s.flex, s.flexCenter, s.tCenter, a.instead)}>
				Don't have an account?&nbsp;
				<Link
					to={'/account/signup'}
					className={cf(s.flex, a.alt)}
				>
					Sign Up
				</Link>
			</span>
		</div>
	)
}

export default Reset
