import React, { useEffect, useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { Link, useNavigate } from 'react-router-dom'
import email from '../../../assets/svg/email.svg'
import { useAuth, useMain } from '../../../hooks'
import a from '../../../styles/Account.module.css'
import s from '../../../styles/Shared.module.css'
import { cf } from '../../../utils'

const Resend = ({ reset }) => {
	const { resend } = useAuth()
	return (
		<button
			className={cf(s.flex, s.flexCenter, s.shadow, a.proceedToForget)}
			onClick={async () => {
				reset()
				resend()
			}}
		>
			Resend Email
		</button>
	)
}

const Waiter = ({ seconds }) => {
	return (
		<button
			className={cf(
				s.flex,
				s.flexCenter,
				a.proceedToForget,
				a.proceedDisabled,
				s.pointerInProgress
			)}
		>
			Resend in {zeroPad(seconds)}s
		</button>
	)
}

const RenderCountdown = ({ seconds, completed, reset }) => {
	if (completed) {
		return <Resend reset={reset} />
	} else {
		return <Waiter seconds={seconds} />
	}
}

const VerifyEmail = () => {
	const { setBigText, setSmallText } = useMain()
	const {
		calledVerify,
		setCalledVerify,
		resendToEmail = 'galebak258@camplvad.com',
		setResendToEmail,
		forReset,
	} = useAuth()
	const [date, setDate] = useState(Date.now() + 20000)
	const [key, setKey] = useState(1)
	const navigate = useNavigate()
	const reset = () => {
		setDate((x) => Date.now() + 20000)
		setKey((x) => x + 1)
	}

	// useEffect(() => {
	// 	setResendToEmail((x) => 'galebak258@camplvad.com')
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])

	useEffect(() => {
		if (!calledVerify) {
			navigate('/404')
		}
		return () => {
			setCalledVerify((x) => false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setBigText((x) => 'Invest and track earnings')
		setSmallText(
			(x) =>
				`By investing and diligently tracking your earnings, you can work towards building a more secure financial future.`
		)
		return () => {
			setResendToEmail((x) => '')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={cf(s.flex, s.flexCenter, a.accountChild, a.onVerifyMobile)}>
			<div className={cf(s.wMax, s.flex, s.flexCenter, a.flexColumnOne)}>
				<div className={cf(s.wMax, s.flex, s.flexCenter)}>
					<img
						src={email}
						alt={'email icon'}
						className={a.emailIcon}
					/>
					<h1 className={cf(s.wMax, s.tCenter, s.m0, s.p0, a.theme)}>
						{forReset ? 'Check your email' : 'Verify your email address'}
					</h1>
					<div className={cf(s.wMax, s.flex, s.flexCenter, a.rMessageCon)}>
						<span
							className={cf(
								s.wMax,
								s.flex,
								s.flexCenter,
								s.tCenter,
								a.resendMessage
							)}
						>
							We’ve sent {forReset ? 'a reset password link to' : 'an email to'}
							&nbsp;
							<span className={cf(a.resendToEmail)}>{resendToEmail}</span>
						</span>
						<span
							className={cf(
								s.wMax,
								s.flex,
								s.flexCenter,
								s.tCenter,
								a.resendMessage
							)}
						>
							{forReset
								? 'You’ll need to click on the reset link to continue'
								: 'You need to verify your email to continue.'}
						</span>
					</div>
				</div>
			</div>

			<div className={cf(s.wMax, s.flex, s.flexCenter, a.form)}>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter,
						a.resetBtnCon
					)}
				>
					<Link
						to={'/account/login'}
						type={'button'}
						className={cf(s.flex, s.flexCenter, s.shadow, a.retraceSteps)}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='30'
							height='30'
							viewBox='0 0 30 30'
							fill='none'
							className={cf(a.retrace)}
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M27 15C27 15.8284 26.3284 16.5 25.5 16.5H8.1225L11.5607 19.9393C12.1014 20.4801 12.143 21.3309 11.6854 21.9193L11.5607 22.0607C11.0199 22.6014 10.1691 22.643 9.58065 22.1854L9.43934 22.0607L3.43934 16.0607L3.37724 15.9947L3.2945 15.893L3.18701 15.7261L3.10684 15.557L3.05377 15.399L3.01025 15.1762L3 15L3.00418 14.8871L3.03036 14.6989L3.07461 14.5318L3.14051 14.3652L3.21899 14.2189L3.31918 14.0749C3.3565 14.0273 3.39664 13.982 3.43934 13.9393L9.43934 7.93934C10.0251 7.35355 10.9749 7.35355 11.5607 7.93934C12.1014 8.48007 12.143 9.33091 11.6854 9.91935L11.5607 10.0607L8.1225 13.5H25.5C26.3284 13.5 27 14.1716 27 15Z'
								fill='black'
							/>
						</svg>
					</Link>
					<Countdown
						date={date}
						renderer={(props) => <RenderCountdown {...{ reset, ...props }} />}
						key={key}
					/>
				</div>
			</div>

			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='482'
				height='2'
				viewBox='0 0 482 2'
				fill='none'
				className={cf(s.wMax, a.strike, a.onVerifyMobile)}
			>
				<path
					d='M0 1H482'
					stroke='#B2B2B2'
					strokeWidth='0.5'
				/>
			</svg>
		</div>
	)
}

export default VerifyEmail
