import React from 'react'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const TSelector = ({ type, setType }) => {
	return (
		<div className={cf(s.flex, s.flexCenter, d.selectorCon, d.selectorB15)}>
			<button
				className={cf(
					s.flex,
					s.flexCenter,
					d.sSelector,
					type === 'all' ? d.sNotSelected : ''
				)}
				onClick={() => setType((x) => 'all')}
			>
				<span
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						type === 'stock' ? '' : d.sSelSpan
					)}
				>
					All
				</span>
			</button>
			<button
				className={cf(
					s.flex,
					s.flexCenter,
					d.sSelector,
					type === 'stock' ? d.sNotSelected : ''
				)}
				onClick={() => setType((x) => 'stock')}
			>
				<span
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						type === 'topup' ? '' : d.sSelSpan
					)}
				>
					Shares
				</span>
			</button>
			<button
				className={cf(
					s.flex,
					s.flexCenter,
					d.sSelector,
					type === 'topup' ? d.sNotSelected : ''
				)}
				onClick={() => setType((x) => 'topup')}
			>
				<span
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						type === 'cashout' ? '' : d.sSelSpan
					)}
				>
					Top up
				</span>
			</button>
			<button
				className={cf(
					s.flex,
					s.flexCenter,
					d.sSelector,
					type === 'cashout' ? d.sNotSelected : ''
				)}
				onClick={() => setType((x) => 'cashout')}
			>
				<span className={cf(s.wMax, s.flex, s.flexCenter)}>Withdrawal</span>
			</button>
		</div>
	)
}

export default TSelector
