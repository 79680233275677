import React, { useEffect, useState } from 'react'
import privacy from '../../../../../assets/svg/privacy.svg'
import { useAuth } from '../../../../../hooks'
import a from '../../../../../styles/Account.module.css'
import d from '../../../../../styles/Dashboard.module.css'
import i from '../../../../../styles/InputField.module.css'
import s from '../../../../../styles/Shared.module.css'
import { cf, request } from '../../../../../utils'

const PasswordInput = ({ state, handler, tag, label }) => {
	return (
		<label
			className={cf(s.wMax, s.flex, s.flexLeft, d.settingsPasswordInputCon)}
			htmlFor={tag}
		>
			<span
				className={cf(s.wMax, s.flex, s.flexLeft, d.settingsPasswordInputLabel)}
			>
				{label}&nbsp;
				<span className={cf(s.flex, s.flexTop, i.requiredMark)}>*</span>
			</span>
			<input
				type={'password'}
				className={cf(
					s.wMax,
					s.flex,
					s.flexLeft,
					s.tLeft,
					d.settingsPasswordInput
				)}
				value={state[tag]}
				onChange={handler}
				autocomplete={'off'}
				id={tag}
				name={tag}
			/>
		</label>
	)
}

const ChangePassword = ({ setView }) => {
	const [requestBody, setRequestBody] = useState({})
	const [isDisabled, setIsDisabled] = useState(true)
	const { accessToken } = useAuth()

	const handler = (e) => {
		const name = e.target.name
		const value = e.target.value

		const tags = {
			current: true,
			new: true,
			confirm: true,
		}

		switch (name) {
			default:
				if (tags[name]) {
					setRequestBody((x) => ({
						...x,
						[name]: value,
					}))
				}
				break
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		const res = await request({
			path: 'auth/reset-password',
			method: 'post',
			body: {
				password: requestBody['new'],
				token: accessToken,
			},
		})

		if (res.success) {
			setView('base')
		}
		// TODO Update functionality
	}

	useEffect(() => {
		setIsDisabled(
			(x) =>
				!(
					requestBody.current &&
					requestBody.new &&
					requestBody.confirm &&
					requestBody.new === requestBody.confirm
				)
		)
	}, [requestBody])

	return (
		<div className={cf(s.flex, s.flexLeft, d.settingsView)}>
			<div className={cf(s.wMax, s.flex, s.flexLeft, d.settingsProfileCon)}>
				<div
					className={cf(s.flex, s.flexCenter, s.g10, d.settingsProfileIconCon)}
				>
					<img
						src={privacy}
						alt={'settings profile icon'}
						className={cf(d.settingsProfileIcon)}
					/>
				</div>
				<div
					className={cf(
						s.flex,
						s.flexLeft,
						s.flexOne,
						d.settingsProfileInfoCon
					)}
				>
					<span
						className={cf(s.wMax, s.flex, s.flexLeft, d.settingsProfileTitle)}
					>
						Change Password
					</span>
					<span
						className={cf(
							s.wMax,
							s.flex,
							s.flexLeft,
							d.settingsProfileSubTitle
						)}
					>
						Update password for enhanced account security
					</span>
				</div>
			</div>
			<form
				className={cf(s.flex, s.flexCenter, d.settingsPasswordChangeForm)}
				onSubmit={handleSubmit}
			>
				<input
					autocomplete={false}
					className={cf(d.inputHidden)}
					type={'text'}
				/>
				<PasswordInput
					{...{
						state: requestBody,
						handler,
						tag: 'current',
						label: 'Current Password',
					}}
				/>
				<PasswordInput
					{...{
						state: requestBody,
						handler,
						tag: 'new',
						label: 'New Password',
					}}
				/>
				<PasswordInput
					{...{
						state: requestBody,
						handler,
						tag: 'confirm',
						label: 'Confirm New Password',
					}}
				/>
				<button
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						d.settingsPasswordChangeBtn,
						!isDisabled ? s.shadow : '',
						isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
					)}
					type={'submit'}
					disabled={isDisabled}
				>
					{isDisabled ? 'Fill the form' : 'Update'}
				</button>
			</form>
		</div>
	)
}

export default ChangePassword
