import React from 'react'
import { useAuth } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import { IBrowse } from '../IBrowse'
import { ViewAll } from '../ViewAll'
import Investment from './Investment'

const Investments = () => {
	const { userDashboard } = useAuth()

	return (
		<div
			className={cf(s.wMax, s.flex, s.flex_dColumn, s.flexLeft, d.txnsParent)}
		>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.txnsTitleCon
				)}
			>
				<span className={cf(s.flex, d.txnsTitle)}>Investment Portfolio</span>
				<ViewAll to={'/dashboard/investments'} />
			</div>
			{userDashboard.portfolio && userDashboard.portfolio.length ? (
				<div
					className={cf(s.wMax, s.flex, s.flex_dColumn, s.flexLeft, d.txnsCon)}
				>
					{userDashboard.portfolio.map((el, i) => (
						<Investment
							inv={el}
							key={i}
						/>
					))}
				</div>
			) : (
				<div className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQuery)}>
					<div className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQueryTxt)}>
						<span
							className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQueryTitle)}
						>
							You have not invested in any property yet
						</span>
						<span className={cf(s.flex, s.flexCenter, d.emptyQueryMessage)}>
							Start your investment journey with Cribvest today. Click the button below to begin.
						</span>
					</div>
					<IBrowse />
				</div>
			)}
		</div>
	)
}

export default Investments
