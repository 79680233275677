import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import realEstate from '../../../assets/images/realEstate.png'
import { IBrowse as Browse } from '../../../components/IBrowse'
import { IProperty } from '../../../components/IProperty'
import { ISelector as Selector } from '../../../components/ISelector'
import { useMain } from '../../../hooks'
import d from '../../../styles/Dashboard.module.css'
import s from '../../../styles/Shared.module.css'
import { cf, currencyFormat } from '../../../utils'

const Investments = () => {
	const location = useLocation()
	const { isSmall, investments, setOriginPath } = useMain()
	const [isPreSale, setIsPreSale] = useState(true)
	const [invs, setInvs] = useState([])

	useEffect(() => {
		setOriginPath((x) => location.pathname)
		if (isPreSale && investments.length) {
			setInvs((x) => investments.filter((el) => el.isPresell))
		} else if (!isPreSale && investments.length) {
			setInvs((x) => investments.filter((el) => !el.isPresell))
		} else {
			setInvs((x) => [])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investments, isPreSale, location.pathname])

	return (
		<div className={cf(s.wMax, s.flex, s.flexTop, d.innerMain)}>
			<span className={cf(s.wMax, s.tLeft, d.hello)}>
				Total Investment Value
			</span>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.sTitleCon
				)}
			>
				<div className={cf(s.flex, s.spaceXBetween, s.spaceYCenter, s.g10)}>
					<h1 className={cf(s.tLeft, s.m0, s.p0, d.iTitle)}>
						R{' '}
						{investments.length
							? currencyFormat(
									investments.reduce((a, b) => a + Number(b.totalInvestment), 0)
							  )
							: 0}
					</h1>
					{/* <span
						className={cf(
							s.flex,
							s.flexCenter,
							d.iProfit,
							!isPositive ? d.iNegative : ''
						)}
					>
						{isPositive ? <FaCaretUp /> : <FaCaretDown />}&nbsp;{'128.3'}%
					</span> */}
				</div>
				<div
					className={cf(
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter,
						s.g10,
						d.sActionButtons
					)}
				>
					<Selector {...{ isPreSale, setIsPreSale }} />
					{!isSmall ? <Browse /> : <></>}
				</div>
			</div>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXEvenly,
					s.spaceYCenter,
					d.iPropertyParent
				)}
			>
				{invs.length ? (
					invs.map((el, i) => (
						<IProperty
							i={el}
							key={i}
						/>
					))
				) : (
					<div className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQuery)}>
						<img
							src={realEstate}
							alt={'real estate'}
							className={cf(s.flex, s.flexCenter, d.emptyRealEstate)}
						/>
						<div className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQueryTxt)}>
							<span
								className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQueryTitle)}
							>
								You have not invested in any property yet
							</span>
							<span className={cf(s.flex, s.flexCenter, d.emptyQueryMessage)}>
								Start your investment journey with Cribvest today. Click the button below to begin.
							</span>
						</div>
					</div>
				)}
			</div>
			{isSmall ? <Browse /> : <></>}
		</div>
	)
}

export default Investments
