import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { LoadMore } from '../../../components/LoadMore'
import { PropertiesFilter } from '../../../components/PropertiesFilter'
import { Property } from '../../../components/Property'
import { useMain } from '../../../hooks'
import p from '../../../styles/Properties.module.css'
import s from '../../../styles/Shared.module.css'
import { cf } from '../../../utils'

const PropertiesPage = () => {
	const location = useLocation()
	const { filteredProperties, setOriginPath } = useMain()
	const container = cf(s.wMax, s.flex, p.innerMain)

	useEffect(() => {
		setOriginPath((x) => location.pathname)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={cf(s.wMax, s.flex, s.flex, s.flex_dColumn, s.flexLeft)}>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.flexCenter,
					s['p_relative'],
					p.propertiesBannerCon
				)}
			>
				<div className={cf(s.wMax, s.p_absolute, p.pPropertiesBannerCon)}></div>
				<div className={cf(container, s.flexLeft)}>
					<h1 className={cf(s.flex, s.m0, s.p0, p.propertyTheme)}>
						Browse Properties
					</h1>
					<PropertiesFilter />
				</div>
			</div>
			<div className={cf(s.wMax, s.flex, s.flexCenter, p.propertiesListingCon)}>
				<div className={cf(container, s.spaceXEvenly, s.spaceYCenter)}>
					{filteredProperties.map((el, i) => (
						<Property
							property={el}
							key={i}
						/>
					))}
				</div>
			</div>
			<div className={cf(s.wMax, s.flex, s.flexCenter, p.loadMoreCon)}>
				<LoadMore />
			</div>
		</div>
	)
}

export default PropertiesPage
