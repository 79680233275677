import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { InputField } from '../../../../components/InputField'
import { useAuth, useMain } from '../../../../hooks'
import a from '../../../../styles/Account.module.css'
import o from '../../../../styles/Onboarding.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf, getList } from '../../../../utils'

const Step = () => {
	const [requestBody, setRequestBody] = useState({})
	const [isDisabled, setIsDisabled] = useState(true)
	const { setCurrentStep } = useMain()
	const { doUpdateKYC, uncompleted } = useAuth()
	const [countryOptions, setCountryOptions] = useState([])
	const [stateOptions, setStateOptions] = useState([])
	const [cityOptions, setCityOptions] = useState([])
	const navigate = useNavigate()

	useLayoutEffect(() => {
		setCurrentStep((x) => 1)
		if (uncompleted[1]) {
			navigate('/account/kyc/identity')
		}
		return () => {
			setCurrentStep((x) => 1)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uncompleted])

	useEffect(() => {
		const doSomething = async () => {
			const countries = await getList('countries', 'country_name')
			if (countries) {
				setCountryOptions((x) => countries)
			}
		}
		doSomething()
	}, [])

	useEffect(() => {
		const doSomething = async () => {
			setStateOptions((x) => [])
			setCityOptions((x) => [])
			setRequestBody((x) => ({
				...x,
				state: null,
				city: null,
			}))
			const states = await getList(
				'states/' + requestBody?.country?.value ?? '',
				'state_name'
			)
			if (states) {
				setStateOptions((x) => states)
			}
		}
		doSomething()
	}, [requestBody.country])

	useEffect(() => {
		const doSomething = async () => {
			setCityOptions((x) => [])
			setRequestBody((x) => ({
				...x,
				city: null,
			}))
			const cities = await getList(
				'cities/' + requestBody?.state?.value ?? '',
				'city_name'
			)
			if (cities) {
				setCityOptions((x) => cities)
			}
		}
		doSomething()
	}, [requestBody.state])

	const countryHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			country: x,
		}))

	const stateHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			state: x,
		}))

	const cityHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			city: x,
		}))

	const { handleSubmit } = useForm()

	const handler = async (e) => {
		const name = e.target.name
		let value = e.target.value

		const validatorObj = {
			dateOfBirth: true,
			address: true,
			city: true,
			state: true,
			country: true,
			zip: true,
		}

		switch (name) {
			default:
				if (validatorObj[name])
					setRequestBody((e) => ({
						...e,
						[name]: value,
					}))
				break
		}
	}

	const handleSubmitHandler = async (e) => {		
		e.preventDefault()
		let i = 0
		const keys = Object.keys(requestBody)
		const length = keys.length,
			reqBody = {}
		for (i; i < length; i++) {
			const el = requestBody[keys[i]]
			if (typeof el === 'object' && el.hasOwnProperty('value')) {
				reqBody[keys[i]] = el.value
			} else {
				reqBody[keys[i]] = el
			}
			if (Array.isArray(el)) {
				reqBody[keys[i]] = []
				let o = 0
				const keys_ = Object.keys({ label: true, value: true })
				const length = keys_.length
				for (o; o < length; o++) {
					if (typeof el[o] === 'object' && el[o].hasOwnProperty('value')) {
						reqBody[keys[i]].push(el[o].value)
					}
				}
			}
		}
		const { address, city, state, country, zip, ...rest } = reqBody

		const body = {
			...rest,
			address: `${address}, ${city}, ${state}, ${zip}, ${country}`,
		}
		doUpdateKYC(body)
	}

	useEffect(() => {
		setIsDisabled(
			(x) => !(requestBody['dateOfBirth'] && requestBody['zip'] && requestBody['country'] && requestBody['state'] && requestBody['city'] && requestBody['address'])
		)
	}, [requestBody])

	return (
		<div className={cf(s.flex, s.flexCenter, a.accountChild)}>
			<h1 className={cf(s.wMax, s.tLeft, s.m0, s.p0, a.theme)}>
				Personal Information
			</h1>
			<span className={cf(s.wMax, s.flex, s.flexLeft, s.tLeft, o.underTheme)}>
				Provide your following information to continue.
			</span>

			<form
				className={cf(s.wMax, s.flex, s.flexCenter, a.form)}
				onSubmit={(e) => handleSubmit(handleSubmitHandler(e))}
			>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.flex_dColumn,
						s.flexCenter,
						a.inputFieldCon
					)}
				>
					{countryOptions.length ? (
						<InputField
							tag={'country'}
							state={requestBody}
							handler={countryHandler}
							placeholder={'Country'}
							type={'select'}
							selectOptions={countryOptions}
						/>
					) : (
						<InputField
							tag={'country'}
							state={requestBody}
							handler={handler}
							type={'text'}
							placeholder={'Country'}
						/>
					)}

					{stateOptions.length ? (
						<InputField
							tag={'state'}
							state={requestBody}
							handler={stateHandler}
							placeholder={'State/Province'}
							type={'select'}
							selectOptions={stateOptions}
						/>
					) : (
						<InputField
							tag={'state'}
							state={requestBody}
							handler={handler}
							type={'text'}
							placeholder={'State/Province'}
						/>
					)}

					{cityOptions.length ? (
						<InputField
							tag={'city'}
							state={requestBody}
							handler={cityHandler}
							placeholder={'City/Town'}
							type={'select'}
							selectOptions={cityOptions}
						/>
					) : (
						<InputField
							tag={'city'}
							state={requestBody}
							handler={handler}
							type={'text'}
							placeholder={'City/Town'}
						/>
					)}

					<InputField
						tag={'zip'}
						state={requestBody}
						handler={handler}
						type={'text'}
						placeholder={'Postal Code'}
					/>
					<InputField
						tag={'address'}
						state={requestBody}
						handler={handler}
						type={'text'}
						placeholder={'Residential Address'}
					/>
					<InputField
						tag={'dateOfBirth'}
						state={requestBody}
						handler={handler}
						type={'date'}
						placeholder={'Date of Birth'}
					/>
				</div>

				<button
					type={'submit'}
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						a.submitBtn,
						!isDisabled ? s.shadow : '',
						isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
					)}
					disabled={isDisabled}
				>
					{isDisabled ? 'Fill the form' : 'Continue'}
				</button>

				<div
					className={cf(s.wMax, s.flex, s.flexLeft, a.rememberToForget, s.p5)}
				>
					<span className={cf(s.wMax, s.dInlineBlock, s.tLeft, a.notice)}>
						The information provided would be saved securely as per our&nbsp;
						<Link
							to={'https://cribvest.com/terms-and-conditions/'}
							className={cf(s.link)}
						>
							Terms of Service
						</Link>
						&nbsp;&&nbsp;
						<Link
							to={'https://cribvest.com/privacy-policy/'}
							className={cf(s.link)}
						>
							Privacy Policy
						</Link>
					</span>
				</div>
			</form>

			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='482'
				height='2'
				viewBox='0 0 482 2'
				fill='none'
				className={cf(s.wMax, a.strike)}
			>
				<path
					d='M0 1H482'
					stroke='#B2B2B2'
					strokeWidth='0.5'
				/>
			</svg>

			<span className={cf(s.wMax, s.flex, s.flexCenter, s.tCenter, a.instead)}>
				Want to fill this later?&nbsp;
				<Link
					to={'/account/kyc/identity'}
					className={cf(s.flex, a.alt)}
				>
					Skip this step
				</Link>
			</span>
		</div>
	)
}

export default Step
