import React from 'react'
import { Link } from 'react-router-dom'
import chevron from '../../assets/svg/chevron.svg'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const ViewAll = ({ to }) => {
	return (
		<Link
			to={to}
			className={cf(s.flex, s.flexCenter, d.cMore)}
		>
			<span className={cf(s.flex, s.flexCenter, d.cMoreText)}>View all</span>
			<img
				src={chevron}
				alt={'chevron right'}
				className={cf(d.cMoreChevron)}
			/>
		</Link>
	)
}

export default ViewAll
