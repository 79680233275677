import React, { useState } from 'react'
import Select from 'react-select'
import i from '../../styles/InputField.module.css'
import p from '../../styles/Properties.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const Filter = ({
	tag,
	state,
	handler,
	type,
	placeholder,
	mini = false,
	required = false,
	isMulti = false,
	label = '',
	selectOptions = [
		{ value: 'unemployed', label: 'Unemployed' },
		{ value: 'fulltime', label: 'Employed: Full-time' },
		{ value: 'parttime', label: 'Employed: Part-time' },
		{ value: 'selfemployed', label: 'Self-employed' },
	],
	...props
}) => {

	return (
		<div className={cf(s.flex, s.flexCenter, p.filterCon)}>
			<label
				htmlFor={`${tag}`}
				className={cf(s.wMax, s.flex, s.flexLeft)}
			>
				<label
					className={cf(
						s.wMax,
						s.flex,
						s.flexLeft,
						s.tLeft,
						s.p0,
						i.pInnerLabel
					)}
					htmlFor={`${tag}`}
				>
					{label}{' '}
					{required ? (
						<span className={cf(s.flex, s.flexTop, i.requiredMark)}>*</span>
					) : (
						<></>
					)}
				</label>
				<Select
					placeholder={placeholder}
					name={tag}
					onChange={handler}
					options={selectOptions}
					value={state[tag]}
					isMulti={isMulti}
					isSearchable={false}
					classNamePreFix={'react-select'}
					className={cf(
						s.wMax,
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter,
						i.label,
						s.p0,
						i.select,
						i.pLabel,
						isMulti ? i.ignoreHeight : ''
					)}
					classNames={{
						control: () =>
							cf(s.wMax, s.flex, s.m0, s.bNone, i.input, i.selectControl_),
						option: () => cf(s.wMax, s.flex, s.flexLeft, s.p5, i.selectOption),
						valueContainer: () => cf(s.p0, i.valueContainer),
						selectControl: () => cf(i.selectControl_),
						indicatorsContainer: () => cf(s.p0, s.m0, i.indicatorsContainer),
					}}
					{...props}
				/>
			</label>
		</div>
	)
}

export default Filter
