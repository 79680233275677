import React, { useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import x from '../../styles/Customs.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const DragAndDrop = ({ files }) => {
	return (
		<div className={cf(s.wMax, s.flex, s.flexCenter, x.customUpload)}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='69'
				height='60'
				viewBox='0 0 69 60'
				fill='none'
				className={cf(x.customUploadIcon)}
			>
				<path
					d='M36.0271 14.7458L36.1193 14.7733L36.1233 14.7688C36.561 14.8481 36.9951 14.586 37.1237 14.1519C38.2953 10.2152 41.9865 7.46504 46.0989 7.46504C46.5857 7.46504 46.9806 7.07016 46.9806 6.5833C46.9806 6.09643 46.5857 5.70156 46.0989 5.70156C41.0447 5.70156 36.7975 9.06665 35.4338 13.6493C35.2946 14.1162 35.5606 14.6067 36.0271 14.7458Z'
					fill='#483EA8'
					stroke='#F9FFF9'
					strokeWidth='0.3'
				/>
				<path
					d='M56.3438 42.4384H51.9534C51.5494 42.4384 51.2217 42.1107 51.2217 41.7067C51.2217 41.3027 51.5494 40.9749 51.9534 40.9749H56.3438C62.3956 40.9749 67.3197 36.0509 67.3197 29.999C67.3197 23.9471 62.3956 19.023 56.3438 19.023H56.2382C56.026 19.023 55.8242 18.9311 55.6852 18.7706C55.5462 18.6101 55.4834 18.3974 55.5138 18.1873C55.5791 17.7315 55.612 17.2737 55.612 16.8279C55.612 11.5829 51.3444 7.31531 46.0995 7.31531C44.059 7.31531 42.1131 7.95296 40.4719 9.15978C40.1112 9.42478 39.599 9.30718 39.3905 8.91047C34.7425 0.0596993 22.6023 -1.12887 16.3082 6.57053C13.6568 9.81417 12.615 14.0336 13.4498 18.146C13.5418 18.6002 13.1942 19.0236 12.7327 19.0236H12.4395C6.3876 19.0236 1.46353 23.9477 1.46353 29.9996C1.46353 36.0514 6.3876 40.9755 12.4395 40.9755H16.8298C17.2338 40.9755 17.5615 41.3032 17.5615 41.7072C17.5615 42.1113 17.2338 42.439 16.8298 42.439H12.4395C5.5805 42.439 0 36.8585 0 29.9995C0 23.3329 5.27155 17.8742 11.8651 17.5731C11.2457 13.3066 12.4301 9.00295 15.1751 5.64437C21.9138 -2.5996 34.828 -1.67556 40.2871 7.51707C42.0287 6.42522 44.0215 5.85244 46.0992 5.85244C52.4538 5.85244 57.4892 11.261 57.0486 17.58C63.5813 17.9463 68.7829 23.3763 68.7829 29.999C68.7829 36.8585 63.2024 42.4384 56.3434 42.4384L56.3438 42.4384Z'
					fill='#483EA8'
				/>
				<path
					d='M15.85 41.2935C15.85 51.4634 24.1237 59.737 34.2935 59.737C44.4634 59.737 52.737 51.4633 52.737 41.2935C52.737 31.1235 44.4634 22.85 34.2935 22.85C24.1235 22.85 15.85 31.1237 15.85 41.2935ZM17.6138 41.2935C17.6138 32.0966 25.0964 24.6138 34.2935 24.6138C43.4904 24.6138 50.9732 32.0964 50.9732 41.2935C50.9732 50.4904 43.4904 57.9732 34.2935 57.9732C25.0966 57.9732 17.6138 50.4905 17.6138 41.2935Z'
					fill='#483EA8'
					stroke='#F9FFF9'
					strokeWidth='0.3'
				/>
				<path
					d='M33.9437 48.6577C33.9437 49.0363 34.2508 49.3434 34.6295 49.3434C35.008 49.3434 35.3152 49.0367 35.3152 48.6577V34.7291C35.3152 34.3504 35.0081 34.0434 34.6295 34.0434C34.2508 34.0434 33.9437 34.3504 33.9437 34.7291V48.6577Z'
					fill='#483EA8'
					stroke='#483EA8'
					strokeWidth='0.3'
				/>
				<path
					d='M34.6262 35.6999L30.8255 39.5006L34.6262 35.6999ZM34.6262 35.6999L38.4269 39.5007C38.5606 39.6344 38.7367 39.7015 38.9118 39.7015L34.6262 35.6999ZM29.8556 39.5007C30.1234 39.7685 30.5578 39.7686 30.8254 39.5007L38.9118 39.7015C39.0866 39.7015 39.2628 39.635 39.3967 39.5006C39.6645 39.2327 39.6645 38.7988 39.3967 38.5309L35.111 34.2452C34.8432 33.9775 34.4088 33.9773 34.1412 34.2452C34.1412 34.2453 34.1412 34.2453 34.1411 34.2453L29.8556 38.5309C29.5877 38.7988 29.5877 39.2328 29.8556 39.5007Z'
					fill='#483EA8'
					stroke='#483EA8'
					strokeWidth='0.3'
				/>
			</svg>
			<span className={cf(s.wMax, s.flex, s.flexCenter, x.desktopUploadText)}>
				Drag & drop files or&nbsp;
				<span className={cf(x.uploadUnderline)}>Browse</span>
			</span>
			<span className={cf(s.wMax, s.flex, s.flexCenter, x.mobileUploadText)}>
				<span className={cf(x.uploadUnderline)}>Select file to upload</span>
			</span>
			<span className={cf(s.wMax, s.flex, s.flexCenter, x.supportedFormats)}>
				Supported formats: {files}
			</span>
		</div>
	)
}

const Uploading = ({ state, file, cancel }) => {
	return (
		<div
			className={cf(
				s.wMax,
				s.flex,
				s.spaceXBetween,
				s.spaceYCenter,
				x.uploadCon
			)}
		>
			<span className={cf(s.flex, s.flexCenter, x.fileName)}>{file.name}</span>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
				className={cf(x.cancelUpload)}
				onClick={cancel}
			>
				<path
					d='M10 20C7.34792 20 4.80414 18.9464 2.92894 17.0711C1.05363 15.1956 0 12.6521 0 10C0 7.34792 1.05363 4.80414 2.92894 2.92894C4.80441 1.05363 7.34792 0 10 0C12.6521 0 15.1959 1.05363 17.0711 2.92894C18.9464 4.80442 20 7.34792 20 10C19.9968 12.6512 18.9423 15.1931 17.0676 17.0676C15.1929 18.9422 12.6509 19.9968 10 20ZM13.8564 7.42802V7.42787C14.0317 7.25848 14.1317 7.02579 14.1338 6.78197C14.1359 6.53816 14.0401 6.30364 13.8676 6.1312C13.6952 5.95876 13.4607 5.86289 13.2169 5.86502C12.9731 5.86715 12.7404 5.96714 12.571 6.14247L10 8.71437L7.42904 6.14247C7.19816 5.9195 6.86683 5.83474 6.55712 5.91965C6.24757 6.00458 6.00575 6.24641 5.92081 6.55597C5.83589 6.86568 5.92066 7.197 6.14362 7.42788L8.71459 9.99978L6.14362 12.5717C5.9683 12.7411 5.86831 12.9738 5.86618 13.2176C5.86405 13.4614 5.95992 13.6959 6.13236 13.8684C6.30479 14.0408 6.53931 14.1367 6.78313 14.1345C7.02694 14.1324 7.25962 14.0324 7.42902 13.8571L9.99999 11.2852L12.571 13.8571C12.7404 14.0324 12.973 14.1324 13.2169 14.1345C13.4607 14.1367 13.6952 14.0408 13.8676 13.8684C14.0401 13.6959 14.1359 13.4614 14.1338 13.2176C14.1317 12.9738 14.0317 12.7411 13.8564 12.5717L11.2854 9.99978L13.8564 7.42802Z'
					fill='#E6E6E6'
				/>
			</svg>
		</div>
	)
}

const Uploaded = ({ file, deleteFile }) => {
	return (
		<div
			className={cf(
				s.wMax,
				s.flex,
				s.spaceXBetween,
				s.spaceYCenter,
				x.uploadCon
			)}
		>
			<span className={cf(s.flex, s.flexCenter, x.fileName)}>{file.name}</span>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
				className={x.deleteUpload}
				onClick={deleteFile}
			>
				<circle
					cx='10'
					cy='10'
					r='10'
					fill='#FFF3F3'
				/>
				<path
					d='M14.3461 6.43147H13.0967C13.08 6.42567 13.0626 6.42191 13.045 6.42018H11.7147V5.31685C11.7147 5.23393 11.6822 5.15433 11.6243 5.0951C11.5663 5.03589 11.4874 5.00173 11.4046 5H8.25839C8.17435 5 8.09377 5.03333 8.0344 5.09277C7.97496 5.15221 7.94155 5.2328 7.94155 5.31684V6.42018H6.61798C6.60038 6.42191 6.58299 6.42567 6.56629 6.43146H5.31684C5.2036 6.43146 5.09901 6.49181 5.04244 6.58985C4.98585 6.68789 4.98585 6.80864 5.04244 6.90668C5.09902 7.00472 5.2036 7.06514 5.31684 7.06514H6.33478L7.15732 14.7169C7.16612 14.7947 7.20329 14.8666 7.26175 14.9189C7.32014 14.9711 7.39576 14.9999 7.47415 15H12.1933C12.2709 14.9989 12.3453 14.9695 12.4029 14.9174C12.4604 14.8652 12.4969 14.794 12.5056 14.7169L13.3281 7.07631H14.3461C14.4593 7.07631 14.5639 7.01597 14.6205 6.91793C14.6771 6.81989 14.6771 6.69906 14.6205 6.60109C14.5639 6.50305 14.4593 6.44263 14.3461 6.44263L14.3461 6.43147ZM8.58207 5.63368H11.0809V6.42018H8.58207V5.63368ZM11.9057 14.3618H7.75728L6.97079 7.07639H12.6921L11.9057 14.3618Z'
					fill='#E41D1D'
				/>
				<path
					d='M9.83197 13.6201C9.91602 13.6201 9.9966 13.5867 10.056 13.5273C10.1155 13.4679 10.1488 13.3873 10.1488 13.3033V8.13477C10.1488 8.02153 10.0885 7.91694 9.99043 7.86036C9.8924 7.80371 9.77156 7.80371 9.67352 7.86036C9.57548 7.91694 9.51514 8.02153 9.51514 8.13477V13.3033C9.51514 13.3873 9.54847 13.4679 9.60791 13.5273C9.66735 13.5867 9.74793 13.6201 9.83198 13.6201H9.83197Z'
					fill='#E41D1D'
				/>
				<path
					d='M8.58425 13.618H8.59772C8.68138 13.6146 8.76024 13.5781 8.81713 13.5166C8.87393 13.4552 8.90418 13.3737 8.9011 13.29L8.71006 8.12149L8.71013 8.12141C8.7084 8.03676 8.67221 7.95641 8.60984 7.89907C8.54739 7.84181 8.46432 7.81254 8.37975 7.81804C8.29609 7.82157 8.21723 7.85807 8.16042 7.91954C8.10354 7.98101 8.07337 8.0625 8.07638 8.14617L8.26742 13.3147C8.2714 13.3962 8.30646 13.4731 8.36545 13.5295C8.42437 13.5858 8.50269 13.6176 8.58425 13.618L8.58425 13.618Z'
					fill='#E41D1D'
				/>
				<path
					d='M11.0673 13.6178H11.0785C11.162 13.6197 11.2429 13.5887 11.3038 13.5316C11.3647 13.4744 11.4008 13.3957 11.4043 13.3122L11.5954 8.14371H11.5953C11.5984 8.06004 11.5681 7.97855 11.5113 7.91707C11.4545 7.8556 11.3756 7.81911 11.2919 7.81557C11.2064 7.80677 11.1211 7.83438 11.0569 7.89172C10.9927 7.94898 10.9557 8.03069 10.9549 8.11677L10.7616 13.2853H10.7616C10.7573 13.3701 10.7873 13.4531 10.8448 13.5156C10.9022 13.5781 10.9824 13.615 11.0673 13.6178L11.0673 13.6178Z'
					fill='#E41D1D'
				/>
			</svg>
		</div>
	)
}

const Upload = ({ file }) => {
	return (
		<div
			className={cf(
				s.wMax,
				s.flex,
				s.spaceXBetween,
				s.spaceYCenter,
				x.uploadCon
			)}
		>
			<span className={cf(s.flex, s.flexCenter, x.fileName)}>{file.name}</span>
		</div>
	)
}

const Uploader = ({
	tag,
	state,
	handler,
	required,
	classes,
	fileTypes,
	multiple,
	removeFile,
	cancelUpload,
}) => {
	const [files, setFiles] = useState('')

	useEffect(() => {
		setFiles((x) =>
			fileTypes.reduce(
				(a, b) => String(a).toUpperCase() + ', ' + String(b).toUpperCase()
			)
		)
	}, [fileTypes])

	return (
		<FileUploader
			handleChange={handler}
			name={tag}
			fileOrFiles={state}
			types={fileTypes}
			classes={cf(s.wMax, s.flex, s.flexCenter, classes)}
			multiple={multiple}
			required={required}
		>
			{!state ? (
				<DragAndDrop files={files} />
			) : (
				<Uploaded
					file={state}
					deleteFile={removeFile}
				/>
			)}
		</FileUploader>
	)
}

export default Uploader
