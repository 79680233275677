import React, { useState } from 'react'
import { useMain, useAuth } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import Status from './Status'

const OnboardingStatus = () => {
	const { userDetails } = useAuth()
	const { isSmall } = useMain()
	const [kycSteps] = useState([
		{
			stage: 'Personal',
			step: 1,
			to: '/account/kyc/personal',
		},
		{
			stage: 'Identity',
			step: 2,
			to: '/account/kyc/identity',
		},
		{
			stage: 'Employment',
			step: 3,
			to: '/account/kyc/employment',
		},
		{
			stage: 'Finance',
			step: 4,
			to: '/account/kyc/finance',
		},
		{
			stage: 'Investment',
			step: 5,
			to: '/account/kyc/investment',
		},
	])

	const [focused, setFocused] = useState(kycSteps[kycSteps.length - 1].step)

	return (
		<div
			className={cf(
				s.flex,
				s.flex_dColumn,
				s.flexLeft,
				d.kycParent,
				// isSmall ? s.wMax : '',
				isSmall ? s.mT20 : ''
			)}
		>
			<span
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.txnsTitleCon
				)}
			>
				Onboarding Status
			</span>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.flex_dColumn,
					s.flexLeft,
					s.p_relative,
					d.kycStepsCon
				)}
			>
				{kycSteps.map((el, i) => (
					<Status {...{ key: i, focused, setFocused, ...el }} />
				))}
			</div>
			<>
				{userDetails?.kyc?.status ? (
					<span
						className={cf(
							s.wMax,
							s.flex,
							s.spaceXBetween,
							s.spaceYCenter,
							d.txnsTitleCon
						)}
					>
						{userDetails?.kyc?.status === 'completed'
							? 'Under Review'
							: userDetails?.kyc?.status === 'pending'
							? 'Incomplete'
							: userDetails?.kyc?.status}
					</span>
				) : (
					<span
						className={cf(
							s.wMax,
							s.flex,
							s.spaceXBetween,
							s.spaceYCenter,
							d.txnsTitleCon
						)}
					>
						Incomplete
					</span>
				)}
			</>
		</div>
	)
}

export default OnboardingStatus
