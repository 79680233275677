import React from 'react'
import arrow from '../../../../../assets/svg/arrow.svg'
import privacy from '../../../../../assets/svg/privacy.svg'
import d from '../../../../../styles/Dashboard.module.css'
import s from '../../../../../styles/Shared.module.css'
import { cf } from '../../../../../utils'

const SettingsThick = ({ tag, right = false }) => {
	return (
		<span
			className={cf(
				s.wMax,
				s.flex,
				s.flexLeft,
				d.settingsThick,
				right ? d.settingsThickRight : d.settingsThickLeft
			)}
		>
			{tag}
		</span>
	)
}

const SettingsLight = ({ tag }) => {
	return (
		<span className={cf(s.wMax, s.flex, s.flexLeft, d.settingsLight)}>
			{tag}
		</span>
	)
}

const SettingsInfo = ({
	title,
	text,
	val,
	children,
	neutral = false,
	isValRight = false,
}) => {
	return (
		<div className={cf(s.wMax, s.flex, s.flexLeft, d.settingsInfo)}>
			<div
				className={cf(
					s.flex,
					s.flexLeft,
					d.settingsInfoLeft,
					neutral ? d.neutral : ''
				)}
			>
				<SettingsThick {...{ tag: title }} />
				<SettingsLight {...{ tag: text }} />
			</div>
			<div
				className={cf(
					s.flex,
					s.flexLeft,
					d.settingsInfoLeft,
					neutral ? d.neutral : ''
				)}
			>
				{!children ? (
					<SettingsThick {...{ tag: val, right: isValRight }} />
				) : (
					children
				)}
			</div>
		</div>
	)
}

const ViewSwitch = ({ setView, tag, view }) => {
	return (
		<button
			className={cf(s.flex, s.flexCenter, d.settingsViewSetViewBtn)}
			onClick={() => setView((x) => view)}
		>
			<span className={cf(s.flex, s.flexCenter, d.settingsViewSetViewTxt)}>
				{tag}
			</span>
			<img
				src={arrow}
				alt={'settings view set view icon'}
				className={cf(s.flex, s.flexCenter, d.settingsViewSetViewIcon)}
			/>
		</button>
	)
}

const Privacy = ({ setView }) => {
	return (
		<div className={cf(s.flex, s.flexCenter, d.settingsView)}>
			<div className={cf(s.wMax, s.flex, s.flexLeft, d.settingsProfileCon)}>
				<div
					className={cf(s.flex, s.flexCenter, s.g10, d.settingsProfileIconCon)}
				>
					<img
						src={privacy}
						alt={'settings profile icon'}
						className={cf(d.settingsProfileIcon)}
					/>
				</div>
				<div
					className={cf(
						s.flex,
						s.flexLeft,
						s.flexOne,
						d.settingsProfileInfoCon
					)}
				>
					<span
						className={cf(s.wMax, s.flex, s.flexLeft, d.settingsProfileTitle)}
					>
						Privacy & Security Settings
					</span>
					<span
						className={cf(
							s.wMax,
							s.flex,
							s.flexLeft,
							d.settingsProfileSubTitle
						)}
					>
						Personalize account privacy and enhance security
					</span>
				</div>
			</div>
			<SettingsInfo
				{...{
					title: 'Change Password',
					text: 'Update password for enhanced account security',
				}}
			>
				<ViewSwitch
					{...{
						view: 'changePassword',
						setView,
						tag: 'Change Password',
					}}
				/>
			</SettingsInfo>
			{/* <SettingsInfo
				{...{
					title: 'Two-factor Authentication',
					text: 'Add an extra layer of protection to your account',
				}}
			>
				<ViewSwitch
					{...{
						view: 'twoFactorAuth',
						setView,
						tag: 'Manage authentication',
					}}
				/>
			</SettingsInfo> */}
		</div>
	)
}

export default Privacy
