import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import p from '../../styles/Properties.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const NavItem = ({ content, link, upcoming = false }) => {
	const location = useLocation()

	return (
		<Link
			to={link && !upcoming ? link : location.pathname}
			className={cf(
				s.flex,
				s.flexCenter,
				p.navItem,
				upcoming ? p.grayed : '',
				location.pathname.indexOf(link) === 0 ? p.focused : ''
			)}
		>
			<span className={cf(s.dInlineBlock, s.flex, s.flexCenter)}>
				{content}
			</span>
		</Link>
	)
}

export default NavItem
