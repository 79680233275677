import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { refreshRequest, request, requestUpload } from '../utils'

export const AuthContext = React.createContext()

const AuthContextProvider = ({ children }) => {
	const location = useLocation()
	const navigate = useNavigate()
	const [userID, setUserID] = useState('')
	const [userRole, setUserRole] = useState('')
	const [userAddress, setUserAddress] = useState('')
	const [accessToken, setAccessToken] = useState('')
	const [calledReset, setCalledReset] = useState(false)
	const [forReset, setForReset] = useState(false)
	const [calledVerify, setCalledVerify] = useState(false)
	const [authorized, setAuthorized] = useState(false)
	const [resendToEmail, setResendToEmail] = useState('')
	const [missingInfo, setMissingInfo] = useState({})
	const [userDetails, setUserDetails] = useState({ user: {}, kyc: null })
	const [kycValues, setKYCValues] = useState({})
	const [uncompleted, setUncompleted] = useState({})
	const [userDashboard, setUserDashboard] = useState({})

	const retrieveInfo = async (res, store = true, nav = true) => {
		if (res.success) {
			// TODO Update functionality
			setAccessToken((x) => res.data.access_token)
			if (store) window.localStorage.setItem(`cribvest`, res.data.refresh_token)
			else window.localStorage.clear()
			setAuthorized((x) => true)
			const userDetails = await request({
				path: 'user/me',
				accessToken: res.data.access_token,
			})
			setUserDetails((x) => ({ ...userDetails.data }))

			const userDashboard = await request({
				path: 'user/dashboard',
				accessToken: res.data.access_token,
			})
			setUserDashboard((x) => ({ ...userDashboard.data }))
			checkKYC({
				userKYC: userDetails?.data?.kyc ?? {},
				appKYC: kycValues,
				target: '/dashboard',
				enforceCompletion: nav,
				nav,
			})
			if (userDetails.data && userDetails.data.user) {
				const {
					firstName = null,
					lastName = null,
					phoneNumber = null,
					country = null,
					email = null,
					gender = null,
				} = userDetails.data.user
				if (
					!(firstName && lastName && phoneNumber && country && email && gender)
				) {
					setMissingInfo((x) => ({
						mFirstName: !Boolean(firstName),
						mLastName: !Boolean(lastName),
						mPhone: !Boolean(phoneNumber),
						mCountry: !Boolean(country),
						mEmail: !Boolean(email),
						mGender: !Boolean(gender),
					}))
					navigate('/account/update')
				}
			} else {
				setMissingInfo((x) => ({
					mFirstName: true,
					mLastName: true,
					mPhone: true,
					mCountry: true,
					mEmail: true,
					mGender: true,
				}))
				navigate('/account/update')
			}
		} else {
			// TODO Update functionality
		}
	}

	const login = async (params, store) => {
		const res = await request({
			path: 'auth/login',
			method: 'post',
			body: {
				...params,
			},
		})

		await retrieveInfo(res, store)

		return res
	}

	const signup = async (params) => {
		const res = await request({
			path: 'auth/signup',
			method: 'post',
			body: {
				...params,
			},
		})

		if (res.success) {
			// TODO Update functionality
			setCalledVerify((x) => true)
			setForReset((x) => false)
			navigate('/account/verify')
		} else {
			// TODO Update functionality
		}

		return res
	}

	const ssoLogin = (type) => {
		const kind = { apple: 'a-auth', google: 'g-auth', microsoft: 'm-auth' }[
			type
		]

		const serverURI = process.env.REACT_APP_BACKEND_BASE_URI

		return `${serverURI}/v1/auth/${kind}`
	}

	const ssoSignup = async (type) => {
		const kind = { apple: 'a-auth', google: 'g-auth', microsoft: 'm-auth' }[
			type
		]
		const res = await request({
			path: 'auth/' + kind,
		})

		// if (res.success) {
		// 	setAccessToken((x) => res.access_token)
		// 	setAuthorized((x) => true)
		// 	setCalledVerify((x) => true)
		// 	setForReset((x) => false)
		// 	navigate('/account/verify')
		// 	// TODO Update functionality
		// } else {
		// 	// TODO Update functionality
		// }

		return res.success
	}

	const refresh = async () => {
		const jwtRes = await refreshRequest()
		if (jwtRes?.access_token) {
			setAccessToken((x) => jwtRes?.access_token)
			setAuthorized((x) => true)
			retrieveInfo(
				{ success: jwtRes.success, data: { ...jwtRes } },
				true,
				false
			)
			return jwtRes?.access_token
		}
		return false
	}

	const makeRequest = async (
		{ path = '', method = 'get', body = {}, prop = 'data' } = {},
		upload = false
	) => {
		const xx = accessToken
		const response = upload
			? await requestUpload({
					path,
					method,
					file: body,
					accessToken: xx,
			  })
			: await request({
					path,
					method,
					body,
					accessToken: xx,
					prop,
			  })
		if (response.message === 'Unauthorized') {
			const xmx = await refresh()
			if (xmx)
				return upload
					? await requestUpload({
							path,
							method,
							file: body,
							accessToken: xmx,
					  })
					: await request({
							path,
							method,
							body,
							accessToken: xmx,
							prop,
					  })
			else {
				navigate('/account/login')
			}
		}
		return response
	}

	// Missing info on SSO signup
	const update = async (params, nav = true) => {
		const res = await makeRequest({
			path: 'user/update',
			method: 'post',
			body: {
				...params,
			},
		})
		if (res.success) {
			// setAccessToken((x) => res.access_token)
			checkKYC({
				userKYC: userDetails.kyc ?? {},
				appKYC: kycValues,
				target: '/dashboard',
				enforceCompletion: nav,
				nav,
			})
			// TODO Update functionality
		} else {
			// TODO Update functionality
		}

		return res
	}

	const resend = async (email) => {
		const res = await makeRequest({
			path: 'auth/forget-password?email=' + email || resendToEmail,
		})
		if (res.success) {
			// TODO Update functionality
		} else {
			// TODO Update functionality
		}
	}

	const reset = async (email) => {
		// TODO Update functionality
		resend(email)
		setForReset((x) => true)
		setCalledVerify((x) => true)
		navigate('/account/verify')
	}

	const updateKYC = (params = {}) => {
		let kycValues = {}
		setKYCValues((x) => {
			kycValues = { ...x, ...params }
			return { ...x, ...params }
		})
		checkKYC({
			userKYC: userDetails.kyc ?? {},
			appKYC: kycValues,
			enforceCompletion: true,
			secondaryTarget: '/account/kyc/completion',
		})
		return true
	}

	const doUpdateKYC = async (body = {}) => {
		setKYCValues((x) => ({ ...x, ...body }))
		const res = await makeRequest({
			path: 'user/kyc',
			method: 'post',
			body: {
				...kycValues,
				...body,
			},
		})

		if (res.success) {
			// TODO Update functionality
			setUserDetails((x) => ({
				...x,
				kyc: { ...x.kyc, ...res.data },
			}))
			checkKYC({
				userKYC: res.data ?? {},
				appKYC: kycValues,
				enforceCompletion: true,
				secondaryTarget: '/account/kyc/completion',
			})
		} else {
			// TODO Update functionality
		}
		return res.success
	}

	const getUserDetails = async () => {
		const res = await makeRequest({
			path: 'user/me',
		})
		if (res.success) {
			setUserDetails((x) => ({ ...res.data }))
		}
		return res.success ? res.data : {}
	}

	const checkKYC = ({
		userKYC = {},
		appKYC = {},
		target = location.pathname,
		nav = true,
		enforceCompletion = false,
		secondaryTarget = '',
	} = {}) => {
		if (userKYC === null) userKYC = {}
		const checkHasProperty = (property, user = {}, app = {}) => {
			if (
				user !== null &&
				user !== undefined &&
				user.hasOwnProperty(property) &&
				user?.[property]
			)
				return user?.[property]
			if (
				app !== null &&
				app !== undefined &&
				app.hasOwnProperty(property) &&
				app?.[property]
			)
				return app?.[property]
			return undefined
		}
		const properties = [
			'address',
			'annualIncome',
			'employedStatus',
			'employer',
			'dateOfBirth',
			'governmentID',
			'householdIncome',
			'industry',
			'investmentTypes',
			'investmentsValue',
			'jobTitle',
			'proofOfAddress',
			'riskTolerance',
			'yearsEmployed',
		]
		const fusedKYC = {},
			len = properties.length
		let p = 0
		for (p; p < len; p++) {
			fusedKYC[properties[p]] = checkHasProperty(properties[p], userKYC, appKYC)
		}
		const isPresent = (property) => {
			if (fusedKYC.hasOwnProperty(property) && fusedKYC?.[property])
				return fusedKYC?.[property]
			return undefined
		}
		const stages = {
			1: ['/account/kyc/personal', ['dateOfBirth', 'address']],
			2: ['/account/kyc/identity', ['governmentID', 'proofOfAddress']],
			3: [
				'/account/kyc/employment',
				{
					employedStatus: {
						unemployed: [],
						fulltime: ['yearsEmployed', 'employer', 'jobTitle', 'industry'],
						parttime: ['yearsEmployed', 'employer', 'jobTitle', 'industry'],
						selfemployed: ['yearsEmployed', 'employer', 'jobTitle', 'industry'],
					},
				},
			],
			4: [
				'/account/kyc/finance',
				['annualIncome', 'householdIncome', 'investmentsValue'],
			],
			5: ['/account/kyc/investment', ['investmentTypes', 'riskTolerance']],
		}

		let next = ''

		const checkStage = (stage, stages) => {
			if (!stage || !stages) return false
			const path = stages[stage][0]
			const keys = stages[stage][1]
			let completed = true
			if (typeof keys === 'object' && !Array.isArray(keys)) {
				const optionsDict = keys
				const options = Object.keys(optionsDict),
					oLen = options.length
				let o = 0
				for (o; o < oLen; o++) {
					const currentOption = optionsDict[options[o]]
					if (
						typeof currentOption === 'object' &&
						!Array.isArray(currentOption)
					) {
						const selectionDict = currentOption
						if (
							selectionDict.hasOwnProperty(fusedKYC?.[options[o]]) &&
							selectionDict[fusedKYC?.[options[o]]]
						) {
							const selections = selectionDict[fusedKYC?.[options[o]]],
								optLen = selections.length
							let opt = 0
							for (opt; opt < optLen; opt++) {
								const check = selections[opt]
								if (!isPresent(check)) {
									completed = false
								}
							}
						} else {
							completed = false
						}
					} else {
						if (!isPresent(currentOption)) {
							completed = false
						}
					}
				}
			} else {
				const kLen = keys.length
				let k = 0
				for (k; k < kLen; k++) {
					const check = keys[k]
					if (!isPresent(check)) {
						completed = false
					}
				}
			}
			if (!completed && !next) next = path
			return completed
		}

		const stagesKeys = Object.keys(stages),
			sLen = stagesKeys.length
		let s = 0,
			complete = 0
		for (s; s < sLen; s++) {
			const stage = stagesKeys[s]
			const completed = checkStage(stage, stages)
			setUncompleted((x) => ({
				...x,
				[stage]: completed,
			}))
			complete = completed ? complete : complete + 1
		}

		if (nav && enforceCompletion && next) navigate(next)
		else if (!next && secondaryTarget) navigate(secondaryTarget)
		else if (nav) navigate(target)
		return complete === 0
	}

	const logout = async () => {
		setAccessToken((x) => null)
		window.localStorage.clear()
		setAuthorized((x) => false)
		setUserDetails((x) => ({}))
		setUserDashboard((x) => ({}))
		setKYCValues((x) => ({}))
		navigate('/')
	}

	const getUserDashboard = async () => {
		if (authorized) {
			const res = await makeRequest({
				path: 'user/dashboard',
			})
			if (res.success) {
				setUserDashboard((x) => ({ ...res.data }))
			} else {
				setUserDashboard((x) => ({}))
			}
			return res.data
		}
		return {}
	}

	const AuthContextValue = {
		userID,
		setUserID,
		userRole,
		setUserRole,
		userAddress,
		accessToken,
		setUserAddress,
		login,
		signup,
		ssoLogin,
		ssoSignup,
		calledReset,
		setCalledReset,
		authorized,
		setAuthorized,
		makeRequest,
		missingInfo,
		setMissingInfo,
		update,
		calledVerify,
		setCalledVerify,
		resend,
		resendToEmail,
		setResendToEmail,
		forReset,
		setForReset,
		reset,
		updateKYC,
		userDetails,
		getUserDetails,
		setUserDetails,
		kycValues,
		setKYCValues,
		doUpdateKYC,
		checkKYC,
		uncompleted,
		setUncompleted,
		userDashboard,
		setUserDashboard,
		logout,
		getUserDashboard,
		retrieveInfo,
	}

	useEffect(() => {
		getUserDetails()
		getUserDashboard()

		setInterval(refresh, 480000)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorized])

	return (
		<AuthContext.Provider value={AuthContextValue}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthContextProvider
