import React from 'react'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const ISelector = ({ isPreSale, setIsPreSale }) => {
	return (
		<div className={cf(s.flex, s.flexCenter, d.selectorCon)}>
			<button
				className={cf(
					s.flex,
					s.flexCenter,
					d.sSelector,
					d.sPaddedSelector,
					isPreSale ? d.sNotSelected : ''
				)}
				onClick={() => setIsPreSale((x) => true)}
			>
				Presale
			</button>
			<button
				className={cf(
					s.flex,
					s.flexCenter,
					d.sSelector,
					d.sPaddedSelector,
					!isPreSale ? d.sNotSelected : ''
				)}
				onClick={() => setIsPreSale((x) => false)}
			>
				Completed
			</button>
		</div>
	)
}

export default ISelector
