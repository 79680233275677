import React, { useEffect, useState } from 'react'
import { useMain } from '../../hooks'
import p from '../../styles/Properties.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import Filter from './Filter'
import Search from './Search'

const PropertiesFilter = () => {
	const [requestBody, setRequestBody] = useState({})
	const { getPropertyFilterParams, propertyFilterParams, setPropertyFilters } =
		useMain()
	const [citySelectOptions, setCitySelectOptions] = useState([
		{ value: 'all', label: 'Any' },
	])
	const [bedroomSelectOptions, setBedroomSelectOptions] = useState([
		{ value: 'all', label: 'Any' },
	])
	const [typeSelectOptions, setTypeSelectOptions] = useState([
		{ value: 'all', label: 'Any' },
	])

	useEffect(() => {
		const { city, bedroom, type } = propertyFilterParams

		const cities = []
		cities.push({ value: 'all', label: 'Any' })
		city.forEach((x, i) => {
			cities.push({ value: x, label: x })
		})
		setCitySelectOptions((x) => [...cities])

		const bedrooms = []
		const bedroomRange = 5
		let bedroomCounter = 1
		bedrooms.push({ value: 'all', label: 'Any' })
		while (bedroomCounter <= bedroomRange) {
			bedrooms.push({
				value: bedroomCounter,
				label: bedroomCounter === 5 ? '5 or more' : bedroomCounter,
			})
			bedroomCounter++
		}
		setBedroomSelectOptions((x) => [...bedrooms])

		const types = []
		types.push({ value: 'all', label: 'Any' })
		type.forEach((x, i) => {
			types.push({
				value: String(x).toLowerCase(),
				label: String(x).slice(0, 1).toUpperCase().concat(String(x).slice(1)),
			})
		})
		setTypeSelectOptions((x) => [...types])
	}, [propertyFilterParams])

	useEffect(() => {
		getPropertyFilterParams()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestBody])

	const cityHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			city: x,
		}))

	const bedroomHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			bedroom: x,
		}))

	const typeHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			type: x,
		}))

	const handleSubmitHandler = async (e) => {
		e.preventDefault()
		let i = 0
		const keys = Object.keys(requestBody)
		const length = keys.length,
			reqBody = {}
		for (i; i < length; i++) {
			const el = requestBody[keys[i]]
			if (typeof el === 'object' && el.hasOwnProperty('value')) {
				reqBody[keys[i]] = el.value
			} else {
				reqBody[keys[i]] = el
			}
			if (Array.isArray(el)) {
				reqBody[keys[i]] = []
				let o = 0
				const keys_ = Object.keys({ label: true, value: true })
				const length = keys_.length
				for (o; o < length; o++) {
					if (typeof el[o] === 'object' && el[o].hasOwnProperty('value')) {
						reqBody[keys[i]].push(el[o].value)
					}
				}
			}
		}
		setPropertyFilters(reqBody)
	}

	return (
		<div className={cf(s.wMax, s.flex, s.flexCenter, p.propertiesFilterCon)}>
			<form
				className={cf(s.wMax, s.flex, s.flexBottom, p.innerPropertiesFilterCon)}
				onSubmit={handleSubmitHandler}
			>
				<div
					className={cf(
						s.flex,
						s.flexOne,
						s.spaceXBetween,
						s.spaceYEnd,
						p.propertiesFilterInnerCon
					)}
				>
					<Filter
						{...{
							label: 'LOCATION',
							tag: 'city',
							state: requestBody,
							handler: cityHandler,
							selectOptions: citySelectOptions,
						}}
					/>
					<Filter
						{...{
							label: 'TYPE OF PROPERTY',
							tag: 'type',
							state: requestBody,
							handler: typeHandler,
							selectOptions: typeSelectOptions,
						}}
					/>
					<div className={cf(s.wMax, s.flex, p.gap)}></div>
					<Filter
						{...{
							label: 'NUMBER OF ROOMS',
							tag: 'bedroom',
							state: requestBody,
							handler: bedroomHandler,
							selectOptions: bedroomSelectOptions,
						}}
					/>
					<Search {...{ clone: true }} />
				</div>
				<Search />
			</form>
		</div>
	)
}

export default PropertiesFilter
