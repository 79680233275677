import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMain } from '../../hooks'

const UnknownRouteHandler = () => {
	const prevRoute = useLocation().pathname
	const navigate = useNavigate()
	const { setRequestedURI } = useMain()
	useEffect(() => {
		setRequestedURI((x) => prevRoute)
		navigate('/404', {
			replace: true,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <div></div>
}

export default UnknownRouteHandler
