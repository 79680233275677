import React, { useEffect, useState } from 'react'
import { BoundSelector } from '../../../components/BoundSelector'
import { TSelector } from '../../../components/TSelector'
import { Transactions as TTransactions } from '../../../components/TTransactions'
import { useMain } from '../../../hooks'
import d from '../../../styles/Dashboard.module.css'
import s from '../../../styles/Shared.module.css'
import { cf } from '../../../utils'

const TransactionsPage = () => {
	const { isTiny, transactions } = useMain()
	const [type, setType] = useState('all')
	const [txns, setTxns] = useState([])

	useEffect(() => {
		switch (type) {
			case 'all':
				setTxns((x) => transactions)
				break
			case 'stock':
				setTxns((x) =>
					transactions.filter((el) => el.type === 'stock purchase')
				)
				break
			case 'topup':
				setTxns((x) => transactions.filter((el) => el.type === 'credit'))
				break
			case 'cashout':
				setTxns((x) => transactions.filter((el) => el.type === 'withdrawal'))
				break
			default:
				setTxns((x) => transactions)
				break
		}
	}, [type, transactions])

	return (
		<div className={cf(s.wMax, s.flex, s.flexTop, d.innerMain)}>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.tMainTitleCon
				)}
			>
				<div className={cf(s.flex, s.flex_dColumn, s.flexTop, d.tTitleCon)}>
					<h1 className={cf(s.flex, s.m0, s.p0, s.flexLeft, d.tTitle)}>
						Transaction History
					</h1>
					<span className={cf(s.flex, s.flexLeft, d.tSubTitle)}>
						Showing your recent transactions.
					</span>
				</div>
				{!isTiny ? <BoundSelector /> : <></>}
			</div>
			<div className={cf(s.wMax, s.flex, s.flexCenter, d.tSelectorCon)}>
				<TSelector {...{ type, setType }} />
			</div>
			{isTiny ? (
				<div className={cf(s.flex, s.flexCenter, d.tinyMonthCon)}>
					<BoundSelector />
				</div>
			) : (
				<></>
			)}
			<div className={cf(s.wMax, s.flex, s.flexCenter, d.tTransactionsCon)}>
				<TTransactions {...{ txns }} />
			</div>
		</div>
	)
}

export default TransactionsPage
