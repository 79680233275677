import React from 'react'
import { Link } from 'react-router-dom'
import f from '../../styles/Footer.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const Footer = () => {
	return (
		<div className={cf(s.wMax, s.container, s.flex, s.spaceXBetween, f.footer)}>
			<span className={cf(s.flex, s.flexCenter, f.copy)}>
				© Cribvest Limited
			</span>
			<Link
				to={'https://cribvest.com/terms-and-conditions/'}
				className={cf(s.flex, s.flexCenter, f.terms)}
			>
				Terms & Condition
			</Link>
		</div>
	)
}

export default Footer
