import React, { useRef, useState } from 'react'
import chevronWhite from '../../assets/svg/chevronWhite.svg'
import { useMain } from '../../hooks'
import p from '../../styles/Properties.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const ImagesView = () => {
	const {
		setShowImagesView,
		isTiny,
		property: { image } = { image: [] },
	} = useMain()
	const [position, setPosition] = useState(0)

	const carousalRef = useRef()
	const switchImage = (position, length) => {
		if (carousalRef.current) {
			carousalRef.current.style.transform = `translate(-${
				position > length - 1
					? (length - 1) * 100
					: position < 0
					? 0
					: position * 100
			}%, 0)`
			setPosition((x) =>
				position < 0 ? 0 : position > length - 1 ? length - 1 : position
			)
		}
	}

	const swipeLeft = () => {
		const position_ = position - 1
		if (carousalRef.current) {
			carousalRef.current.style.transform = `translate(-${
				position_ < 0 ? 0 : position_ * 100
			}%, 0)`
			setPosition((x) => (position_ < 0 ? 0 : position_))
		}
	}

	const swipeRight = (length) => {
		const position_ = position + 1
		if (carousalRef.current) {
			carousalRef.current.style.transform = `translate(-${
				position_ > length - 1 ? (length - 1) * 100 : position_ * 100
			}%, 0)`
			setPosition((x) => (position_ > length - 1 ? length - 1 : position_))
		}
	}

	return (
		<div
			className={cf(s.wMax, s.p_fixed, s.flex, s.flexCenter, p.imgViewParent)}
		>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.flex_dColumn,
					s.flexCenter,
					s.p_relative
				)}
			>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						s.p_relative,
						p.imgViewBigCarousalCon
					)}
				>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.flexCenter,
							s.p_relative,
							p.imgViewBigCarousal
						)}
					>
						<div
							className={cf(
								s.flex,
								s.wMax,
								s.flex_dColumn,
								s.flexCenter,
								p.imgViewBigOnes
							)}
							ref={carousalRef}
						>
							{image.length ? (
								image.map((el, i) => (
									<div
										className={cf(
											s.wMax,
											s.flex,
											s.flexCenter,
											s.hMax,
											p.imgViewPropertySampleCon
										)}
										key={i}
									>
										<img
											src={el}
											alt={'property sample'}
											className={cf(
												s.hMax,
												s.flex,
												s.flexCenter,
												p.imgViewPropertySample
											)}
										/>
									</div>
								))
							) : (
								<></>
							)}
						</div>
						<button
							className={cf(s.flex, s.flexCenter, p.progressButton)}
							onClick={() => swipeLeft()}
						>
							<img
								src={chevronWhite}
								alt={'chevron white'}
								className={cf(p.progressButtonIcon)}
							/>
						</button>
						<button
							className={cf(
								s.flex,
								s.flexCenter,
								p.progressButton,
								p.progressButtonRight
							)}
							onClick={() => swipeRight(image.length)}
						>
							<img
								src={chevronWhite}
								alt={'chevron white'}
								className={cf(p.progressButtonIcon, p.progressButtonIconRight)}
							/>
						</button>
					</div>
				</div>
				<div
					className={cf(s.flex, s.flexCenter, s.wMax, p.imgViewSmallOnesCon)}
				>
					<div
						className={cf(s.flex, s.hMax, s.flex_dColumn, p.imgViewSmallOnes)}
					>
						{image.length ? (
							image.map((el, i) => (
								<img
									src={el}
									alt={'mini property sample'}
									className={cf(
										s.flex,
										s.hMax,
										s.flexCenter,
										p.imgViewMiniPropertySample,
										position === i ? p.imgInView : ''
									)}
									key={i}
									onClick={() => switchImage(i, image.length)}
								/>
							))
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
			<button
				className={cf(
					s.flex,
					s.flexCenter,
					p.progressButton,
					p.progressButtonRight,
					p.imgViewClose
				)}
				onClick={() => setShowImagesView((x) => false)}
			>
				{!isTiny ? (
					<svg
						width='24'
						height='25'
						viewBox='0 0 24 25'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<g id='Icon/close'>
							<path
								id='Path 2'
								d='M17.6569 18.1566L6.34319 6.84285'
								stroke='white'
								strokeLinecap='round'
							/>
							<path
								id='Path 2_2'
								d='M17.6568 6.84285L6.34311 18.1566'
								stroke='white'
								strokeLinecap='round'
							/>
						</g>
					</svg>
				) : (
					'Close'
				)}
			</button>
		</div>
	)
}

export default ImagesView
