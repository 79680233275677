import axios from 'axios'

/**
 * Sets the background of the element ref to that of the user's pfp
 * @param {any} others An array of arrays - [][ref, path, toBeAwaited]...
 */
export const setBGs = async (others, preventPreLoad = false) => {
	if (others) {
		const oLen = others.length
		let i = 0
		for (i; i < oLen; i++) {
			try {
				const opts = others[i]
				const ref = opts[0]
				const toBeAwaited = opts[2] || false
				if (toBeAwaited) {
					const image = await axios.get(opts[1])
					ref.current.style.background = image
				} else {
					ref.current.style.background = `url(${opts[1]})`
				}
				ref.current.style.backgroundPosition = 'center'
				ref.current.style.backgroundRepeat = 'no-repeat'
				ref.current.style.backgroundSize = 'cover'
			} catch (error) {
				// Do nothing
			}
		}
	}
}
