import React from 'react'
import 'react-phone-number-input/style.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Redirect } from '../../components/Redirect'
import { useMain } from '../../hooks'
import a from '../../styles/Account.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import { UnknownRouteHandler } from '../404'
import { Login } from './login'
import { Onboarding } from './onboarding'
import { Reset } from './reset'
import { Signup } from './signup'
import { UpdateUser } from './updateUser'
import { VerifyEmail } from './verifyEmail'

const Account = () => {
	const { bigText, smallText } = useMain()
	const location = useLocation()
	return (
		<div
			className={cf(s.wMax, s.flex, s.flex_nowrap, s.spaceXBetween, s.window)}
		>
			{location.pathname.indexOf('/account/kyc') !== 0 ? (
				<div
					className={cf(s.flex, s.flexLeft, a.extraTextCon, s['flex_dColumn'])}
				>
					<div
						className={cf(
							s.flex,
							s.flexRight,
							s.parent,
							a.extraTextInnerCon,
							s.flex_dColumn
						)}
					>
						<h1 className={cf(s.m0, s.p0, a.bigTxt)}>{bigText}</h1>
						<div
							className={cf(
								s.wMax,
								s.flex,
								s.flexRight,
								s.p_relative,
								a.noteCon
							)}
						>
							<span className={cf(a.noteStrike, s.p_absolute)}></span>
							<span className={cf(s.flex, s.tLeft, a.note)}>{smallText}</span>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
			<div
				className={cf(
					s.flex,
					s.parent,
					a.actualContent,
					location.pathname.indexOf('/account/kyc') !== 0 ? a.scrollable : '',
					location.pathname.indexOf('/account/kyc') === 0 ? s.wMax : '',
					location.pathname.indexOf('/account/kyc') === 0
						? s.flexTop
						: s.flexCenter
				)}
			>
				<Routes>
					<Route
						path='/'
						element={<Redirect to={location.pathname + '/login'} />}
					/>
					<Route
						path='login'
						element={<Login />}
					/>
					<Route
						path='signup'
						element={<Signup />}
					/>
					<Route
						path='reset'
						element={<Reset />}
					/>
					<Route
						path='update'
						element={<UpdateUser />}
					/>
					<Route
						path='verify'
						element={<VerifyEmail />}
					/>
					<Route
						path='kyc/*'
						element={<Onboarding />}
					/>
					<Route
						path='/*'
						element={<UnknownRouteHandler />}
					/>
				</Routes>
			</div>
		</div>
	)
}

export default Account
