import React from 'react'
import arrow from '../../assets/svg/arrow.svg'
import ticketDark from '../../assets/svg/ticketDark.svg'
import { useMain } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const Ticket = ({ title, lastMessage, isClosed }) => {
	const { isTiny } = useMain()
	return (
		<div className={cf(s.wMax, s.flex, s.flexLeft, d.supportTicketParent)}>
			<div className={cf(s.flex, s.flexCenter, d.supportTicketIconCon)}>
				<img
					src={ticketDark}
					alt={'support ticket icon dark'}
					className={cf(s.flex, s.flexCenter, d.supportTicketIcon)}
				/>
			</div>
			<div
				className={cf(s.flex, s.flexLeft, s.flexOne, d.supportTicketInfoCon)}
			>
				<div
					className={cf(s.wMax, s.flex, s.flexLeft, d.supportTicketTitleCon)}
				>
					<span
						className={cf(
							s.dInlineBlock,
							s.tLeft,
							d.supportTicketTitle,
							s.tOverflowEllipsis
						)}
					>
						{!isTiny ? 'Subject:' : ''}&nbsp;{title}
					</span>
					<div
						className={cf(
							s.flex,
							s.flexCenter,
							d.supportTicketStatusCon,
							isClosed ? d.supportTicketStatusClosed : d.supportTicketStatusOpen
						)}
					>
						<span
							className={cf(s.flex, s.flexCenter, d.supportTicketStatusTxt)}
						>
							{isClosed ? 'Closed' : 'Open'}
						</span>
					</div>
				</div>
				{!isTiny ? (
					<span
						className={cf(
							s.wMax,
							s.dInlineBlock,
							s.tLeft,
							d.supportTicketMessage,
							s.tOverflowEllipsis
						)}
					>
						{!isClosed ? 'You:' : 'Cribvest Support:'}&nbsp;{lastMessage}
					</span>
				) : (
					<></>
				)}
			</div>
			<img
				src={arrow}
				alt={'support ticket chevron right icon'}
				className={cf(s.flex, s.flexCenter, d.supportTicketChevronIcon)}
			/>
		</div>
	)
}

export default Ticket
