import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import sortArrayOfObjects from 'sort-array-of-objects/sortArrayOfObjects'
import { InputField } from '../../../../components/InputField'
import { useAuth, useMain } from '../../../../hooks'
import a from '../../../../styles/Account.module.css'
import o from '../../../../styles/Onboarding.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf } from '../../../../utils'

const Step = () => {
	const [requestBody, setRequestBody] = useState({})
	const [options] = useState([
		{ value: 'unemployed', label: 'Unemployed' },
		{ value: 'fulltime', label: 'Employed: Full-time' },
		{ value: 'parttime', label: 'Employed: Part-time' },
		{ value: 'selfemployed', label: 'Self-employed' },
	])
	const [optionsIndustry] = useState([
		{ value: 'construction', label: 'Construction' },
		{ value: 'food', label: 'Food' },
		{ value: 'education', label: 'Education' },
		{ value: 'telecommunications', label: 'Telecommunications' },
		{ value: 'energy', label: 'Energy' },
		{ value: 'software', label: 'Software' },
		{ value: 'restaurant', label: 'Restaurant' },
		{ value: 'plastic', label: 'Plastic' },
		{ value: 'retail', label: 'Retail' },
		{ value: 'manufacturing', label: 'Manufacturing' },
		{ value: 'agriculture', label: 'Agriculture' },
		{ value: 'automobile-engineering', label: 'Automobile Engineering' },
		{ value: 'aerospace', label: 'Aerospace' },
		{ value: 'advertising', label: 'Advertising' },
		{ value: 'automotive', label: 'Automotive' },
		{ value: 'electronics', label: 'Electronics' },
		{ value: 'transport', label: 'Transport' },
		{ value: 'insurance', label: 'Insurance' },
		{ value: 'financial-services', label: 'Financial Services' },
		{ value: 'hospitality', label: 'Hospitality' },
		{ value: 'publishing', label: 'Publishing' },
		{ value: 'commerce', label: 'Commerce' },
		{ value: 'biotechnology', label: 'Biotechnology' },
		{ value: 'professional-services', label: 'Professional Services' },
	])
	// const [optionsDuration] = useState([
	// 	{ value: '1-3years', label: '1 - 3 years' },
	// 	{ value: '3-5years', label: '3 - 5 years' },
	// 	{ value: '5-10years', label: '5 - 10 years' },
	// 	{ value: '10years-over', label: 'Over 10 years' },
	// ])
	const [isDisabled, setIsDisabled] = useState(true)
	const { setCurrentStep } = useMain()
	const { doUpdateKYC, uncompleted } = useAuth()
	const navigate = useNavigate()

	useLayoutEffect(() => {
		setCurrentStep((x) => 3)
		if (uncompleted[3]) {
			navigate('/account/kyc/finance')
		}
		return () => {
			setCurrentStep((x) => 3)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uncompleted])

	const handler = async (e) => {
		const name = e.target.name
		let value = e.target.value

		const validatorObj = {
			employer: true,
			position: true,
		}

		switch (name) {
			default:
				if (validatorObj[name])
					setRequestBody((e) => ({
						...e,
						[name]: value,
					}))
				break
		}
	}

	const statusHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			status: x,
		}))
	const industryHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			industry: x,
		}))
	const durationHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			duration: x.target.value,
		}))

	const handleSubmitHandler = async (e) => {
		e.preventDefault()
		let i = 0
		const keys = Object.keys(requestBody)
		const length = keys.length,
			reqBody = {}
		for (i; i < length; i++) {
			const el = requestBody[keys[i]]
			if (typeof el === 'object' && el.hasOwnProperty('value')) {
				reqBody[keys[i]] = el.value
			} else {
				reqBody[keys[i]] = el
			}
			if (Array.isArray(el)) {
				reqBody[keys[i]] = []
				let o = 0
				const keys_ = Object.keys({ label: true, value: true })
				const length = keys_.length
				for (o; o < length; o++) {
					if (typeof el[o] === 'object' && el[o].hasOwnProperty('value')) {
						reqBody[keys[i]].push(el[o].value)
					}
				}
			}
		}
		const {
			duration,
			status,
			employer = '',
			industry = '',
			position = '',
		} = reqBody
		const body = {
			yearsEmployed: Number(duration ?? 0),
			employedStatus: status,
			employer,
			industry,
			jobTitle: position,
		}
		doUpdateKYC(body)
	}

	useEffect(() => {
		setIsDisabled(
			(x) =>
				!(requestBody['status'] && requestBody['status'].value !== 'unemployed'
					? requestBody['employer'] &&
					  requestBody['position'] &&
					  requestBody['industry'] &&
					  requestBody['duration'] &&
					  Number(requestBody['duration']) > 0
					: requestBody['status'])
		)
	}, [requestBody])

	useEffect(() => {
		if (requestBody['status'] && requestBody['status'].value === 'unemployed') {
			setRequestBody((e) => ({
				status: e.status,
			}))
		}
	}, [requestBody])

	useEffect(() => {
		if (
			requestBody['status'] &&
			requestBody['status'].value === 'selfemployed'
		) {
			setRequestBody((e) => {
				const { employer, ...rest } = e
				return {
					...rest,
				}
			})
		}
	}, [requestBody])

	return (
		<div className={cf(s.flex, s.flexCenter, a.accountChild)}>
			<h1 className={cf(s.wMax, s.tLeft, s.m0, s.p0, a.theme)}>
				Employment Details
			</h1>
			<span className={cf(s.wMax, s.flex, s.flexLeft, s.tLeft, o.underTheme)}>
				Provide the following information to continue.
			</span>

			<form
				className={cf(s.wMax, s.flex, s.flexCenter, a.form)}
				onSubmit={handleSubmitHandler}
			>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.flex_dColumn,
						s.flexCenter,
						a.inputFieldCon
					)}
				>
					<InputField
						tag={'status'}
						state={requestBody}
						handler={statusHandler}
						type={'select'}
						selectOptions={options}
						placeholder={'Employment Status'}
					/>
					{requestBody['status'] &&
					requestBody['status'].value !== 'unemployed' ? (
						<InputField
							tag={'industry'}
							state={requestBody}
							handler={industryHandler}
							type={'select'}
							selectOptions={sortArrayOfObjects(optionsIndustry, 'value')}
							placeholder={'Industry'}
						/>
					) : (
						<></>
					)}
					{requestBody['status'] &&
					requestBody['status'].value !== 'unemployed' ? (
						<InputField
							tag={'duration'}
							state={requestBody}
							handler={durationHandler}
							type={'number'}
							placeholder={'Years Employed'}
						/>
					) : (
						<></>
					)}
					{requestBody['status'] &&
					requestBody['status'].value !== 'unemployed' &&
					requestBody['status'].value !== 'selfemployed' ? (
						<InputField
							tag={'employer'}
							state={requestBody}
							handler={handler}
							type={'text'}
							placeholder={'Employer'}
						/>
					) : (
						<></>
					)}
					{requestBody['status'] &&
					requestBody['status'].value !== 'unemployed' ? (
						<InputField
							tag={'position'}
							state={requestBody}
							handler={handler}
							type={'text'}
							placeholder={'Job Title'}
						/>
					) : (
						<></>
					)}
				</div>

				<button
					type={'submit'}
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						a.submitBtn,
						!isDisabled ? s.shadow : '',
						isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
					)}
					disabled={isDisabled}
				>
					{isDisabled ? 'Fill the form' : 'Continue'}
				</button>

				<div
					className={cf(s.wMax, s.flex, s.flexLeft, a.rememberToForget, s.p5)}
				>
					<span className={cf(s.wMax, s.dInlineBlock, s.tLeft, a.notice)}>
						The information provided would be saved securely as per our&nbsp;
						<Link
							to={'https://cribvest.com/terms-and-conditions/'}
							className={cf(s.link)}
						>
							Terms of Service
						</Link>
						&nbsp;&&nbsp;
						<Link
							to={'https://cribvest.com/privacy-policy/'}
							className={cf(s.link)}
						>
							Privacy Policy
						</Link>
					</span>
				</div>
			</form>

			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='482'
				height='2'
				viewBox='0 0 482 2'
				fill='none'
				className={cf(s.wMax, a.strike)}
			>
				<path
					d='M0 1H482'
					stroke='#B2B2B2'
					strokeWidth='0.5'
				/>
			</svg>

			<span className={cf(s.wMax, s.flex, s.flexCenter, s.tCenter, a.instead)}>
				Want to fill this later?&nbsp;
				<Link
					to={'/account/kyc/finance'}
					className={cf(s.flex, a.alt)}
				>
					Skip this step
				</Link>
			</span>
		</div>
	)
}

export default Step
