import React, { useEffect, useState } from 'react'
import calendar from '../../assets/svg/calendar-dates.svg'
import { useMain } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import Bounds from './Bounds'

const bounds = [
	{ tag: 'Today', value: 'day' },
	{ tag: 'This week', value: 'week' },
	{ tag: 'This month', value: 'month' },
	{ tag: 'This year', value: 'year' },
]

const BoundSelector = ({ isPreSale, setIsPreSale }) => {
	const [showBounds, setShowBounds] = useState(false)
	const [bound, setBound] = useState(bounds[2])
	const { getTransactions } = useMain()

	useEffect(() => {
		getTransactions(bound.value)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bound])

	return (
		<div
			className={cf(s.flex, s.flexCenter, d.dateSelectorCon)}
			// onClick={() => setShowProfileGroup((x) => !x)}
		>
			<div
				className={cf(s.flex, s.flexCenter, d.dateSelectorConInner, s.pointer)}
				// onClick={() => setShowProfileGroup((x) => !x)}
			>
				<img
					src={calendar}
					// ref={avatarRef}
					alt={'date selector'}
					className={cf(s.flex, s.flexCenter, d.chevronDown)}
					onClick={() => setShowBounds((x) => !x)}
				/>
				<span
					className={cf(s.flex, s.flexCenter, d.dateSelectorMonth)}
					onClick={() => setShowBounds((x) => !x)}
				>
					{bound.tag}
				</span>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='25'
					viewBox='0 0 24 25'
					fill='none'
					className={cf(
						d.chevronDown,
						d.black,
						showBounds ? d.oStatusFlip : ''
					)}
					onClick={() => setShowBounds((x) => !x)}
				>
					<path
						d='M6 10.5L12 16.5L18 10.5'
						stroke='white'
						strokeLinecap='round'
					/>
				</svg>
			</div>
			{showBounds ? <Bounds {...{ setShowBounds, bounds, setBound }} /> : <></>}
		</div>
	)
}

export default BoundSelector
