import React, { useEffect, useState } from 'react'
import backArrow from '../../../../assets/svg/backArrow.svg'
import { FileUploader } from '../../../../components/FileUploader'
import { Ticket } from '../../../../components/Ticket'
import { WalletInput } from '../../../../components/WalletInput'
import { useAuth, useMain } from '../../../../hooks'
import a from '../../../../styles/Account.module.css'
import d from '../../../../styles/Dashboard.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf } from '../../../../utils'

const OpenTicket = ({
	requestBody,
	handleChange,
	options,
	departmentHandler,
	docUploaded,
	handleSubmitHandler,
	fileUploadOptions = {
		tag: '',
		state: {},
		handler: () => {},
		required: true,
		classes: cf(s.flex),
		fileTypes: [],
		multiple: false,
		removeFile: () => {},
	},
}) => {
	const [wordsLeft, setWordsLeft] = useState(250)
	const [isDisabled, setIsDisabled] = useState(true)

	useEffect(() => {
		const { complaint = '' } = requestBody
		const words = complaint ? String(complaint).trim().split(' ').length : 0
		setWordsLeft((x) => 250 - words)
	}, [requestBody])

	useEffect(() => {
		const { subject = null, complaint = null, department = null } = requestBody
		setIsDisabled((x) => !(docUploaded && subject && complaint && department))
	}, [requestBody, docUploaded])

	return (
		<form
			className={cf(s.flex, s.flex_dColumn, s.flexLeft, d.ticketingViewTForm)}
			onSubmit={handleSubmitHandler}
		>
			<span
				className={cf(s.wMax, s.flex, s.flexLeft, d.ticketingViewTFormTitle)}
			>
				Open a ticket
			</span>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.flex_dColumn,
					s.flexLeft,
					d.ticketingInputCon
				)}
			>
				<WalletInput
					{...{
						tag: 'subject',
						state: requestBody,
						required: true,
						label: 'Subject',
						handler: handleChange,
						type: 'text',
						placeholder: 'Enter a title',
					}}
				/>
				<WalletInput
					{...{
						tag: 'department',
						state: requestBody,
						required: true,
						label: 'Department',
						handler: departmentHandler,
						type: 'select',
						selectOptions: options,
						placeholder: 'Payment',
					}}
				/>
				<div className={cf(s.wMax, s.flex, s.flexCenter)}>
					<WalletInput
						{...{
							tag: 'complaint',
							state: requestBody,
							required: true,
							label: 'Complaint',
							handler: handleChange,
							type: 'text',
							placeholder: 'Your message',
							useTextarea: true,
							rows: 5,
						}}
					/>
					<span
						className={cf(
							s.wMax,
							s.flex,
							s.flexLeft,
							d.ticketingViewComplaintNotice
						)}
					>
						Maximum of 250 words: {wordsLeft} left
					</span>
				</div>
				<div className={cf(s.wMax, s.flex, s.flexCenter)}>
					<span
						className={cf(
							s.wMax,
							s.flex,
							s.flexLeft,
							d.ticketingViewFileUploadTitle
						)}
					>
						Attach file
					</span>
					<FileUploader {...{ ...fileUploadOptions }} />
				</div>
				<button
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						d.settingsPasswordChangeBtn,
						d.ticketingViewSubmitBtn,
						!isDisabled ? s.shadow : '',
						isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
					)}
					type={'submit'}
					disabled={isDisabled}
				>
					{isDisabled ? 'Fill the form' : 'Create Ticket'}
				</button>
			</div>
		</form>
	)
}

const Chat = () => {
	return (
		<div
			className={cf(s.flex, s.flex_dColumn, s.flexLeft, d.ticketingViewTForm)}
		></div>
	)
}

const TicketingView = ({ view, setView }) => {
	const { setShowTicketingView, isTiny } = useMain()
	const { makeRequest } = useAuth()
	const [requestBody, setRequestBody] = useState({})
	const [options] = useState([
		{ value: 'department', label: 'Cribvest' },
		// { value: 'fulltime', label: 'Employed: Full-time' },
		// { value: 'parttime', label: 'Employed: Part-time' },
		// { value: 'selfemployed', label: 'Self-employed' },
	])
	const [docUploaded, setDocUploaded] = useState(false)

	const docHandler = async (file) => {
		setRequestBody((x) => ({
			...x,
			doc: file,
		}))
	}
	useEffect(() => {
		if (requestBody.doc) {
			const doSomething = async () => {
				const res = await makeRequest(
					{
						path: 'user/upload',
						method: 'post',
						body: requestBody.doc,
					},
					true
				)
				if (res.data && res.data.url) {
					setDocUploaded((x) => res.data.url)
				} else {
					setDocUploaded((x) => '')
				}
			}
			doSomething()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestBody.doc])
	const removeDoc = () => {
		setRequestBody((x) => ({
			...x,
			doc: null,
		}))
	}
	const handleSubmitHandler = async (e) => {
		e.preventDefault()
		// TODO Update functionality
		const body = {
			doc: docUploaded,
		}
		// const res = updateKYC(body)
		// if (res) {
		// 	navigate('/account/kyc/employment')
		// }
	}

	const departmentHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			department: x,
		}))

	const handleChange = (e) => {
		const name = e.target.name
		const value = e.target.value

		const tags = {
			subject: true,
			complaint: true,
		}

		switch (name) {
			case 'complaint':
				const words = value ? String(value).trim().split(' ').length : 0
				const exceeded = words > 250
				setRequestBody((x) => ({
					...x,
					[name]: exceeded ? x[name] : value,
				}))
				break
			default:
				if (tags[name]) {
					setRequestBody((x) => ({
						...x,
						[name]: value,
					}))
				}
				break
		}
	}

	return (
		<div className={cf(s.wMax, s.flex, s.flexTop, d.innerMain)}>
			<div className={cf(s.wMax, s.flex, s.flexTop, d.ticketingView)}>
				<div className={cf(s.wMax, s.flex, s.flexLeft, d.ticketingViewExitCon)}>
					<button
						type={'button'}
						className={cf(s.flex, s.flexCenter, d.ticketingViewExit)}
						onClick={() => setShowTicketingView((x) => false)}
					>
						<img
							src={backArrow}
							alt={'ticketing view exit button'}
							className={cf(s.flex, s.flexCenter, d.ticketingViewExitIcon)}
						/>
						<span className={cf(s.flex, s.flexCenter, d.ticketingViewExitTxt)}>
							Go back
						</span>
					</button>
				</div>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.spaceXBetween,
						s.spaceYStart,
						d.ticketingViewTicketingCon
					)}
				>
					{view === 'create' ? (
						<OpenTicket
							{...{
								requestBody,
								handleChange,
								options,
								departmentHandler,
								docUploaded,
								handleSubmitHandler,
								fileUploadOptions: {
									tag: 'doc',
									state: requestBody.doc,
									handler: docHandler,
									required: true,
									fileTypes: ['JPEG', 'PNG', 'PDF'],
									multiple: false,
									removeFile: removeDoc,
								},
							}}
						/>
					) : view === 'chat' ? (
						<Chat />
					) : (
						<></>
					)}
					{!isTiny ? (
						<div
							className={cf(s.flex, s.flexLeft, d.ticketingViewCreatedTickets)}
						>
							<span
								className={cf(
									s.wMax,
									s.flexLeft,
									d.ticketingViewCreatedTicketsTitle
								)}
							>
								Created Tickets
							</span>
							<div
								className={cf(
									s.wMax,
									s.flexCenter,
									d.ticketingViewCreatedTicketsCon
								)}
							>
								<Ticket
									{...{
										title: 'Having issues with funding my wallet.',
										lastMessage:
											'Card keeps declining. kindly look into it ASAP.',
										isClosed: false,
									}}
								/>
								<Ticket
									{...{
										title: 'Issue with my Identity verification',
										lastMessage:
											'Kindly re-submit a clear scan of the documents',
										isClosed: true,
									}}
								/>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	)
}

export default TicketingView
