import React, { useRef, useState } from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import Select from 'react-select'
import i from '../../styles/InputField.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const InputField = ({
	tag,
	state,
	handler,
	type,
	placeholder,
	mini = false,
	required = true,
	selectOptions = null,
	isMulti = false,
	...props
}) => {
	const [isPassword] = useState(type === 'password')
	const [visible, setVisible] = useState(false)
	const [showDatePlaceholder, setShowDatePlaceholder] = useState(true)
	const inputRef = useRef()

	return type === 'select' ? (
		<Select
			placeholder={placeholder}
			name={tag}
			onChange={handler}
			options={selectOptions}
			value={state[tag]}
			isMulti={isMulti}
			isSearchable={false}
			classNamePreFix={'react-select'}
			className={cf(
				s.wMax,
				s.flex,
				s.spaceXBetween,
				s.spaceYCenter,
				i.label,
				s.p0,
				isMulti ? i.ignoreHeight : ''
			)}
			classNames={{
				control: () =>
					cf(s.wMax, s.flex, s.m0, s.bNone, i.input, i.selectControl),
				option: () => cf(s.wMax, s.flex, s.flexLeft, s.p5, i.selectOption),
				valueContainer: () => cf(s.p0),
				indicatorsContainer: () => cf(s.p0, s.m0, i.indicatorsContainer),
			}}
			{...props}
		/>
	) : (
		<label
			htmlFor={`${tag}`}
			className={cf(
				s.wMax,
				s.flex,
				s.spaceXBetween,
				s.spaceYCenter,
				i.label,
				mini ? i.mini : '',
				type === 'range' || type === 'date' ? i.ignoreHeight : ''
			)}
		>
			{type === 'date' && showDatePlaceholder && (
				<label
					className={cf(s.flex, s.m0, s.p0, i.input, i.placeholderSpan)}
					htmlFor={`${tag}`}
				>
					{placeholder}
				</label>
			)}
			{type === 'range' && (
				<label
					className={cf(
						s.wMax,
						s.flex,
						s.flexLeft,
						s.tLeft,
						s.m0,
						s.p0,
						s.mB5,
						i.input
					)}
					htmlFor={`${tag}`}
				>
					{placeholder}
				</label>
			)}
			<input
				id={`${tag}`}
				name={`${tag}`}
				type={`${isPassword ? (visible ? 'text' : type) : type}`}
				placeholder={placeholder}
				ref={inputRef}
				onFocus={
					type === 'date'
						? () => {
								setShowDatePlaceholder((x) => false)
						  }
						: null
				}
				onBlur={() => {
					if (type === 'date' && !state[tag])
						setShowDatePlaceholder((x) => true)
				}}
				className={cf(
					s.flex,
					s.m0,
					s.p0,
					i.input,
					isPassword ? i.password : '',
					type === 'date' && state[tag] ? i.filled : ''
				)}
				onChange={handler}
				value={state[tag] || ''}
				required={required}
				{...props}
			/>
			{type === 'range' && (
				<label
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						s.tCenter,
						s.m0,
						s.p0,
						s.mT5,
						i.input
					)}
					htmlFor={`${tag}`}
				>
					R {state[tag] ?? 0}
				</label>
			)}
			{isPassword ? (
				!visible ? (
					<FaRegEye
						onClick={() => setVisible((e) => true)}
						className={cf(s.flex, i.setVisible)}
					/>
				) : (
					<FaRegEyeSlash
						onClick={() => setVisible((e) => false)}
						className={cf(s.flex, i.setVisible)}
					/>
				)
			) : (
				<></>
			)}
		</label>
	)
}

export default InputField
