import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import close from '../../assets/svg/close.svg'
import hamburgerDark from '../../assets/svg/hamburgerDark.svg'
import {useMain} from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import n from '../../styles/NavBarDashboard.module.css'
import p from '../../styles/Properties.module.css'
import s from '../../styles/Shared.module.css'
import {cf} from '../../utils'
import {ProfileButton} from '../ProfileGroup'
import NavItem from './NavItem'
import TinyNavBar from './TinyNavBar'

const NavItems = ({children, showNavItems, setShowNavItems}) => {
  const {isTiny} = useMain()
  return (
    <ul
      className={cf(
        s.flex,
        s.flexCenter,
        s.p0,
        s.m0,
        showNavItems ? n.showNavItems : '',
        isTiny ? n.isSmall : '',
        p.navItems
      )}
    >
      <NavItem
        content={'About Us'}
        link={'https://cribvest.com/about-us/'}
      />
      <NavItem
        content={'Learning'}
        link={'https://cribvest.com/resources/'}
      />
      <NavItem
        content={'Properties'}
        link={'/properties'}
      />
    </ul>
  )
}

const NavBar = () => {
  const navigate = useNavigate()
  const [showNavItems, setShowNavItems] = useState(false)
  const {setShowProfileGroup, isTiny, showingTinyNavBar, showTinyNavBar} =
    useMain()

  useEffect(() => {
    if (isTiny) {
      setShowNavItems((io) => false)
    } else {
      setShowNavItems((io) => true)
    }
  }, [isTiny])

  return (
    <div className={cf(s.wMax, s.flex, s.flexCenter, s.m0, p.navBarCon)}>
      <div
        className={cf(
          s.wMax,
          s.flex,
          s.spaceXBetween,
          s.p_relative,
          s.spaceYCenter,
          p.navBar
        )}
      >
        <div className={cf(s.flex, s.flexCenter, p.firstSection)}>

          <Link
            className={cf(s.flex, s.flexCenter, s.pointer, p.logoContainer)}
           to="/dashboard"></Link>

          <NavItems
            showNavItems={showNavItems}
            setShowNavItems={setShowNavItems}
          />
        </div>

        {/* <div className={cf(s.flex, s.flexCenter, s.m0, s.p0, s.p_relative)}> */}
        {!isTiny ? <ProfileButton dark={true}/> : <></>}
        {/* </div> */}

        {isTiny ? (
          <div className={cf(s.flex, s.flexCenter, d.tinyProfileSection)}>
            <ProfileButton dark={true}/>
            {!showingTinyNavBar ? (
              <img
                src={hamburgerDark}
                alt={'Menu toggle'}
                className={cf(n.hamburger)}
                onClick={() => {
                  setShowProfileGroup((x) => false)
                  showTinyNavBar((x) => true)
                }}
              />
            ) : (
              <img
                src={close}
                alt={'Menu toggle'}
                className={cf(n.hamburger)}
                onClick={() => {
                  showTinyNavBar((x) => false)
                }}
              />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      {isTiny && showingTinyNavBar ? (
        <TinyNavBar {...{showTinyNavBar}} />
      ) : (
        <></>
      )}
    </div>
  )
}

export default NavBar
