// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__u5gg\\+ {
	padding: 10px 72px;
	color: #737373;
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 36px; /* 257.143% */
}

.Footer_copy__Ws--o {
}

.Footer_terms__dcoUk {
	color: #737373;
}

/* -----------------Media-Queries---------------------- */
@media only screen and (max-width: 900px) and (min-width: 761px) {
}

@media only screen and (max-width: 760px) {
}

@media only screen and (max-width: 760px) and (min-width: 481px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 480px) and (min-width: 361px) {
}

@media only screen and (max-width: 360px) {
}

@media only screen and (max-height: 380px) and (min-height: 301px) {
}

@media only screen and (max-height: 300px) {
}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,cAAc;CACd,oBAAoB;CACpB,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB,EAAE,aAAa;AACjC;;AAEA;AACA;;AAEA;CACC,cAAc;AACf;;AAEA,yDAAyD;AACzD;AACA;;AAEA;AACA;;AAEA;AACA;;AAEA;AACA;;AAEA;AACA;;AAEA;AACA;;AAEA;AACA;;AAEA;AACA","sourcesContent":[".footer {\n\tpadding: 10px 72px;\n\tcolor: #737373;\n\tfont-family: Manrope;\n\tfont-size: 14px;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 36px; /* 257.143% */\n}\n\n.copy {\n}\n\n.terms {\n\tcolor: #737373;\n}\n\n/* -----------------Media-Queries---------------------- */\n@media only screen and (max-width: 900px) and (min-width: 761px) {\n}\n\n@media only screen and (max-width: 760px) {\n}\n\n@media only screen and (max-width: 760px) and (min-width: 481px) {\n}\n\n@media only screen and (max-width: 480px) {\n}\n\n@media only screen and (max-width: 480px) and (min-width: 361px) {\n}\n\n@media only screen and (max-width: 360px) {\n}\n\n@media only screen and (max-height: 380px) and (min-height: 301px) {\n}\n\n@media only screen and (max-height: 300px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__u5gg+`,
	"copy": `Footer_copy__Ws--o`,
	"terms": `Footer_terms__dcoUk`
};
export default ___CSS_LOADER_EXPORT___;
