import React from 'react'
import { useNavigate } from 'react-router-dom'
import profileImage from '../../assets/svg/Default_pfp.svg'
import exit from '../../assets/svg/exit.svg'
import lang from '../../assets/svg/language.svg'
import { useAuth } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const ProfileGroup = ({ dark = false }) => {
	const navigate = useNavigate()

	const { userDashboard, logout } = useAuth()

	return (
		<div className={cf(s.flex, s.flexCenter, s['p_absolute'], d.profileGroup)}>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.profileRow
				)}
				onClick={() => navigate('/dashboard')}
			>
				<img
					src={
						userDashboard.user
							? userDashboard.user.image
								? userDashboard.user.image
								: profileImage
							: profileImage
					}
					alt={'user profile icon'}
					className={cf(s.flex, s.flexCenter, d.profileGroupProfileIcon)}
				/>
				<div
					className={cf(
						s.flexOne,
						s.flex,
						s.flexLeft,
						d.profileGroupDetails,
						dark ? d.profileGroupDark : ''
					)}
				>
					<span
						className={cf(
							s.wMax,
							s.flex,
							s.flexLeft,
							d.userName,
							d.profileGroupDark
						)}
					>
						{userDashboard.user ? userDashboard.user?.firstName : ''}&nbsp;
						{userDashboard.user ? userDashboard.user?.lastName : ''}
					</span>
					<span className={cf(s.wMax, s.flex, s.flexLeft, d.userSubName)}>
						{userDashboard.user ? userDashboard.user?.email : ''}
					</span>
				</div>
			</div>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.profileRow,
					s.pointer
				)}
				onClick={() => window.open('https://www.cribvest.com', '_self')}
			>
				<img
					src={lang}
					alt={'profileGroupIcon'}
					className={cf(s.flex, s.flexCenter, d.profileGroupIcon)}
				/>
				<span
					className={cf(
						s.flex,
						s.flexOne,
						s.flexLeft,
						d.profileGroupOption,
						dark ? d.profileGroupDark : ''
					)}
				>
					Go to main website
				</span>
			</div>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.profileRow
				)}
				onClick={logout}
			>
				<img
					src={exit}
					alt={'profileGroupIcon'}
					className={cf(s.flex, s.flexCenter, d.profileGroupIcon)}
				/>
				<span
					className={cf(
						s.flex,
						s.flexOne,
						s.flexLeft,
						d.profileGroupOption,
						dark ? d.profileGroupDark : ''
					)}
				>
					Log out
				</span>
			</div>
		</div>
	)
}

export default ProfileGroup
