import React from 'react';
import { Link } from 'react-router-dom';
import notFound from '../../assets/images/notFound.png';
import a from '../../styles/App.module.css';
import s from '../../styles/Shared.module.css';
import { cf } from '../../utils';

const NotFound = () => {
	return (
		<div
			className={cf(s.flex, s.flexCenter, s.wMax, s.parent, a.notFoundWrapper)}
		>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.p_relative,
					s.flexCenter,
					a.notFoundParent
				)}
			>
				<img
					src={notFound}
					alt={'page not found'}
					className={cf(s.flex, s.flexCenter, s.p_absolute, a.notFoundImage)}
				/>
				<h1
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						s.m0,
						s.p0,
						a.notFoundTitle
					)}
				>
					404
				</h1>
				<h2
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						s.m0,
						s.p0,
						a.notFoundSubTitle
					)}
				>
					Page Not Found
				</h2>
				<span className={cf(s.flex, s.flexCenter, a.notFoundMessage)}>
					we’re sorry. the page you requested could no be found Please go back
					to the home page
				</span>
				<div className={cf(s.wMax, s.flex, s.flexCenter)}>
					<Link
						to={'/'}
						type={'button'}
						className={cf(s.flex, s.flexCenter, a.notFoundButton)}
					>
						GO HOME
					</Link>
				</div>
			</div>
		</div>
	)
}

export default NotFound
