import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks'
import { PropertyInvest } from './PropertyInvest'
import { PropertyPage } from './PropertyPage'

const Property = () => {
	const { authorized } = useAuth()
	const navigate = useNavigate()
	const [showInvest, setShowInvest] = useState(false)
	// useEffect(() => {
	// 	if (!authorized) navigate('/')
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [authorized])

	return !showInvest ? (
		<PropertyPage {...{ setShowInvest }} />
	) : (
		<PropertyInvest {...{ setShowInvest }} />
	)
}

export default Property
