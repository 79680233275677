import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks'
import t from '../../styles/NavBar.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const NavItem = ({ content, link, showTinyNavBar, upcoming = false }) => {
	const location = useLocation()

	return (
		<Link
			to={link && !upcoming ? link : location.pathname}
			className={cf(
				s.wMax,
				s.flex,
				s.flexLeft,
				t.tinyNavItem,
				s.pointer,
				upcoming ? t.tinyGrayed : '',
				location.pathname === link ? t.tinyFocused : ''
			)}
			onClick={() => {
				showTinyNavBar((x) => false)
			}}
		>
			{content}
		</Link>
	)
}

const NavItems = ({ showTinyNavBar }) => {
	return (
		<div className={cf(s.wMax, s.flex, s.flexCenter, t.tinyNavItemGroup)}>
			<NavItem
				content={'About Us'}
				link={'https://cribvest.com/about-us/'}
				{...{ showTinyNavBar }}
			/>
			<NavItem
				content={'Learning'}
				link={'https://cribvest.com/resources/'}
				{...{ showTinyNavBar }}
			/>
			<NavItem
				content={'Properties'}
				link={'/properties'}
				{...{ showTinyNavBar }}
			/>
		</div>
	)
}

const TinyNavBar = ({ showTinyNavBar }) => {
	const { logout } = useAuth()
	return (
		<div className={cf(s.wMax, s.flex, t.tinyNavBar)}>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.hMax,
					s.flex_dColumn,
					s.spaceXBetween,
					s.spaceYCenter,
					t.innerMain
				)}
			>
				<NavItems {...{ showTinyNavBar }} />
				<div className={cf(s.wMax, s.flex, s.flexCenter, t.tinyLogoutCon)}>
					<Link
						className={cf(s.wMax, s.flex, s.flexLeft, t.tinyNavItem, s.pointer)}
						onClick={() => {
							showTinyNavBar((x) => false)
							logout()
						}}
					>
						Log out
					</Link>
				</div>
			</div>
		</div>
	)
}

export default TinyNavBar
