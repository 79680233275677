import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { InputField } from '../../../../components/InputField'
import { useAuth, useMain } from '../../../../hooks'
import a from '../../../../styles/Account.module.css'
import o from '../../../../styles/Onboarding.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf } from '../../../../utils'

const Step = () => {
	const [requestBody, setRequestBody] = useState({})
	const [optionsPreference] = useState([
		{ value: 'residential', label: 'Residential' },
		{ value: 'commercial', label: 'Commercial' },
	])
	const [optionsRisk] = useState([
		{ value: 'conservative', label: 'Conservative' },
		{ value: 'moderate', label: 'Moderate' },
		{ value: 'aggressive', label: 'Aggressive' },
	])
	const [isDisabled, setIsDisabled] = useState(true)
	const { setCurrentStep } = useMain()
	const { doUpdateKYC, uncompleted } = useAuth()
	const navigate = useNavigate()

	useLayoutEffect(() => {
		setCurrentStep((x) => 5)
		if (uncompleted[5]) {
			navigate('/account/kyc/completion')
		}
		return () => {
			setCurrentStep((x) => 5)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uncompleted])

	const preferenceHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			preference: x,
		}))

	const riskHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			risk: x,
		}))

	const handleSubmitHandler = async (e) => {
		e.preventDefault()
		let i = 0
		const keys = Object.keys(requestBody)
		const length = keys.length,
			reqBody = {}
		for (i; i < length; i++) {
			const el = requestBody[keys[i]]
			if (typeof el === 'object' && el.hasOwnProperty('value')) {
				reqBody[keys[i]] = el.value
			} else {
				reqBody[keys[i]] = el
			}
			if (Array.isArray(el)) {
				reqBody[keys[i]] = []
				let o = 0
				const keys_ = Object.keys({ label: true, value: true })
				const length = keys_.length
				for (o; o < length; o++) {
					if (typeof el[o] === 'object' && el[o].hasOwnProperty('value')) {
						reqBody[keys[i]].push(el[o].value)
					}
				}
			}
		}
		const { risk, preference } = reqBody
		const body = {
			riskTolerance: risk,
			investmentTypes: preference,
		}
		await doUpdateKYC(body)
	}

	useEffect(() => {
		setIsDisabled((x) => !(requestBody['preference'] && requestBody['risk']))
	}, [requestBody])

	return (
		<div className={cf(s.flex, s.flexCenter, a.accountChild)}>
			<h1 className={cf(s.wMax, s.tLeft, s.m0, s.p0, a.theme)}>
				Investment Preference
			</h1>
			<span className={cf(s.wMax, s.flex, s.flexLeft, s.tLeft, o.underTheme)}>
				Provide the following information to continue.
			</span>

			<form
				className={cf(s.wMax, s.flex, s.flexCenter, a.form)}
				onSubmit={handleSubmitHandler}
			>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.flex_dColumn,
						s.flexCenter,
						a.inputFieldCon
					)}
				>
					<InputField
						tag={'preference'}
						state={requestBody}
						handler={preferenceHandler}
						type={'select'}
						selectOptions={optionsPreference}
						placeholder={'Preferred investment type'}
						isMulti={true}
					/>
					<InputField
						tag={'risk'}
						state={requestBody}
						handler={riskHandler}
						type={'select'}
						selectOptions={optionsRisk}
						placeholder={'Risk tolerance'}
					/>
				</div>

				<button
					type={'submit'}
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						a.submitBtn,
						!isDisabled ? s.shadow : '',
						isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
					)}
					disabled={isDisabled}
				>
					{isDisabled ? 'Fill the form' : 'Continue'}
				</button>

				<div
					className={cf(s.wMax, s.flex, s.flexLeft, a.rememberToForget, s.p5)}
				>
					<span className={cf(s.wMax, s.dInlineBlock, s.tLeft, a.notice)}>
						The information provided would be saved securely as per our&nbsp;
						<Link
							to={'https://cribvest.com/terms-and-conditions/'}
							className={cf(s.link)}
						>
							Terms of Service
						</Link>
						&nbsp;&&nbsp;
						<Link
							to={'https://cribvest.com/privacy-policy/'}
							className={cf(s.link)}
						>
							Privacy Policy
						</Link>
					</span>
				</div>
			</form>

			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='482'
				height='2'
				viewBox='0 0 482 2'
				fill='none'
				className={cf(s.wMax, a.strike)}
			>
				<path
					d='M0 1H482'
					stroke='#B2B2B2'
					strokeWidth='0.5'
				/>
			</svg>

			<span className={cf(s.wMax, s.flex, s.flexCenter, s.tCenter, a.instead)}>
				Want to fill this later?&nbsp;
				<Link
					to={'/dashboard'}
					className={cf(s.flex, a.alt)}
				>
					Skip this step
				</Link>
			</span>
		</div>
	)
}

export default Step
