import axios from 'axios'

const serverURI =
	process.env.REACT_APP_ADDRESS_API_BASE_URI

const axiosCall = axios.create({
	baseURL: `${serverURI}`,
	headers: {
		Accept: 'application/json',
	},
	// withCredentials: true,
})

let auth_token = null

const retrieve_auth = async () => {
	try {
		const res = await axiosCall.get('getaccesstoken', {
			headers: {
				'api-token':
					process.env.REACT_APP_ADDRESS_API_TOKEN,
				'user-email':
					process.env.REACT_APP_ADDRESS_API_USER_EMAIL,
			},
		})
		auth_token = res.data.auth_token
		return auth_token
	} catch (err) {
		return false
	}
}

export const getList = async (path, tag) => {
	if (!auth_token) {
		const x = await retrieve_auth()
		if (!x) return false
	}

	try {
		const res = await axiosCall.get(path, {
			headers: {
				Authorization: `Bearer ${auth_token}`,
			},
		})
		const data = []
		res.data.forEach((x, i) => {
			data.push({ value: x?.[tag], label: x?.[tag] })
		})
		return data
	} catch (err) {
		return []
	}
}
