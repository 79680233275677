import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { InputField } from '../../../components/InputField'
import { SingleSignOn } from '../../../components/SingleSignOn'
import { useAuth, useMain } from '../../../hooks'
import a from '../../../styles/Account.module.css'
import s from '../../../styles/Shared.module.css'
import { cf } from '../../../utils'

const Login = () => {
	const [requestBody, setRequestBody] = useState({})
	const [isDisabled, setIsDisabled] = useState(true)
	const { setBigText, setSmallText, startWaiting, stopWaiting, alertThis} = useMain()
	const { setCalledReset, login, retrieveInfo } = useAuth()
	const location = useLocation()

	const handler = async (e) => {
		const name = e.target.name
		let value = e.target.value

		const validatorObj = {
			email: true,
		}

		switch (name) {
			case 'password':
				// TODO Update functionality
				setRequestBody((e) => ({
					...e,
					[name]: value,
				}))
				break
			case 'remember':
				setRequestBody((x) => ({
					...x,
					[name]: e.target.checked,
				}))
				break
			default:
				if (validatorObj[name])
					setRequestBody((e) => ({
						...e,
						[name]: value,
					}))
				break
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		startWaiting()
		// await login(requestBody, Boolean(requestBody.remember))
		const res = await login(requestBody, true)
		if(!res.success) alertThis({
			message: res.message,
			forConfirmation: false,
			canClear: false,
		})
		stopWaiting()
	}

	useEffect(() => {
		setBigText((x) => 'Invest and track earnings')
		setSmallText(
			(x) =>
				`By investing and diligently tracking your earnings, you can work towards building a more secure financial future.`
		)

		const doSomething = async () => {
			const token = location.search.slice(
				location.search.lastIndexOf('?token=') + '?token='.length
			)

			if (token) {
				startWaiting()
				await retrieveInfo({success: true, data: {access_token: token, refresh_token: token}})
				stopWaiting()
			}
		}

		doSomething()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setIsDisabled((x) => !(requestBody['email'] && requestBody['password']))
	}, [requestBody])

	return (
		<div className={cf(s.flex, s.flexCenter, a.accountChild)}>
			<h1 className={cf(s.wMax, s.tLeft, s.m0, s.p0, a.theme)}>
				Sign in to Cribvest
			</h1>

			<div className={cf(s.wMax, s.flex, s.flexCenter, a.ssoParent)}>
				{/* <SingleSignOn type={'microsoft'} /> */}
				<SingleSignOn
					type={'google'}
					fill={true}
				/>
				{/* <SingleSignOn type={'apple'} /> */}
			</div>

			<div className={cf(s.wMax, s.flex, s.flexCenter, s.p_relative, a.orCon)}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='482'
					height='2'
					viewBox='0 0 482 2'
					fill='none'
					className={cf(s.wMax, s['p_absolute'], a.orStrike)}
				>
					<path
						d='M0 1L482 1.00004'
						stroke='#B2B2B2'
						strokeWidth='0.5'
					/>
				</svg>
				<span className={cf(s.flex, s.flexCenter, a.orTxtCon)}>
					<span className={cf(s.flex, a.orTxt)}>or</span>
				</span>
			</div>

			<form
				className={cf(s.wMax, s.flex, s.flexCenter, a.form)}
				onSubmit={handleSubmit}
			>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.flex_dColumn,
						s.flexCenter,
						a.inputFieldCon
					)}
				>
					<InputField
						tag={'email'}
						state={requestBody}
						handler={handler}
						type={'email'}
						placeholder={'Email Address'}
					/>
					<InputField
						tag={'password'}
						state={requestBody}
						handler={handler}
						type={'password'}
						placeholder={'Password'}
					/>
				</div>

				<button
					type={'submit'}
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						a.submitBtn,
						!isDisabled ? s.shadow : '',
						isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
					)}
					disabled={isDisabled}
				>
					{isDisabled ? 'Fill the form' : 'Login'}
				</button>

				<div
					className={cf(
						s.wMax,
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter,
						a.rememberToForget
					)}
				>
					<label
						className={cf(s.flex, s.flexLeft, s.g10, s.pointer)}
						htmlFor={'remember'}
					>
						<div
							className={cf(
								s.flex,
								s.flexCenter,
								a.rememberCon,
								requestBody.remember ? a.doRemember : ''
							)}
						>
							{requestBody.remember ? (
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='18'
									height='18'
									viewBox='0 0 18 18'
									fill='none'
									className={cf(a.remember)}
								>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M3.49372 8.41075C3.15201 8.08532 2.59799 8.08532 2.25628 8.41075C1.91457 8.73619 1.91457 9.26383 2.25628 9.58926L6.63128 13.7559C6.98577 14.0935 7.56502 14.079 7.90038 13.7241L15.7754 5.39081C16.0987 5.04872 16.0695 4.52181 15.7103 4.21393C15.3511 3.90605 14.7979 3.93378 14.4746 4.27587L7.21658 11.9563L3.49372 8.41075Z'
										fill='white'
									/>
								</svg>
							) : (
								<></>
							)}
						</div>
						<span className={cf(s.dInlineBlock)}>Remember login</span>
						<input
							type={'checkbox'}
							id={'remember'}
							name={'remember'}
							className={cf(s.flex, a.rememberInput, s.p_absolute)}
							onChange={handler}
						/>
					</label>
					<Link
						to={'/account/reset'}
						className={cf(s.flex, a.forgot)}
						onClick={() => setCalledReset((x) => true)}
					>
						Forgot password?
					</Link>
				</div>
			</form>

			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='482'
				height='2'
				viewBox='0 0 482 2'
				fill='none'
				className={cf(s.wMax, a.strike)}
			>
				<path
					d='M0 1H482'
					stroke='#B2B2B2'
					strokeWidth='0.5'
				/>
			</svg>

			<span className={cf(s.wMax, s.flex, s.flexCenter, s.tCenter, a.instead)}>
				Don't have an account?&nbsp;
				<Link
					to={'/account/signup'}
					className={cf(s.flex, a.alt)}
				>
					Sign Up
				</Link>
			</span>
		</div>
	)
}

export default Login
