import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
	isPossiblePhoneNumber,
	parsePhoneNumber,
} from 'react-phone-number-input'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { Link } from 'react-router-dom'
import { InputField } from '../../../components/InputField'
import { SingleSignOn } from '../../../components/SingleSignOn'
import { countries } from '../../../constants'
import { useAuth, useMain } from '../../../hooks'
import a from '../../../styles/Account.module.css'
import i from '../../../styles/InputField.module.css'
import s from '../../../styles/Shared.module.css'
import { cf } from '../../../utils'

const Signup = () => {
	const [requestBody, setRequestBody] = useState({})
	const [phone, setPhone] = useState(undefined)
	const [isDisabled, setIsDisabled] = useState(true)
	const { setBigText, setSmallText, startWaiting, stopWaiting, alertThis } = useMain()
	const { signup, setResendToEmail } = useAuth()

	const { control, handleSubmit } = useForm()

	const handler = async (e) => {
		const name = e.target.name
		let value = e.target.value

		const validatorObj = {
			email: true,
			firstName: true,
			lastName: true,
		}

		switch (name) {
			case 'password':
				// TODO Update functionality
				setRequestBody((e) => ({
					...e,
					[name]: value,
				}))
				break
			case 'remember':
				setRequestBody((x) => ({
					...x,
					[name]: e.target.checked,
				}))
				break
			default:
				if (validatorObj[name])
					setRequestBody((e) => ({
						...e,
						[name]: value,
					}))
				break
		}
	}

	const handleSubmitHandler = async (e) => {
		e.preventDefault()
		setResendToEmail((x) => requestBody['email'])
		startWaiting()
		const res = await signup(requestBody)
		if (!res.success)
			alertThis({
				message: res.message,
				forConfirmation: false,
				canClear: false,
			})
		stopWaiting()
	}

	useEffect(() => {
		setBigText((x) => 'Get up to 26% on your investment')
		setSmallText(
			(x) =>
				`By investing and diligently tracking your earnings, you can work towards building a more secure financial future.`
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setRequestBody((x) => ({
			...x,
			phone,
		}))
		if (phone) {
			const phoneNumber = parsePhoneNumber(phone)
			setRequestBody((x) => ({
				...x,
				country: phoneNumber ? countries[phoneNumber.country] : x.country,
			}))
		}
	}, [phone])

	useEffect(() => {
		setIsDisabled(
			(x) =>
				!(
					requestBody['phone'] &&
					isPossiblePhoneNumber(requestBody['phone']) &&
					requestBody['lastName'] &&
					requestBody['firstName'] &&
					requestBody['country'] &&
					requestBody['email'] &&
					requestBody['password']
				)
		)
	}, [requestBody])

	return (
		<div className={cf(s.flex, s.flexCenter, a.accountChild)}>
			<h1 className={cf(s.wMax, s.tLeft, s.m0, s.p0, a.theme)}>
				Sign up to Cribvest
			</h1>

			<div className={cf(s.wMax, s.flex, s.flexCenter, a.ssoParent)}>
				{/* <SingleSignOn
					login={false}
					type={'microsoft'}
				/> */}
				<SingleSignOn
					login={false}
					type={'google'}
					fill={true}
				/>
				{/* <SingleSignOn
					login={false}
					type={'apple'}
				/> */}
			</div>

			<div className={cf(s.wMax, s.flex, s.flexCenter, s.p_relative, a.orCon)}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='482'
					height='2'
					viewBox='0 0 482 2'
					fill='none'
					className={cf(s.wMax, s['p_absolute'], a.orStrike)}
				>
					<path
						d='M0 1L482 1.00004'
						stroke='#B2B2B2'
						strokeWidth='0.5'
					/>
				</svg>
				<span className={cf(s.flex, s.flexCenter, a.orTxtCon)}>
					<span className={cf(s.flex, a.orTxt)}>or</span>
				</span>
			</div>

			<form
				className={cf(s.wMax, s.flex, s.flexCenter, a.form)}
				onSubmit={(e) => handleSubmit(handleSubmitHandler(e))}
			>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.flex_dColumn,
						s.flexCenter,
						a.inputFieldCon
					)}
				>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.spaceXBetween,
							s.spaceYCenter,
							a.miniCon
						)}
					>
						<InputField
							tag={'firstName'}
							state={requestBody}
							handler={handler}
							type={'text'}
							placeholder={'First Name'}
							mini={true}
						/>
						<InputField
							tag={'lastName'}
							state={requestBody}
							handler={handler}
							type={'text'}
							placeholder={'Last Name'}
							mini={true}
						/>
					</div>
					<PhoneInputWithCountry
						placeholder={'Enter phone number'}
						value={phone}
						name={'phone'}
						onChange={setPhone}
						className={cf(s.wMax, i.label)}
						control={control}
						rules={{ required: true, validate: isPossiblePhoneNumber }}
					/>
					<InputField
						tag={'email'}
						state={requestBody}
						handler={handler}
						type={'email'}
						placeholder={'Email Address'}
					/>
					<InputField
						tag={'password'}
						state={requestBody}
						handler={handler}
						type={'password'}
						placeholder={'password'}
					/>
				</div>

				<button
					type={'submit'}
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						a.submitBtn,
						!isDisabled ? s.shadow : '',
						isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
					)}
					disabled={isDisabled}
				>
					{isDisabled
						? requestBody['phone'] &&
						  !isPossiblePhoneNumber(requestBody['phone'])
							? 'Phone number invalid'
							: 'Fill the form'
						: 'Continue'}
				</button>

				<div
					className={cf(s.wMax, s.flex, s.flexLeft, a.rememberToForget, s.p5)}
				>
					<span className={cf(s.wMax, s.dInlineBlock, s.tLeft, a.notice)}>
						The information provided would be saved securely as per our&nbsp;
						<Link
							to={'/'}
							className={cf(s.link)}
						>
							Terms of Service
						</Link>
						&nbsp;&&nbsp;
						<Link
							to={'/'}
							className={cf(s.link)}
						>
							Privacy Policy
						</Link>
					</span>
				</div>
			</form>

			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='482'
				height='2'
				viewBox='0 0 482 2'
				fill='none'
				className={cf(s.wMax, a.strike)}
			>
				<path
					d='M0 1H482'
					stroke='#B2B2B2'
					strokeWidth='0.5'
				/>
			</svg>

			<span className={cf(s.wMax, s.flex, s.flexCenter, s.tCenter, a.instead)}>
				Already have an account?&nbsp;
				<Link
					to={'/account/login'}
					className={cf(s.flex, a.alt)}
				>
					Sign In
				</Link>
			</span>
		</div>
	)
}

export default Signup
