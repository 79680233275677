import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Redirect } from '../../../components/Redirect'
import { Steps } from '../../../components/Steps'
import { useAuth } from '../../../hooks'
import o from '../../../styles/Onboarding.module.css'
import s from '../../../styles/Shared.module.css'
import { cf } from '../../../utils'
import { UnknownRouteHandler } from '../../404'
import { Final } from './Final'
import { Step1 } from './Step_1'
import { Step2 } from './Step_2'
import { Step3 } from './Step_3'
import { Step4 } from './Step_4'
import { Step5 } from './Step_5'

const Onboarding = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const { authorized } = useAuth()

	// useEffect(() => {
	// 	if (!authorized) navigate('/account/login')
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [authorized])

	return (
		<div
			className={cf(
				s.wMax,
				s.flex,
				s.flexLeft,
				s['flex_dColumn'],
				o.onboarding
			)}
		>
			<Steps />
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.flexCenter,
					s.flexOne,
					s.hMax,
					o.onboardingWrapper
				)}
			>
				<Routes>
					<Route
						path='/'
						element={<Redirect to={location.pathname + '/identity'} />}
					/>
					<Route
						path='personal'
						element={<Step1 />}
					/>
					<Route
						path='identity'
						element={<Step2 />}
					/>
					<Route
						path='employment'
						element={<Step3 />}
					/>
					<Route
						path='finance'
						element={<Step4 />}
					/>
					<Route
						path='investment'
						element={<Step5 />}
					/>
					<Route
						path='completion'
						element={<Final />}
					/>
					<Route
						path='/*'
						element={<UnknownRouteHandler />}
					/>
				</Routes>
			</div>
		</div>
	)
}

export default Onboarding
