import React from 'react'
import fund from '../../assets/svg/fund.svg'
import home from '../../assets/svg/home.svg'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf, currencyFormat } from '../../utils'

const Transaction = ({ txn }) => {
	return (
		<div
			className={cf(
				s.wMax,
				s.flex,
				s.spaceXBetween,
				s.spaceYCenter,
				s.g10,
				d.txn
			)}
		>
			<div className={cf(s.flex, s.flexCenter, d.txnIconCon)}>
				{txn.type !== 'credit' ? (
					<img
						src={home}
						alt={'home'}
						className={cf(s.flex, s.flexCenter, d.txnIcon)}
					/>
				) : (
					<img
						src={fund}
						alt={'fund'}
						className={cf(s.flex, s.flexCenter, d.txnIcon)}
					/>
				)}
			</div>
			<div className={cf(s.flex, s.flexCenter, d.txnDetailCon)}>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter,
						d.txnDetailTop
					)}
				>
					<span className={cf(s.dInlineBlock, d.txnTitle)}>
						{txn.description}
					</span>
					<span
						className={cf(
							s.dInlineBlock,
							d.txnAmount,
							txn.type === 'credit' ? d.txnCredit : d.txnDebit
						)}
					>
						R {currencyFormat(txn.amount)}
					</span>
				</div>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter,
						d.txnDetailBottom
					)}
				>
					{/* <span className={cf(s.dInlineBlock, d.txnMedium)}>
						Flutterwave • Bank transfer
					</span> */}
					<span
						className={cf(
							s.dInlineBlock,
							d.txnStatus,
							txn.status ?? true ? d.txnSuccess : d.txnFail
						)}
					>
						{txn.status ?? true ? 'Successful' : 'Failed'}
					</span>
				</div>
			</div>
		</div>
	)
}

export default Transaction
