import React from 'react'
import home from '../../assets/svg/home.svg'
import pin from '../../assets/svg/pin.svg'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf, currencyFormat } from '../../utils'

const Investment = ({ inv }) => {
	return (
		<div
			className={cf(
				s.wMax,
				s.flex,
				s.spaceXBetween,
				s.spaceYCenter,
				s.g10,
				d.txn
			)}
		>
			<div className={cf(s.flex, s.flexCenter, d.txnIconCon)}>
				<img
					src={home}
					alt={'home'}
					className={cf(s.flex, s.flexCenter, d.txnIcon)}
				/>
			</div>
			<div
				className={cf(s.flex, s.spaceXBetween, s.spaceYCenter, d.txnDetailCon)}
			>
				<div
					className={cf(
						// s.wMax,
						s.flexOne,
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter
					)}
				>
					<span className={cf(s.flexOne, s.dInlineBlock, d.invTitle)}>
						{inv.name}
						<span className={cf(d.preSale)}>
							{inv.isPresell ? ' • Presale' : ''}
						</span>
					</span>
					<div className={cf(s.flex, s.flexLeft, s.g10, d.invLocCon)}>
						<img
							src={pin}
							alt={'pin'}
							className={cf(s.flex, s.flexCenter, d.invIcon)}
						/>
						<span className={cf(s.dInlineBlock, d.invLocation)}>
							{inv.location}
						</span>
					</div>
				</div>
				<div className={cf(s.flex, s.spaceXBetween, s.spaceYCenter)}>
					<span className={cf(s.dInlineBlock, s.tRight, d.invShares)}>
						{inv.units} Shares
					</span>
					<span className={cf(s.dInlineBlock, d.txnAmount, d.invAmount)}>
						R {currencyFormat(inv.totalInvestment)}
					</span>
				</div>
			</div>
		</div>
	)
}

export default Investment
