import React, { useState } from 'react'
import chat from '../../../../assets/svg/chat.svg'
import supportIcon from '../../../../assets/svg/support.svg'
import ticketWhite from '../../../../assets/svg/ticketWhite.svg'
import { Faq } from '../../../../components/Faq'
import { Ticket } from '../../../../components/Ticket'
import { useMain } from '../../../../hooks'
import d from '../../../../styles/Dashboard.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf } from '../../../../utils'

const ActionButton = ({ tag, handler, icon, isPurple = true }) => {
	return (
		<button
			className={cf(
				s.flex,
				s.flexCenter,
				d.supportActionButton,
				isPurple ? d.supportActionButtonPurple : d.supportActionButtonBlack
			)}
			onClick={handler}
		>
			<img
				src={icon}
				alt={'support action button icon'}
				className={cf(s.flex, s.flexCenter, d.supportActionButtonIcon)}
			/>
			<span className={cf(s.flex, s.flexCenter, d.supportActionButtonTxt)}>
				{tag}
			</span>
		</button>
	)
}

const SupportView = ({ view, setView }) => {
	const { isTiny, setShowTicketingView } = useMain()
	const [state, setState] = useState(0)

	return (
		<div className={cf(s.wMax, s.flex, s.flexTop, d.innerMain)}>
			<div className={cf(s.flex, s.flex_dColumn, s.flexLeft, d.supportParent)}>
				<div className={cf(s.wMax, s.flex, s.flexCenter, d.supportIconCon)}>
					<img
						src={supportIcon}
						alt={'support icon'}
						className={cf(s.flex, s.flexCenter, d.supportIcon)}
					/>
				</div>
				<div className={cf(s.wMax, s.flex, s.flexCenter, d.supportIntroCon)}>
					<h1
						className={cf(
							s.wMax,
							s.flex,
							s.flexCenter,
							s.p0,
							s.m0,
							d.supportIntroTitle
						)}
					>
						Are you facing any problem?
					</h1>
					<span className={cf(s.flex, s.flexCenter, d.supportIntroTxt)}>
						If you need instant support then use the live chat option to reach
						us quickly. Our support will reply as soon as you send us a message.
					</span>
				</div>
				<div
					className={cf(s.wMax, s.flex, s.flexCenter, d.supportActionButtonCon)}
				>
					<ActionButton
						{...{
							tag: 'Contact us',
							icon: chat,
							handler: () => {
								window.location.href = "mailto:hello@cribvest.com";
								setView((x) => 'chat');
								setShowTicketingView((x) => false);
							},
						}}
					/>
				</div>
			</div>
		</div>
	)
}

export default SupportView
