import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks'
import p from '../../styles/Properties.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import { UnknownRouteHandler } from '../404'
import { PropertiesPage } from './PropertiesPage'
import { PropertyView } from './PropertyView'

const Properties = () => {
	// const { authorized } = useAuth()
	// const navigate = useNavigate()
	// useEffect(() => {
	// 	if (!authorized) navigate('/account/login')
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [authorized])

	return (
		<div className={cf(s.wMax, s.flex, s.flex_dColumn, s.flexLeft, p.parent)}>
			<Routes>
				<Route
					path='/'
					element={<PropertiesPage />}
				/>
				<Route
					path='id/*'
					element={<PropertyView />}
				/>
				<Route
					path='/*'
					element={<UnknownRouteHandler />}
				/>
			</Routes>
		</div>
	)
}

export default Properties
