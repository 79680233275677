import React, { useRef, useState } from 'react'
import Select from 'react-select'
import i from '../../styles/InputField.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const InputField = ({
	tag,
	state,
	handler,
	type,
	placeholder,
	mini = false,
	required = true,
	selectOptions = null,
	isMulti = false,
	label = '',
	useTextarea = false,
	rows = 4,
	...props
}) => {
	const [isPassword] = useState(type === 'password')
	const [visible] = useState(false)
	const inputRef = useRef()

	return type === 'select' ? (
		<label
			htmlFor={`${tag}`}
			className={cf(s.wMax, s.flex, s.flexLeft)}
		>
			<label
				className={cf(
					s.wMax,
					s.flex,
					s.flexLeft,
					s.tLeft,
					s.m0,
					s.p0,
					i.innerLabel
				)}
				htmlFor={`${tag}`}
			>
				{label}{' '}
				{required ? (
					<span className={cf(s.flex, s.flexTop, i.requiredMark)}>*</span>
				) : (
					<></>
				)}
			</label>
			<Select
				placeholder={placeholder}
				name={tag}
				onChange={handler}
				options={selectOptions}
				value={state[tag]}
				isMulti={isMulti}
				isSearchable={false}
				classNamePreFix={'react-select'}
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					i.label_,
					s.p0,
					i.select,
					isMulti ? i.ignoreHeight : ''
				)}
				classNames={{
					control: () =>
						cf(s.wMax, s.flex, s.m0, s.bNone, i.input, i.selectControl_),
					option: () => cf(s.wMax, s.flex, s.flexLeft, s.p5, i.selectOption),
					valueContainer: () => cf(s.p0, i.valueContainer),
					selectControl: () => cf(i.selectControl_),
					indicatorsContainer: () => cf(s.p0, s.m0, i.indicatorsContainer),
				}}
				{...props}
			/>
		</label>
	) : (
		<label
			htmlFor={`${tag}`}
			className={cf(
				s.wMax,
				s.flex,
				s.flexLeft,
				s.tLeft,
				i.label_,
				mini ? i.mini : '',
				type === 'range' || type === 'date' ? i.ignoreHeight : ''
			)}
		>
			<label
				className={cf(
					s.wMax,
					s.flex,
					s.flexLeft,
					s.tLeft,
					s.m0,
					s.p0,
					i.innerLabel
				)}
				htmlFor={`${tag}`}
			>
				{label}{' '}
				{required ? (
					<span className={cf(s.flex, s.flexTop, i.requiredMark)}>*</span>
				) : (
					<></>
				)}
			</label>
			{!useTextarea ? (
				<input
					id={`${tag}`}
					name={`${tag}`}
					type={`${isPassword ? (visible ? 'text' : type) : type}`}
					placeholder={placeholder}
					ref={inputRef}
					className={cf(
						s.wMax,
						s.flex,
						i.input_,
						isPassword ? i.password : '',
						type === 'date' && state[tag] ? i.filled : ''
					)}
					onChange={handler}
					value={state[tag] || ''}
					required={required}
					{...props}
				/>
			) : (
				<textarea
					id={`${tag}`}
					name={`${tag}`}
					type={`${isPassword ? (visible ? 'text' : type) : type}`}
					placeholder={placeholder}
					ref={inputRef}
					rows={rows}
					className={cf(
						s.wMax,
						s.flex,
						i.input_,
						isPassword ? i.password : '',
						type === 'date' && state[tag] ? i.filled : ''
					)}
					onChange={handler}
					value={state[tag] || ''}
					required={required}
					{...props}
				/>
			)}
			{/* {type === 'range' && (
				<label
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						s.tCenter,
						s.m0,
						s.p0,
						s.mT5,
						d.input
					)}
					htmlFor={`${tag}`}
				>
					R {state[tag] ?? 0}
				</label>
			)} */}
		</label>
	)
}

export default InputField
