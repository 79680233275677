import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks'
import t from '../../styles/NavBar.module.css'
import n from '../../styles/NavBarDashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const NavItem = ({ content, link, showTinyNavBar, upcoming = false }) => {
	const location = useLocation()

	return (
		<Link
			to={link && !upcoming ? link : location.pathname}
			className={cf(
				s.wMax,
				s.flex,
				s.flexLeft,
				t.tinyNavItem,
				n.tinyNavItemPurple,
				s.pointer,
				upcoming ? t.tinyGrayed : '',
				location.pathname === link ? t.tinyFocused : ''
			)}
			onClick={() => {
				showTinyNavBar((x) => false)
			}}
		>
			{content}
		</Link>
	)
}

const NavItems = ({ showTinyNavBar }) => {
	return (
		<div className={cf(s.wMax, s.flex, s.flexCenter, t.tinyNavItemGroup)}>
			<NavItem
				content={'Dashboard'}
				link={'/dashboard'}
				{...{ showTinyNavBar }}
			/>
			<NavItem
				content={'Investments'}
				link={'/dashboard/investments'}
				{...{ showTinyNavBar }}
			/>
			<NavItem
				content={'Transactions'}
				link={'/dashboard/transactions'}
				{...{ showTinyNavBar }}
			/>
			<NavItem
				content={'Settings'}
				link={'/dashboard/settings'}
				{...{ showTinyNavBar }}
			/>
			<NavItem
				content={'Support'}
				link={'/dashboard/support'}
				{...{ showTinyNavBar }}
			/>
		</div>
	)
}

const TinyNavBar = ({ showTinyNavBar }) => {
	const { logout } = useAuth()
	return (
		<div className={cf(s.wMax, s.flex, t.tinyNavBar, n.tinyNavBarPurple)}>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.hMax,
					s.flex_dColumn,
					s.spaceXBetween,
					s.spaceYCenter,
					n.innerMain
				)}
			>
				<NavItems {...{ showTinyNavBar }} />
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						t.tinyLogoutCon,
						n.tinyLogoutConPurple
					)}
				>
					<Link
						className={cf(
							s.wMax,
							s.flex,
							s.flexLeft,
							t.tinyNavItem,
							n.tinyNavItemPurple,
							s.pointer
						)}
						onClick={() => {
							showTinyNavBar((x) => false)
							logout()
						}}
					>
						Log out
					</Link>
				</div>
			</div>
		</div>
	)
}

export default TinyNavBar
