import { useMain } from '../../hooks'
import s from '../../styles/Shared.module.css'
import d from '../../styles/Dashboard.module.css'
import { cf } from '../../utils'
import { IBrowse } from '../IBrowse'
import ctaBG from '../../assets/images/ctaBG.png'
import { useRef, useEffect } from 'react'

const CtA = () => {
	const { isSmall } = useMain()
	const bgRef = useRef()

	useEffect(() => {
		bgRef.current.style.background = `url(${ctaBG})`
		bgRef.current.style.backgroundPosition = 'center'
		bgRef.current.style.backgroundRepeat = 'no-repeat'
		bgRef.current.style.backgroundSize = 'cover'
	}, [])

	return (
		<div
			className={cf(
				s.flex,
				s.flex_dColumn,
				s.flexLeft,
				d.kycParent,
				// isSmall ? s.wMax : '',
				isSmall ? s.mT20 : '',
				d.cta
			)}
			ref={bgRef}
		>
			<span className={cf(s.wMax, s.flex, s.flexLeft, d.ctaTitle)}>
				Begin your
				<br />
				investment journey
			</span>
			<span className={cf(s.wMax, s.flex, s.flexLeft, d.ctaText)}>
				By investing and diligently tracking your earnings, you can work towards
				building a more secure financial future.
			</span>
			<IBrowse
				noMargin={true}
				angel={true}
			/>
		</div>
	)
}

export default CtA
