import React, { useEffect, useRef } from 'react'
import { useMain } from '../../hooks'
import o from '../../styles/Onboarding.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const OnboardingProgress = () => {
	const { currentStep } = useMain()
	const progressRef = useRef()

	useEffect(() => {
		const _ = Number(currentStep + 1)
		const __ = Number(7)
		const ___ = (_ / __) * 100
		const ____ = Math.round(100 - ___)

		if (progressRef.current) progressRef.current.style.right = `${____}%`
	}, [currentStep])

	return (
		<div className={cf(s.wMax, s.flex, s.flexCenter, o.onboardingProgress)}>
			<div className={cf(s.wMax, s.hMax, s.flex, s.flexCenter)}>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s['flex_dColumn'],
						s.flexLeft,
						s.g10,
						o.progressCon2
					)}
				>
					<div
						className={cf(
							s.wMax,
							s.hMax,
							s.flex,
							s.flexCenter,
							o.progressBarCon
						)}
					>
						<div
							className={cf(s.wMax, o.progressBarComplete2, o.l0)}
							ref={progressRef}
						></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OnboardingProgress
