import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ni from '../../styles/NavItem.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const NavItem = ({ content, link, upcoming = false, walCon = false }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const causeRedirect = (link = '/') => {
		navigate(link)
	}

	return (
		<li
			className={cf(
				s.flex,
				s.flexCenter,
				ni.navItem,
				upcoming ? ni.grayed : '',
				location.pathname === link ? ni.focused : ''
			)}
			onClick={() => !upcoming && !walCon && causeRedirect(link)}
		>
			{content}
		</li>
	)
}

export default NavItem
