import React, { useEffect, useState } from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import kycClose from '../../assets/svg/kycClose.svg'
import nb from '../../styles/NavBar.module.css'
import p from '../../styles/Properties.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const NavBar = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const [twitch, setTwitch] = useState(false)

	const toggleDisplay = () => {}

	const closeKYC = async () => {
		navigate('/dashboard')
	}

	useEffect(() => {
		setTwitch((io) => location.pathname.indexOf('/account') !== 0)
	}, [location])

	useEffect(() => {
		const twitcher = () => {
			// desktopView
			if (
				location.pathname.indexOf('/account' === 0) &&
				location.pathname.indexOf('/account/kyc') !== 0
			) {
				if (window.innerWidth >= 901) {
					setTwitch((io) => false)
					// mobileLandscapeView
				} else if (
					window.innerWidth >= 821 &&
					window.innerWidth <= 900 &&
					window.innerHeight <= 420
				) {
					setTwitch((io) => false)
					// bigTabletView
				} else if (
					window.innerWidth >= 821 &&
					window.innerWidth <= 900 &&
					window.innerHeight >= 421
				) {
					setTwitch((io) => false)
					// tabletView
				} else if (window.innerWidth >= 481 && window.innerWidth <= 820) {
					setTwitch((io) => true)
					// mobileView
				} else if (window.innerWidth <= 480) {
					setTwitch((io) => true)
				} else setTwitch((x) => false)
			} else if (location.pathname.indexOf('/account') !== 0) {
				setTwitch((io) => true)
			} else {
				setTwitch((io) => false)
			}
		}
		twitcher()
		window.addEventListener('scroll', twitcher)
		window.addEventListener('resize', twitcher)
		return () => {
			window.removeEventListener('scroll', twitcher)
			window.removeEventListener('resize', twitcher)
		}
	}, [twitch, location])

	return (
		<div
			className={cf(
				s.wMax,
				s.flex,
				s.spaceXBetween,
				s.spaceYCenter,
				nb.navBar,
				nb.navBar_,
				s.hWAccel,
				p.innerMain,
				nb?.['navFixed'],
				location.pathname.indexOf('/account') === 0 &&
					location.pathname.indexOf('/account/kyc') !== 0
					? nb.navBarPartial
					: '',
				location.pathname.indexOf('/account') !== 0 ||
					location.pathname.indexOf('/account/kyc') === 0
					? s.bgWhite
					: '',
				twitch ? nb.twitch : ''
			)}
		>
			<Link
				className={cf(s.flex, s.flexCenter, s.pointer, nb.logoContainer)}
			 to="/"></Link>
			<div
				className={cf(
					s.flex,
					s.flexCenter,
					nb.navItemsContainer,
					location.pathname.indexOf('/account') === 0 &&
						location.pathname.indexOf('/account/kyc') !== 0
						? nb.partial
						: location.pathname.indexOf('/account/kyc') === 0 ||
						  location.pathname.indexOf('/account') !== 0
						? s.bgWhite
						: ''
				)}
			>
				{location.pathname.indexOf('/account/kyc') !== 0 ? (
					<div
						className={cf(s.flex, s.flexCenter, s.pointer, nb.toggleSwitch)}
						onClick={toggleDisplay}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='20'
							viewBox='0 0 20 20'
							fill='none'
							className={nb.doubleStrokes}
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M17 15C17.5523 15 18 15.4477 18 16C18 16.5523 17.5523 17 17 17H3C2.44772 17 2 16.5523 2 16C2 15.4477 2.44772 15 3 15H17ZM17 3C17.5523 3 18 3.44772 18 4C18 4.55228 17.5523 5 17 5H3C2.44772 5 2 4.55228 2 4C2 3.44772 2.44772 3 3 3H17Z'
								fill='black'
							/>
						</svg>
					</div>
				) : (
					<>
						{location.pathname.indexOf('/account/kyc/completion') !== 0 ? (
							<div
								className={cf(s.flex, s.flexCenter, s.pointer)}
								onClick={closeKYC}
							>
								<img
									src={kycClose}
									alt={'kyc close'}
									className={cf(s.flex, s.flexCenter, nb.doubleStrokes)}
								/>
							</div>
						) : (
							<></>
						)}
					</>
				)}
			</div>
		</div>
	)
}

export default NavBar
