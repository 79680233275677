import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import closeLight from '../../assets/svg/closeLight.svg'
import hamburger from '../../assets/svg/hamburger.svg'
import {useMain} from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import n from '../../styles/NavBarDashboard.module.css'
import s from '../../styles/Shared.module.css'
import {cf} from '../../utils'
import {ProfileButton} from '../ProfileGroup'
import NavItem from './NavItem'
import TinyNavBar from './TinyNavBar'

const NavItems = ({showNavItems}) => {
  const {isTiny} = useMain()
  return (
    <ul
      className={cf(
        s.flex,
        s.spaceXBetween,
        s.p0,
        s.m0,
        showNavItems ? n.showNavItems : '',
        isTiny ? n.isSmall : ''
      )}
    >
      <NavItem
        content={'Dashboard'}
        link={'/dashboard'}
      />
      <NavItem
        content={'Investments'}
        link={'/dashboard/investments'}
      />
      <NavItem
        content={'Transactions'}
        link={'/dashboard/transactions'}
      />
      <NavItem
        content={'Settings'}
        link={'/dashboard/settings'}
      />
      <NavItem
        content={'Support'}
        link={'/dashboard/support'}
      />
    </ul>
  )
}

const NavBar = () => {
  const navigate = useNavigate()
  const [showNavItems, setShowNavItems] = useState(false)
  const {setShowProfileGroup, isTiny, showingTinyNavBar, showTinyNavBar} =
    useMain()

  useEffect(() => {
    if (isTiny) {
      setShowNavItems((io) => false)
    } else {
      setShowNavItems((io) => true)
    }
  }, [isTiny])

  return (
    <div
      className={cf(
        s.wMax,
        s.flex,
        s.flexCenter,
        s.m0,
        s.p0,
        n.navBarCon,
        d.navBar,
        d.autoLR,
        showingTinyNavBar ? s.p_fixed : ''
        // showTicketingView && location.pathname === '/dashboard/support'
        // 	? d.fixNavBarToView
        // 	: ''
      )}
    >
      <div
        className={cf(
          s.wMax,
          s.flex,
          s.spaceXBetween,
          s.p_relative,
          s.spaceYCenter,
          n.navBar
        )}
      >
        <Link
          className={cf(s.flex, s.flexCenter, s.pointer, n.logoContainer)}
          to="/dashboard"></Link>
        <NavItems
          showNavItems={showNavItems}
          setShowNavItems={setShowNavItems}
        />

        {/* <div className={cf(s.flex, s.flexCenter, s.m0, s.p0, s.p_relative)}> */}
        {!isTiny ? <ProfileButton/> : <></>}
        {/* </div> */}

        {isTiny ? (
          <div className={cf(s.flex, s.flexCenter, d.tinyProfileSection)}>
            <ProfileButton/>
            {!showingTinyNavBar ? (
              <img
                src={hamburger}
                alt={'Menu toggle'}
                className={cf(n.hamburger)}
                onClick={() => {
                  setShowProfileGroup((x) => false)
                  showTinyNavBar((x) => true)
                }}
              />
            ) : (
              <img
                src={closeLight}
                alt={'Menu toggle'}
                className={cf(n.hamburger)}
                onClick={() => {
                  showTinyNavBar((x) => false)
                }}
              />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      {isTiny && showingTinyNavBar ? (
        <TinyNavBar {...{showTinyNavBar}} />
      ) : (
        <></>
      )}
    </div>
  )
}

export default NavBar
