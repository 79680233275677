import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Redirect = ({ to = '/' }) => {
	const navigate = useNavigate()
	useEffect(() => {
		navigate(to, {
			replace: true,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <div></div>
}

export default Redirect
