import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth, useMain } from '../../../../hooks'
import a from '../../../../styles/Account.module.css'
import o from '../../../../styles/Onboarding.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf } from '../../../../utils'

const Final = () => {
	const [requestBody, setRequestBody] = useState({})
	const [isDisabled, setIsDisabled] = useState(true)
	const { setCurrentStep } = useMain()
	const { doUpdateKYC } = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		setCurrentStep((x) => 6)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handler = async (e) => {
		const name = e.target.name

		const validatorObj = {
			accept: true,
		}

		switch (name) {
			default:
				if (validatorObj[name])
					setRequestBody((x) => ({
						...x,
						[name]: e.target.checked,
					}))
				break
		}
	}

	const handleSubmitHandler = async (e) => {
		e.preventDefault()
		const res = await doUpdateKYC()
		if (res) {
			navigate('/dashboard')
		}
	}

	useEffect(() => {
		setIsDisabled((x) => !requestBody['accept'])
	}, [requestBody])

	return (
		<div className={cf(s.flex, s.flexCenter, a.accountChild)}>
			<div className={cf(o.stepMarkCon, o.bigStepMarkCon)}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='31'
					height='31'
					viewBox='0 0 31 31'
					fill='none'
					className={cf(o.bigStepMark)}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.88074 14.1447C4.22173 13.3962 3.15327 13.3962 2.49426 14.1447C1.83525 14.8932 1.83525 16.1068 2.49426 16.8553L10.9318 26.4386C11.6154 27.2151 12.7325 27.1817 13.3793 26.3655L28.5668 7.19886C29.1903 6.41205 29.1341 5.20017 28.4414 4.49204C27.7486 3.78391 26.6816 3.84769 26.0582 4.6345L12.0605 22.2996L4.88074 14.1447Z'
						fill='white'
					/>
				</svg>
			</div>
			<h1 className={cf(s.wMax, s.tCenter, s.m0, s.p0, a.theme)}>
				Completed Onboarding
			</h1>
			<span
				className={cf(s.wMax, s.flex, s.flexCenter, s.tCenter, o.underTheme)}
			>
				Congratulations you are done with your account setup.
			</span>

			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='482'
				height='2'
				viewBox='0 0 482 2'
				fill='none'
				className={cf(s.wMax, a.strike)}
			>
				<path
					d='M0 1H482'
					stroke='#B2B2B2'
					strokeWidth='0.5'
				/>
			</svg>

			<form
				className={cf(s.wMax, s.flex, s.flexCenter, a.form)}
				onSubmit={handleSubmitHandler}
			>
				<label
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						// s.spaceXBetween,
						// s.spaceYCenter,
						s.g10,
						s.pointer,
						a.rememberLabel,
						o.rememberLabel
					)}
					htmlFor={'accept'}
				>
					<div
						className={cf(
							s.flex,
							s.flexCenter,
							a.rememberCon,
							requestBody.accept ? a.doRemember : ''
						)}
					>
						{requestBody.accept ? (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='18'
								height='18'
								viewBox='0 0 18 18'
								fill='none'
								className={cf(a.remember)}
							>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M3.49372 8.41075C3.15201 8.08532 2.59799 8.08532 2.25628 8.41075C1.91457 8.73619 1.91457 9.26383 2.25628 9.58926L6.63128 13.7559C6.98577 14.0935 7.56502 14.079 7.90038 13.7241L15.7754 5.39081C16.0987 5.04872 16.0695 4.52181 15.7103 4.21393C15.3511 3.90605 14.7979 3.93378 14.4746 4.27587L7.21658 11.9563L3.49372 8.41075Z'
									fill='white'
								/>
							</svg>
						) : (
							<></>
						)}
					</div>
					<span className={cf(s.flex, s.flexOne, s.flexLeft)}>
						I accept the Cribvest’s&nbsp;
						<Link
							to={'https://cribvest.com/terms-and-conditions/'}
							className={cf(s.flex, a.alt)}
						>
							Terms of Service
						</Link>
						&nbsp;and&nbsp;
						<Link
							to={'https://cribvest.com/privacy-policy/'}
							className={cf(s.flex, a.alt)}
						>
							Privacy Policy
						</Link>
					</span>
					<input
						type={'checkbox'}
						id={'accept'}
						name={'accept'}
						className={cf(s.flex, a.rememberInput, s['p_absolute'])}
						onChange={handler}
					/>
				</label>

				<button
					type={'submit'}
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						a.submitBtn,
						!isDisabled ? s.shadow : '',
						isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
					)}
					disabled={isDisabled}
				>
					{isDisabled ? 'Accept terms' : 'Continue'}
				</button>
			</form>
		</div>
	)
}

export default Final
