import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import d from '../../styles/Dashboard.module.css'
import o from '../../styles/Onboarding.module.css'
import s from '../../styles/Shared.module.css'
import { cf, currencyFormat } from '../../utils'
import { IViewDetails } from '../IViewDetails'

const IProperty = ({ i }) => {
	// const { currentStep } = useMain()
	const progressRef = useRef()
	const propertyRef = useRef()
	const location = useLocation()

	useEffect(() => {
		const _ = Number(i.sold)
		const __ = Number(i.price) * Number(i.volume)
		const ___ = (_ / __) * 100
		const ____ = Math.round(100 - ___)

		// timer.current = setTimeout(() => {
		if (progressRef.current) progressRef.current.style.right = `${____}%`
		// }, 2000)
		if (propertyRef.current) {
			propertyRef.current.style.background = `url(${i.image[0]})`
			propertyRef.current.style.backgroundPosition = 'center'
			propertyRef.current.style.backgroundRepeat = 'no-repeat'
			propertyRef.current.style.backgroundSize = 'cover'
		}
	}, [i.volume, i.price, i.sold, i.image])

	return (
		<div className={cf(s.flex, s.flexCenter, d.iProperty)}>
			<div
				className={cf(s.wMax, s.flex, s.flexStart, d.iPropertyImage)}
				ref={propertyRef}
			>
				<span className={cf(s.flex, s.flexCenter, d.iPropertyShares)}>
					{currencyFormat(Number(i.units), false)} Share
					{Number(i.units) > 1 ? 's' : ''}
				</span>
			</div>
			<div className={cf(s.wMax, d.iPropertyDetails)}>
				<div className={cf(s.wMax, s.flex, s.flex_dColumn, s.flexLeft)}>
					<span className={cf(s.wMax, s.flex, s.flexLeft, d.iPropertyName)}>
						{i.name}
					</span>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.spaceXBetween,
							s.spaceYCenter,
							d.iPropertyListing
						)}
					>
						<span className={cf(s.flex, s.flexLeft, d.iPropLeft)}>
							Investment Target:
						</span>
						<span className={cf(s.flex, s.flexRight, d.iPropRight)}>
							R {currencyFormat(Number(i.target))}
						</span>
					</div>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.flexCenter,
							o.onboardingProgress,
							d.iPropertyProgress
						)}
					>
						<div className={cf(s.wMax, s.hMax, s.flex, s.flexCenter)}>
							<div
								className={cf(
									s.wMax,
									s.flex,
									s['flex_dColumn'],
									s.flexLeft,
									s.g10,
									o.progressCon2
								)}
							>
								<div
									className={cf(
										s.wMax,
										s.hMax,
										s.flex,
										s.flexCenter,
										o.progressBarCon
									)}
								>
									<div
										className={cf(s.wMax, o.progressBarComplete2, o.l0)}
										ref={progressRef}
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<IViewDetails
				{...{
					to: i.propertyId
						? `/properties/id/${i.propertyId}`
						: location.pathname,
				}}
			/>
		</div>
	)
}

export default IProperty
