import React, { useEffect, useState } from 'react'
import { useMain } from '../../hooks'
import l from '../../styles/LoadingPreview.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const Cube = () => {
	return (
		<div className={cf(l.cube)}>
			<div className={cf(l.topFace)}></div>
			<div className={cf(l.leftFace)}></div>
			<div className={cf(l.frontFace)}></div>
		</div>
	)
}

const LoadingPreview = () => {
	const { sleep, showPreloader, setProcessing } = useMain()
	const [preloaderClass, setPreloaderClass] = useState(
		cf(l.container, l.invisible)
	)

	useEffect(() => {
		setPreloaderClass((io) => l.container)
	}, [])

	useEffect(() => {
		const close = async () => {
			const fadeOff = async () => {
				setPreloaderClass((io) => cf(l.container, l.invisible))
				await sleep(400)
				setPreloaderClass((io) => cf(l.terminated))
			}
			if (showPreloader === false) {
				await fadeOff()
				setProcessing((io) => false)
			}
		}
		close()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showPreloader, sleep])

	return (
		<div className={preloaderClass}>
			<div className={cf(s.flex, s.flexCenter, s.p_relative, l.loaderCon)}>
				<div className={cf(s.flex, s.flexLeft, s.flex_dColumn, l.cubes)}>
					<Cube />
					<Cube />
					<Cube />
					<Cube />
					<Cube />
					<Cube />
				</div>
				<div className={cf(l.cubesMask)}></div>
			</div>
		</div>
	)
}

export default LoadingPreview
