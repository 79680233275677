import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import dashboard from '../../assets/svg/dashboardNavIcon.svg'
import dashboardDark from '../../assets/svg/dashboardNavIconBlack.svg'
import investments from '../../assets/svg/investmentsNavIcon.svg'
import investmentsDark from '../../assets/svg/investmentsNavIconBlack.svg'
import transactions from '../../assets/svg/receipt.svg'
import transactionsDark from '../../assets/svg/receiptBlack.svg'
import settings from '../../assets/svg/settingsNavIcon.svg'
import settingsDark from '../../assets/svg/settingsNavIconBlack.svg'
import support from '../../assets/svg/supportNavIcon.svg'
import supportDark from '../../assets/svg/supportNavIconBlack.svg'
import { useMain } from '../../hooks'
import n from '../../styles/NavBarDashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const NavItem = ({ content, link, upcoming = false }) => {
	const { isSmall } = useMain()
	const location = useLocation()
	const iconsLight = {
		dashboard,
		investments,
		transactions,
		settings,
		support,
	}
	const iconsDark = {
		dashboardDark,
		investmentsDark,
		transactionsDark,
		settingsDark,
		supportDark,
	}
	const [icon, setIcon] = useState(iconsLight?.[`${content.toLowerCase()}`])

	useEffect(() => {
		if (location.pathname === link) {
			setIcon((x) => iconsDark?.[`${content.toLowerCase()}Dark`])
		} else {
			setIcon((x) => iconsLight?.[`${content.toLowerCase()}`])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [content, link, location])

	return (
		<Link
			to={link && !upcoming ? link : location.pathname}
			className={cf(
				s.flex,
				s.flexCenter,
				n.navItem,
				upcoming ? n.grayed : '',
				location.pathname === link ? n.focused : ''
			)}
		>
			<img
				src={icon}
				className={cf(
					s.dInlineBlock,
					n.icon,
					location.pathname === link ? n.iconFocused : ''
				)}
				alt={'Little ' + content + ' icon'}
			/>
			{!isSmall || location.pathname === link ? (
				<span className={cf(s.dInlineBlock, s.flex, s.flexCenter)}>
					{content}
				</span>
			) : (
				<></>
			)}
		</Link>
	)
}

export default NavItem
