import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import bath from '../../assets/svg/bath.svg'
import bed from '../../assets/svg/bed.svg'
import pin from '../../assets/svg/pin.svg'
import sqm from '../../assets/svg/sqm.svg'
import { useMain } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import o from '../../styles/Onboarding.module.css'
import p from '../../styles/Properties.module.css'
import s from '../../styles/Shared.module.css'
import { cf, currencyFormat } from '../../utils'

const Property = ({ property: x }) => {
	const { isSmall, getProperty } = useMain()
	const progressRef = useRef()
	const propertyRef = useRef()
	const navigate = useNavigate()

	const doSomething = async () => {
		const id = x.id
		await getProperty(id)
		navigate('/properties/id/' + x.id)
	}

	useEffect(() => {
		const _ = Number(x.price) * Number(x.sold)
		const __ = Number(x.price) * Number(x.volume)
		const ___ = (_ / __) * 100
		const ____ = Math.round(100 - ___)

		// timer.current = setTimeout(() => {
		if (progressRef.current) progressRef.current.style.right = `${____}%`
		// }, 2000)
		if (propertyRef.current) {
			propertyRef.current.style.background = `url(${x.image[0]})`
			propertyRef.current.style.backgroundPosition = 'center'
			propertyRef.current.style.backgroundRepeat = 'no-repeat'
			propertyRef.current.style.backgroundSize = 'cover'
		}
	}, [x.volume, x.price, x.sold, x.image])

	return (
		<div
			onClick={doSomething}
			className={cf(s.flex, s.flexCenter, p.pProperty, s.pointer)}
		>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.flexStart,
					d.iPropertyImage,
					p.pPropertyImage
				)}
				ref={propertyRef}
			>
				<span
					className={cf(
						s.flex,
						s.flexCenter,
						d.iPropertyShares,
						p.pPropertyShares
					)}
				>
					{currencyFormat(Number(x.volume) - Number(x.sold), false)} Share
					{Number(x.volume) - Number(x.sold) > 1 ? 's' : ''}
				</span>
			</div>
			<div className={cf(s.wMax, d.iPropertyDetails, p.pPropertyDetails)}>
				<div className={cf(s.wMax, s.flex, s.flex_dColumn, s.flexLeft)}>
					<span
						className={cf(
							s.wMax,
							s.flex,
							s.flexLeft,
							d.iPropertyName,
							p.pPropertyName
						)}
					>
						{x.name}
					</span>
					<div className={cf(s.wMax, s.flex, s.flexLeft, p.propertyLocCon)}>
						<img
							src={pin}
							alt={'property location icon'}
							className={cf(s.flex, s.flexCenter, p.propertyPin)}
						/>
						<span className={cf(s.flex, s.flexRight, p.propertyLocation)}>
							{x.location}
						</span>
					</div>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.spaceXBetween,
							s.spaceYCenter,
							d.iPropertyListing,
							p.propertyListing
						)}
					>
						<span className={cf(s.flex, s.flexLeft, d.iPropLeft, p.pPropLeft)}>
							Investment Target:
						</span>
						<span
							className={cf(s.flex, s.flexRight, d.iPropRight, p.pPropRight)}
						>
							R {currencyFormat(x.marketCap)}
						</span>
					</div>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.flexCenter,
							o.onboardingProgress,
							d.iPropertyProgress
						)}
					>
						<div className={cf(s.wMax, s.hMax, s.flex, s.flexCenter)}>
							<div
								className={cf(
									s.wMax,
									s.flex,
									s['flex_dColumn'],
									s.flexLeft,
									s.g10,
									o.progressCon2
								)}
							>
								<div
									className={cf(
										s.wMax,
										s.hMax,
										s.flex,
										s.flexCenter,
										o.progressBarCon
									)}
								>
									<div
										className={cf(s.wMax, o.progressBarComplete2, o.l0)}
										ref={progressRef}
									></div>
								</div>
							</div>
						</div>
					</div>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.spaceXBetween,
							s.spaceYCenter,
							p.xxGrossYield
						)}
					>
						<span className={cf(s.flex, p.xxGrossYieldTag)}>Gross yield:</span>
						<span className={cf(s.flex, p.xxGrossYieldValue)}>
							{x.grossYield} %
						</span>
					</div>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.spaceXBetween,
							s.spaceYCenter,
							p.propertyDetails
						)}
					>
						<div className={cf(s.flex, s.flexLeft, s.g10, p.propertyDetail)}>
							<img
								src={bath}
								alt={'property detail icon'}
								className={cf(s.flex, s.flexCenter, p.propDetailIcon)}
							/>
							<span className={cf(s.flex, s.flexRight, p.propDetailText)}>
								{x.bathroom} Bath{!isSmall ? 'room' : ''}
								{x.bedroom > 1 ? 's' : ''}
							</span>
						</div>
						<div className={cf(s.flex, s.flexLeft, s.g10, p.propertyDetail)}>
							<img
								src={bed}
								alt={'property detail icon'}
								className={cf(s.flex, s.flexCenter, p.propDetailIcon)}
							/>
							<span className={cf(s.flex, s.flexRight, p.propDetailText)}>
								{x.bedroom} Bed{!isSmall ? 'room' : ''}
								{x.bedroom > 1 ? 's' : ''}
							</span>
						</div>
						<div className={cf(s.flex, s.flexLeft, s.g10, p.propertyDetail)}>
							<img
								src={sqm}
								alt={'property detail icon'}
								className={cf(s.flex, s.flexCenter, p.propDetailIcon)}
							/>
							<span className={cf(s.flex, s.flexRight, p.propDetailText)}>
								{x.sqm}sqm
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Property
