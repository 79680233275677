import React from 'react'
import { Link } from 'react-router-dom'
import plus from '../../assets/svg/plus.svg'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const IBrowse = ({ noMargin = false, angel = false }) => {
	return (
		<Link
			to={'/properties'}
			className={cf(
				s.flex,
				s.flexCenter,
				d.sBrowse,
				noMargin ? d.noMargin : '',
				angel ? d.angel : ''
			)}
		>
			<span className={cf(s.flex, s.flexCenter)}>Browse Properties</span>
			<img
				src={plus}
				alt={'plus icon'}
				className={cf(s.flex, d.sPlus)}
			/>
		</Link>
	)
}

export default IBrowse
