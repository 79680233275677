import React, { useEffect, useState } from 'react'
import { Alert } from '../components/Alert'
import { useAuth } from '../hooks'

const waitingPro = {}
export const MainContext = React.createContext()

const MainContextProvider = ({ children }) => {
	const {
		makeRequest,
		userDetails,
		userDashboard,
		getUserDashboard,
		authorized,
	} = useAuth()

	const [promiseOfConfirmation, setPromiseOfConfirmation] = useState({})

	const [[showPreloader, setShowPreloader], [processing, setProcessing]] = [
		useState(false),
		useState(false),
	]

	const [[alertInfo, setAlertInfo], [showAlert, setShowAlert]] = [
		useState({
			message: 'Confirm Action',
			accept: 'Yes',
			decline: 'No',
			forConfirmation: true,
			prompt: false,
		}),
		useState(false),
	]

	const [requestedURI, setRequestedURI] = useState('')
	const [page, setPage] = useState(1)
	const [bigText, setBigText] = useState('')
	const [smallText, setSmallText] = useState('')
	const [currentStep, setCurrentStep] = useState(1)
	const [isSmall, setIsSmall] = useState(false)
	const [isTiny, setIsTiny] = useState(false)
	const [txnSuccessful, setTxnSuccessful] = useState(false)
	const [showFundWallet, setShowFundWallet] = useState(false)
	const [properties, setProperties] = useState([])
	const [filteredProperties, setFilteredProperties] = useState([])
	const [property, setProperty] = useState({})
	const [showProfileGroup, setShowProfileGroup] = useState(false)
	const [showingTinyNavBar, showTinyNavBar] = useState(false)
	const [propertySize, setPropertySize] = useState(6)
	const [purchaseSuccess, setPurchaseSuccess] = useState(false)
	const [showTicketingView, setShowTicketingView] = useState(false)
	const [showCashOut, setShowCashOut] = useState(false)
	const [wTxnSuccessful, setWTxnSuccessful] = useState(false)
	const [investments, setInvestments] = useState([])
	const [transactions, setTransactions] = useState([])
	const [showImagesView, setShowImagesView] = useState(false)
	const [originPath, setOriginPath] = useState('')
	const [propertyFilterParams, setPropertyFilterParams] = useState({
		city: [],
		bedroom: [],
		type: [],
	})
	const [propertyFilters, setPropertyFilters] = useState({
		city: 'all',
		bedroom: 'all',
		type: 'all',
	})

	const sleep = (m) => new Promise((resolve) => setTimeout(resolve, m))

	const alertThis = async ({
		message = 'Confirm Action',
		accept = 'Yes',
		decline = 'No',
		forConfirmation = true,
		prompt = false,
		persist = false,
		neutral = false,
		callback = null,
		canClear = false,
		inputType = 'text',
	} = {}) => {
		await sleep(200)
		promiseOfConfirmation?.resolve && promiseOfConfirmation.resolve()
		const result = await new Promise((resolve, reject) => {
			setPromiseOfConfirmation({ resolve, reject })
			setAlertInfo(() => ({
				message,
				accept,
				decline,
				forConfirmation,
				prompt,
				persist,
				neutral,
				callback,
				canClear,
				inputType,
			}))
			setShowAlert(() => true)
		}).catch(() => setShowAlert(() => false))
		if (result === undefined) setShowAlert(() => false)
		return result
	}

	const startWaiting = async () => {
		const shouldDisplay = (display) => {
			setShowPreloader(display)
			if (display) setProcessing((io) => display)
		}

		try {
			await new Promise((resolve, reject) => {
				waitingPro['resolve'] = resolve
				waitingPro['reject'] = reject
				shouldDisplay(true)
			})
			shouldDisplay(false)
		} catch (error) {
			shouldDisplay(false)
		}
	}

	const stopWaiting = (mode = true) => {
		if (mode && waitingPro.resolve) waitingPro.resolve()
		else if (waitingPro.reject) waitingPro.reject()
	}

	const requestTopUp = async (amount) => {
		startWaiting()
		const res = await makeRequest({
			path: `payment/link?amount=${amount}`,
		})
		stopWaiting()
		if (res.success && res.data.url) {
			window.open(`${res.data.url}`, '_blank')
			setShowFundWallet((x) => false)
			await checkCredit(amount)
		} else {
			// console.log('Failed to get payment url')
		}
	}

	const checkCredit = async (amount) => {
		// if (!dashboardObj.wallet) return
		const currentBal = userDashboard.wallet.balance
		let timer = undefined
		let checker = setInterval(async () => {
			const currentDashboard = await getUserDashboard()
			const bal = currentDashboard.wallet.balance
			if (Number(bal) === Number(currentBal) + Number(amount)) {
				clearInterval(checker)
				checker = undefined
				clearTimeout(timer)
				timer = undefined
				setTxnSuccessful((x) => true)
				return true
			}
		}, 1000)
		timer = setTimeout(() => {
			clearInterval(checker)
			checker = undefined
			clearTimeout(timer)
			timer = undefined
		}, 300000)
	}

	const checkDebit = async (amount) => {
		// if (!dashboardObj.wallet) return
		const currentBal = userDashboard.wallet.balance
		let timer = undefined
		let checker = setInterval(async () => {
			const currentDashboard = await getUserDashboard()
			const bal = currentDashboard.wallet.balance
			if (Number(bal) === Number(currentBal) - Number(amount)) {
				clearInterval(checker)
				checker = undefined
				clearTimeout(timer)
				timer = undefined
				setTxnSuccessful((x) => true)
				return true
			}
		}, 1000)
		timer = setTimeout(() => {
			clearInterval(checker)
			checker = undefined
			clearTimeout(timer)
			timer = undefined
		}, 300000)
	}

	const getProperties = async (page = 1, size = 6) => {
		const res = await makeRequest({
			path: `property?page=${page}&size=${size}`,
		})

		setProperties((x) =>
			res.success && res.data.properties.length ? res.data.properties : []
		)
	}

	const getMoreProperties = async (page = 1) => {
		const res = await makeRequest({
			path: `property?page=${page}&size=${propertySize + 4}`,
		})

		if (res.success) setPropertySize((x) => res.data.properties.length)

		if (res.success && res.data.properties.length) {
			const {
				data: { properties },
			} = res
			setProperties((x) => properties)
		} else {
			setProperties((x) => [])
		}

		return res.success && res.data.properties.length ? res.data.properties : []
	}

	const getProperty = async (id = 1) => {
		const res = await makeRequest({
			path: `property/${id}`,
		})

		// setProperty((x) => ({
		//     "id": id,
		//     "name": "Lush Villas",
		//     "location": "Victoria Island",
		//     "marketCap": 100000,
		//     "volume": 100,
		//     "price": 10000,
		//     "increasePercent": 0,
		//     "sold": 12,
		//     "image": [
		//         "https://ssl.cdn-redfin.com/system_files/media/869770_JPG/genDesktopMapHomeCardUrl/item_1.jpg",
		//         "https://img.jamesedition.com/listing_images/2023/10/18/15/59/15/9a966e55-78bc-45ce-b1b5-ae8528dcb0ff/je/1100xxs.jpg",
		//         "https://img-v2.gtsstatic.net/reno/imagereader.aspx?imageurl=https%3A%2F%2Fsir.azureedge.net%2F1194i215%2Fp7nd5d8hcr6545jszayvpmgz47i215&option=N&h=472&permitphotoenlargement=false",
		//         "https://www.clarionledger.com/gcdn/presto/2022/06/24/NSHT/f194dda0-a1d0-4b2b-95a0-83ba4fb880b5-Market_07032022_05.jpg"
		//     ],
		//     "description": [
		//         "Nestled in the serene locality of Ikorodu Boulivade, Ayenuwa Lakes offers a picturesque view and a tranquil living experience.",
		//         "This condominium complex comprises four units, each elegantly designed to provide a modern and comfortable lifestyle.",
		//         "Featuring spacious bedrooms, luxurious bathrooms, and a stunning view, this property is an ideal choice for those seeking a sophisticated urban living space.",
		//         "Enjoy the convenience of being located in JO'Borg, SA, a thriving city with access to numerous amenities and attractions.",
		//         "The property holds complete legal documentation, including a Certificate of Occupancy (CofO), ensuring a secure investment for potential buyers."
		//     ],
		//     "type": "condo",
		//     "units": 88,
		//     "approvalStatus": "DRAFT",
		//     "country": "SA",
		//     "state": "JO'Borg",
		//     "city": "Vade",
		//     "bedroom": 10,
		//     "bathroom": 5,
		//     "sqm": 50,
		//     "createdAt": "2023-11-27T17:04:34.614Z",
		//     "updatedAt": "2023-12-11T12:20:30.479Z",
		//     "isPresell": true,
		//     "roi": 0,
		//     "netYield": 0,
		//     "grossYield": 0
		// }))
		// return true

		setProperty((x) => (res.success ? res.data : {}))
		return res.success || res.message === 'Unauthorized'
	}

	const purchaseProperty = async (id, units) => {
		startWaiting()
		const res = await makeRequest({
			path: `order`,
			method: 'post',
			body: {
				propertyId: id,
				units,
			},
		})
		stopWaiting()
		getProperty(property.id)
		pullData()
		getUserDashboard()
		if (res.success && res.data.status === 'fulfilled') {
			setPurchaseSuccess((x) => true)
		}
	}

	const getInvestments = async () => {
		const res = await makeRequest({
			path: 'investment',
		})

		if (res.success && res.data.length) {
			const { data } = res
			setInvestments((x) => data)
		} else {
			setInvestments((x) => [])
		}

		return res.success && res.data.length ? res.data : []
	}

	const getTransactions = async (bound = '') => {
		const res = await makeRequest({
			path: `payment/history${bound ? '?date=' + bound : ''}`,
		})

		if (res.success && res.data.length) {
			const { data } = res
			setTransactions((x) => data)
		} else {
			setTransactions((x) => [])
		}

		return res.success && res.data.length ? res.data : []
	}

	const getPropertyFilterParams = async () => {
		const res = await makeRequest({
			path: 'property/filter',
		})

		if (res.success) {
			const params = res.data
			const { city = [], bedroom = [], type = [] } = params
			setPropertyFilterParams((x) => ({ city, bedroom, type }))
		} else {
			setPropertyFilterParams((x) => ({ city: [], bedroom: [], type: [] }))
		}
	}

	const pullData = async () => {
		getProperties(1, propertySize)
		getInvestments()
		getTransactions('month')
		getPropertyFilterParams()
	}

	const MainContextValue = {
		sleep,
		promiseOfConfirmation,
		setPromiseOfConfirmation,
		alertInfo,
		setAlertInfo,
		showAlert,
		setShowAlert,
		showPreloader,
		setShowPreloader,
		processing,
		setProcessing,
		startWaiting,
		stopWaiting,
		requestedURI,
		setRequestedURI,
		page,
		setPage,
		bigText,
		setBigText,
		smallText,
		setSmallText,
		currentStep,
		setCurrentStep,
		isSmall,
		setIsSmall,
		isTiny,
		setIsTiny,
		txnSuccessful,
		setTxnSuccessful,
		requestTopUp,
		checkCredit,
		checkDebit,
		showFundWallet,
		setShowFundWallet,
		properties,
		setProperties,
		getProperties,
		property,
		setProperty,
		getProperty,
		showProfileGroup,
		setShowProfileGroup,
		showingTinyNavBar,
		showTinyNavBar,
		propertySize,
		setPropertySize,
		getMoreProperties,
		purchaseSuccess,
		setPurchaseSuccess,
		purchaseProperty,
		showTicketingView,
		setShowTicketingView,
		showCashOut,
		setShowCashOut,
		wTxnSuccessful,
		setWTxnSuccessful,
		investments,
		setInvestments,
		getInvestments,
		transactions,
		setTransactions,
		getTransactions,
		showImagesView,
		setShowImagesView,
		originPath,
		setOriginPath,
		propertyFilterParams,
		setPropertyFilterParams,
		getPropertyFilterParams,
		filteredProperties,
		setFilteredProperties,
		propertyFilters,
		setPropertyFilters,
		alertThis,
	}

	useEffect(() => {
		if (
			window.innerWidth <= 820 ||
			(window.innerWidth <= 950 &&
				window.innerWidth >= 600 &&
				window.innerHeight <= 440)
		) {
			setIsSmall((io) => true)
		} else {
			setIsSmall((io) => false)
		}
		if (window.innerWidth <= 481) {
			setIsTiny((io) => true)
		} else {
			setIsTiny((io) => false)
		}
		let timer = undefined
		timer = setInterval(() => {
			if (
				window.innerWidth <= 820 ||
				(window.innerWidth <= 950 &&
					window.innerWidth >= 600 &&
					window.innerHeight <= 440)
			) {
				setIsSmall((io) => true)
			} else {
				setIsSmall((io) => false)
			}
			if (window.innerWidth <= 481) {
				setIsTiny((io) => true)
			} else {
				setIsTiny((io) => false)
			}
		}, 1000)
		const viewReset = () => {
			if (
				window.innerWidth <= 820 ||
				(window.innerWidth <= 950 &&
					window.innerWidth >= 600 &&
					window.innerHeight <= 440)
			) {
				setIsSmall((io) => true)
			} else {
				setIsSmall((io) => false)
			}
			if (window.innerWidth <= 481) {
				setIsTiny((io) => true)
			} else {
				setIsTiny((io) => false)
			}
		}
		window.addEventListener('resize', viewReset)
		// startWaiting()
		return () => {
			window.removeEventListener('resize', viewReset)
			clearInterval(timer)
			timer = undefined
		}
	}, [])

	useEffect(() => {
		pullData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDetails, authorized])

	useEffect(() => {
		if (authorized) {
			const filtered = properties.filter((x, i) => {
				const { city = 'all', bedroom = 'all', type = 'all' } = propertyFilters
				const filterCity = city !== 'all'
				const filterBedroom = bedroom !== 'all'
				const filterType = type !== 'all'

				return (
					(filterCity ? x.city === city : true) &&
					(filterBedroom ? x.bedroom >= bedroom : true) &&
					(filterType ? x.type === type : true)
				)
			})
			setFilteredProperties((x) => filtered)
		}
	}, [authorized, properties, propertyFilters])

	return (
		<MainContext.Provider value={MainContextValue}>
			{children}
			<Alert />
		</MainContext.Provider>
	)
}

export default MainContextProvider
