import React from 'react'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const FundContinue = ({ isDisabled }) => {
	return (
		<div className={cf(s.wMax, s.flex, s.flexRight)}>
			<button
				type={'submit'}
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.sBrowse,
					d.iViewDetailsBtn,
					d.fundContinue,
					d.b18
				)}
				disabled={isDisabled}
			>
				<span className={cf(s.flex, s.flexCenter)}>Continue</span>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					className={cf(s.flex, d.sPlus, d.iPlus)}
				>
					<g id='arrow-down'>
						<path
							id='Path 3'
							d='M10 18L16 12L10 6'
							stroke='#22272F'
							strokeLinecap='round'
						/>
					</g>
				</svg>
			</button>
		</div>
	)
}

export default FundContinue
