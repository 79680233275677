import React, { useEffect, useState } from 'react'
import fund from '../../assets/svg/fund.svg'
import home from '../../assets/svg/home.svg'
import tFailed from '../../assets/svg/tFailed.svg'
import tPending from '../../assets/svg/tPending.svg'
import tSuccess from '../../assets/svg/tSuccess.svg'
import { useMain } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf, currencyFormat } from '../../utils'

const Transaction = ({ txn }) => {
	const { isTiny } = useMain()
	const [date, setDate] = useState()

	useEffect(() => {
		if (txn.createdAt) {
			const dateTime = new Date(txn.createdAt)
			setDate((x) => dateTime.toString().slice(0, 15))
		}
	}, [txn])

	return (
		<div
			className={cf(
				s.wMax,
				s.flex,
				s.spaceXBetween,
				s.spaceYCenter,
				s.g10,
				d.txn
			)}
		>
			<div className={cf(s.flex, s.flexCenter, d.txnIconCon)}>
				{txn.type === 'credit' ? (
					<img
						src={home}
						alt={'home'}
						className={cf(s.flex, s.flexCenter, d.txnIcon)}
					/>
				) : (
					<img
						src={fund}
						alt={'fund'}
						className={cf(s.flex, s.flexCenter, d.txnIcon)}
					/>
				)}
			</div>
			<div
				className={cf(s.flex, s.spaceXBetween, s.spaceYCenter, d.txnDetailCon)}
			>
				<div
					className={cf(
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter,
						d.tTypeMedium,
						d.tDetails
					)}
				>
					<span className={cf(s.flex, s.flexLeft, d.tTxnType)}>
						{txn.description}
					</span>
					{isTiny ? (
						<span className={cf(s.flex, s.flexCenter, d.tDate)}>{date}</span>
					) : (
						<></>
					)}
				</div>
				{!isTiny ? (
					<div
						className={cf(
							s.flex,
							s.spaceXBetween,
							s.spaceYCenter,
							d.tTxnRecords
						)}
					>
						<div
							className={cf(
								s.flex,
								s.flexLeft,
								d.tStatus,
								txn.status === 'success'
									? d.tSSuccess
									: txn.status === 'pending'
									? d.tSPending
									: d.tSFailed
							)}
						>
							{txn.status === 'success' ? (
								<img
									src={tSuccess}
									alt={'fund'}
									className={cf(s.flex, s.flexCenter, d.txnIcon, d.tStatusIcon)}
								/>
							) : txn.status === 'pending' ? (
								<img
									src={tPending}
									alt={'fund'}
									className={cf(s.flex, s.flexCenter, d.txnIcon, d.tStatusIcon)}
								/>
							) : (
								<img
									src={tFailed}
									alt={'fund'}
									className={cf(s.flex, s.flexCenter, d.txnIcon, d.tStatusIcon)}
								/>
							)}
							<span className={cf(s.flex, s.flexCenter)}>{`${txn.status
								.slice(0, 1)
								.toUpperCase()}${txn.status.slice(1)}`}</span>
						</div>
						<span className={cf(s.flex, s.flexCenter, d.tDate)}>{date}</span>
					</div>
				) : (
					<></>
				)}
				<div
					className={cf(
						s.flex,
						s.flex_dColumn,
						s.spaceXBetween,
						s.spaceYEnd,
						d.tDetails,
						d.tAmountCon
					)}
				>
					<span
						className={cf(s.flex, s.flexCenter, d.tAmount)}
					>{`R ${currencyFormat(txn.amount)}`}</span>
					{isTiny ? (
						<div
							className={cf(
								s.flex,
								d.tStatus,
								isTiny ? s.flexRight : s.flexLeft,
								txn.status === 'success'
									? d.tSSuccess
									: txn.status === 'pending'
									? d.tSPending
									: d.tSFailed
							)}
						>
							<span className={cf(s.flex, s.flexCenter)}>{`${txn.status
								.slice(0, 1)
								.toUpperCase()}${txn.status.slice(1)}`}</span>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	)
}

export default Transaction
