import React from 'react'
import arrow from '../../assets/svg/arrow.svg'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const Faq = ({ title, message, state, setState, id }) => {
	return (
		<div
			className={cf(s.wMax, s.flex, s.flexCenter, d.fapCon, s.pointer)}
			onClick={() => setState((x) => (id === state ? 0 : id))}
		>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.faqTitleCon
				)}
			>
				<span className={cf(s.flex, s.flexOne, d.faqTitle)}>{title}</span>
				<img
					src={arrow}
					alt={'faq icon'}
					className={cf(
						s.flex,
						s.flexCenter,
						d.faqIcon,
						id === state ? d.flip90 : ''
					)}
				/>
			</div>
			{id === state ? (
				<p className={cf(s.flex, s.flexLeft, s.m0, s.p0, d.faqMessage)}>
					{message}
				</p>
			) : (
				<></>
			)}
		</div>
	)
}

export default Faq
