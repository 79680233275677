import React, { useEffect, useState } from 'react'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf, currencyFormat } from '../../utils'
import { useMain, useAuth } from '../../hooks'
import flag from '../../assets/images/flag.png'
import withdraw from '../../assets/svg/withdraw.svg'
import fund from '../../assets/svg/fund.svg'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'

const Currency = () => {
	return (
		<div className={cf(s.flex, s.spaceXBetween, d.currencyCon)}>
			<img
				src={flag}
				alt={'flag'}
				className={cf(s.flex, s.flexCenter, d.flag)}
			/>
			<span className={cf(s.flex, s.flexCenter, d.flagID)}>ZAR</span>
			{/* <svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='25'
				viewBox='0 0 24 25'
				fill='none'
				className={cf(s.pointer, d.cChevronDown)}
			>
				<path
					d='M6 10.5L12 16.5L18 10.5'
					stroke='white'
					strokeLinecap='round'
				/>
			</svg> */}
		</div>
	)
}

const Card = () => {
	const { userDashboard, userDetails } = useAuth()
	const { setShowFundWallet, setShowCashOut, alertThis } = useMain()
	const [visible, setVisible] = useState(true)
	const [userBal, setUserBal] = useState(0)

	useEffect(() => {
		if (userDashboard.wallet && userDashboard.wallet.balance)
			setUserBal((x) => currencyFormat(userDashboard.wallet.balance))
	}, [userDashboard])

	return (
		<div
			className={cf(s.wMax, s.flex, s.flex_dColumn, s.spaceXBetween, d.card)}
		>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.cardTop
				)}
			>
				<span className={cf(s.flex, s.flexLeft, d.walBal)}>Wallet balance</span>
				<Currency />
			</div>
			<div className={cf(s.wMax, s.flex, s.flexLeft, d.cardMiddle)}>
				<span className={cf(s.flex, s.flexLeft, d.walVal)}>
					R {visible ? `${userDashboard?.wallet ? userBal : 0.0}` : '******'}
				</span>
				<div className={cf(d.setVisibleCon)}>
					{visible ? (
						<FaRegEye
							onClick={() => setVisible((e) => !true)}
							className={cf(s.flex, d.setVisible)}
						/>
					) : (
						<FaRegEyeSlash
							onClick={() => setVisible((e) => !false)}
							className={cf(s.flex, d.setVisible)}
						/>
					)}
				</div>
			</div>
			<div className={cf(s.wMax, s.flex, s.spaceXBetween, d.cardBottom)}>
				<div
					className={cf(
						s.flex,
						s.flexCenter,
						d.withdraw,
						s.pointerDisabled,
						d.notFocused
					)}
					// onClick={() => {
					// 	const kycExists =
					// 		userDetails && userDetails.kyc && userDetails.kyc.status
					// 	if (kycExists && userDetails.kyc.status === 'approved') {
					// 		setShowCashOut((x) => true)
					// 	} else if (kycExists && userDetails.kyc.status === 'completed') {
					// 		alertThis({
					// 			message: `Your KYC submission is under review, you'll be able to perform transactions once approved`,
					// 			forConfirmation: false,
					// 			canClear: false,
					// 		})
					// 	} else if (kycExists && userDetails.kyc.status === 'rejected') {
					// 		alertThis({
					// 			message: `Your KYC submission wasn't approved, please visit the identity page on KYC to upload your valid documents.`,
					// 			forConfirmation: false,
					// 			canClear: false,
					// 		})
					// 	} else {
					// 		alertThis({
					// 			message: `Please complete your KYC in order to perform transactions`,
					// 			forConfirmation: false,
					// 			canClear: false,
					// 		})
					// 	}
					// }}
				>
					<img
						src={withdraw}
						alt={'Little card icon'}
						className={cf(d.littleCardIcon)}
					/>
					<span className={cf(s.flex, s.flexCenter, d.cardButtons)}>
						Withdraw
					</span>
				</div>
				<div
					className={cf(s.flex, s.flexCenter, s.pointer, d.fund)}
					onClick={() => {
						const kycExists =
							userDetails && userDetails.kyc && userDetails.kyc.status
						if (kycExists && userDetails.kyc.status === 'approved') {
							setShowFundWallet((x) => true)
						} else if (kycExists && userDetails.kyc.status === 'completed') {
							alertThis({
								message: `Your KYC submission is under review, you'll be able to perform transactions once approved`,
								forConfirmation: false,
								canClear: false,
							})
						} else if (kycExists && userDetails.kyc.status === 'rejected') {
							alertThis({
								message: `Your KYC submission wasn't approved, please visit the identity page on KYC to upload your valid documents.`,
								forConfirmation: false,
								canClear: false,
							})
						} else {
							alertThis({
								message: `Please complete your KYC in order to perform transactions`,
								forConfirmation: false,
								canClear: false,
							})
						}
					}}
				>
					<img
						src={fund}
						alt={'Little card icon'}
						className={cf(d.littleCardIcon)}
					/>
					<span className={cf(s.flex, s.flexCenter, d.cardButtons)}>Fund</span>
				</div>
			</div>
		</div>
	)
}

export default Card
