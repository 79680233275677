import React from 'react'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const Bound = ({ bound, setShowBounds, setBound }) => {
	const handleClick = async () => {
		setBound((x) => bound)
		setShowBounds((x) => false)
	}
	return (
		<span
			className={cf(s.wMax, s.flex, s.flexCenter, d.bound, s.pointer)}
			onClick={handleClick}
		>
			{bound.tag}
		</span>
	)
}

const Bounds = ({ setShowBounds, bounds, setBound }) => {
	return (
		<div
			className={cf(s.wMax, s.flex, s.flex_dColumn, s.flexLeft, d.boundsCon)}
		>
			{bounds.map((el, i) => (
				<Bound
					key={i}
					bound={el}
					setShowBounds={setShowBounds}
					setBound={setBound}
				/>
			))}
		</div>
	)
}

export default Bounds
