import React from 'react'
import { Card } from '../../../components/Card'
import { CTA } from '../../../components/CTA'
import { IBrowse } from '../../../components/IBrowse'
import { Investments } from '../../../components/Investments'
import { OnboardingStatus } from '../../../components/OnboardingStatus'
import { Transactions } from '../../../components/Transactions'
import { useAuth, useMain } from '../../../hooks'
import d from '../../../styles/Dashboard.module.css'
import s from '../../../styles/Shared.module.css'
import { cf } from '../../../utils'

const Dashboard = () => {
	const { isTiny, isSmall } = useMain()
	const { userDetails } = useAuth()

	return (
		<div className={cf(s.wMax, s.flex, s.flexTop, d.innerMain)}>
			<div className={cf(s.wMax, s.flex, s.spaceXBetween, s.spaceYCenter)}>
				<div className={cf(s.flexOne, s.flex, s.flexLeft)}>
					<span className={cf(s.wMax, s.tLeft, d.hello)}>
						Hello {userDetails.user ? userDetails.user?.firstName : ''} 👋🏿
					</span>
					<h1 className={cf(s.wMax, s.tLeft, s.p0, d.title)}>Dashboard</h1>
				</div>
				{!isTiny ? <IBrowse noMargin={true} /> : <></>}
			</div>
			<div
				className={cf(s.wMax, s.flex, s.flexStart, s.spaceXBetween, d.tiles)}
			>
				<div className={cf(s.flex, d.cardBox, isSmall ? s.wMax : '')}>
					<div
						className={cf(
							s.flex,
							s.flexCenter,
							d.cardCon,
							isSmall ? s.wMax : ''
						)}
					>
						<Card />
					</div>
					{isSmall ? (
						<>
							{userDetails?.kyc?.status === 'approved' ? (
								<CTA />
							) : (
								<OnboardingStatus />
							)}
						</>
					) : (
						<></>
					)}
					<div
						className={cf(
							s.flex,
							s.flexCenter,
							d.transactionsCon,
							isSmall ? s.mT20 : ''
						)}
					>
						<Transactions />
					</div>
					{!isTiny ? (
						<div className={cf(s.wMax, s.flex, s.flexCenter, d.investmentsCon)}>
							<Investments />
						</div>
					) : (
						<></>
					)}
				</div>
				{!isSmall ? (
					<>
						{userDetails?.kyc?.status === 'approved' ? (
							<CTA />
						) : (
							<OnboardingStatus />
						)}
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	)
}

export default Dashboard
