import React, { useEffect, useLayoutEffect, useState } from 'react'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { Link, useNavigate } from 'react-router-dom'
import { FileUploader } from '../../../../components/FileUploader'
import { useAuth, useMain } from '../../../../hooks'
import a from '../../../../styles/Account.module.css'
import o from '../../../../styles/Onboarding.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf } from '../../../../utils'

const fileTypes = ['JPEG', 'JPG', 'PNG', 'PDF']

const Step = () => {
	const [requestBody, setRequestBody] = useState({})
	const [isDisabled, setIsDisabled] = useState(true)
	const [govIDUploaded, setGovIDUploaded] = useState(false)
	const [poaUploaded, setPoaUploaded] = useState(false)
	const { setCurrentStep } = useMain()
	const { doUpdateKYC, makeRequest, uncompleted, userDetails } = useAuth()
	const navigate = useNavigate()

	useLayoutEffect(() => {
		setCurrentStep((x) => 2)
		if (
			uncompleted[2] &&
			userDetails.kyc &&
			userDetails.kyc.status !== 'rejected'
		) {
			navigate('/account/kyc/employment')
		}
		return () => {
			setCurrentStep((x) => 2)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uncompleted])

	const giIDHandler = async (file) => {
		setRequestBody((x) => ({
			...x,
			giID: file,
		}))
	}

	useEffect(() => {
		if (requestBody.giID) {
			const doSomething = async () => {
				const res = await makeRequest(
					{
						path: 'user/upload',
						method: 'post',
						body: requestBody.giID,
					},
					true
				)
				if (res.data.url) {
					setGovIDUploaded((x) => res.data.url)
				} else {
					setGovIDUploaded((x) => false)
				}
			}
			doSomething()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestBody.giID])

	const poaHandler = async (file) => {
		setRequestBody((x) => ({
			...x,
			poa: file,
		}))
	}

	useEffect(() => {
		if (requestBody.poa) {
			const doSomething = async () => {
				const res = await makeRequest(
					{
						path: 'user/upload',
						method: 'post',
						body: requestBody.poa,
					},
					true
				)
				if (res.data.url) {
					setPoaUploaded((x) => res.data.url)
				} else {
					setPoaUploaded((x) => false)
				}
			}
			doSomething()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestBody.poa])

	const removeGiID = () => {
		setRequestBody((x) => ({
			...x,
			giID: null,
		}))
	}

	const removePOA = () => {
		setRequestBody((x) => ({
			...x,
			poa: null,
		}))
	}

	const handleSubmitHandler = async (e) => {
		e.preventDefault()
		const body = {
			governmentID: govIDUploaded,
			proofOfAddress: poaUploaded,
		}
		doUpdateKYC(body)
	}

	useEffect(() => {
		setIsDisabled((x) => !(govIDUploaded && poaUploaded))
	}, [govIDUploaded, poaUploaded])

	return (
		<div className={cf(s.flex, s.flexCenter, a.accountChild)}>
			<h1 className={cf(s.wMax, s.tLeft, s.m0, s.p0, a.theme)}>
				Identity verification
			</h1>
			<span className={cf(s.wMax, s.flex, s.flexLeft, s.tLeft, o.underTheme)}>
				Upload the following information to continue.
			</span>

			<form
				className={cf(s.wMax, s.flex, s.flexCenter, a.form)}
				onSubmit={handleSubmitHandler}
			>
				<div className={cf(s.wMax, s.flex, s.flexCenter, o.fileFieldCon)}>
					<ul className={cf(s.wMax, s.tLeft, o.fileFieldTitle)}>
						<li className={cf(s.wMax, o.fieldTitle)}>
							Government-issued ID (passport, drivers license){' '}
							<span className={cf(s.red)}>*</span>
						</li>
					</ul>
					<FileUploader
						handler={giIDHandler}
						tag={'giID'}
						state={requestBody['giID'] ?? null}
						fileTypes={fileTypes}
						classes={cf(s.flex, s.flexCenter, s.wMax, o.fileUploader)}
						multiple={false}
						required={true}
						removeFile={removeGiID}
					/>
				</div>

				<div className={cf(s.wMax, s.flex, s.flexCenter, o.fileFieldCon)}>
					<ul className={cf(s.wMax, s.tLeft, o.fileFieldTitle)}>
						<li className={cf(s.wMax, o.fieldTitle)}>
							Proof of address (e.g., utility bill, bank statement){' '}
							<span className={cf(s.red)}>*</span>
						</li>
					</ul>
					<FileUploader
						handler={poaHandler}
						tag={'poa'}
						state={requestBody['poa'] ?? null}
						fileTypes={fileTypes}
						classes={cf(s.flex, s.flexCenter, s.wMax, o.fileUploader)}
						multiple={false}
						required={true}
						removeFile={removePOA}
					/>
				</div>

				<button
					type={'submit'}
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						a.submitBtn,
						!isDisabled ? s.shadow : '',
						isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
					)}
					disabled={isDisabled}
				>
					{isDisabled
						? requestBody['phone'] &&
						  !isPossiblePhoneNumber(requestBody['phone'])
							? 'Phone number invalid'
							: 'Fill the form'
						: 'Continue'}
				</button>

				<div
					className={cf(s.wMax, s.flex, s.flexLeft, a.rememberToForget, s.p5)}
				>
					<span className={cf(s.wMax, s.dInlineBlock, s.tLeft, a.notice)}>
						The information provided would be saved securely as per our&nbsp;
						<Link
							to={'https://cribvest.com/terms-and-conditions/'}
							className={cf(s.link)}
						>
							Terms of Service
						</Link>
						&nbsp;&&nbsp;
						<Link
							to={'https://cribvest.com/privacy-policy/'}
							className={cf(s.link)}
						>
							Privacy Policy
						</Link>
					</span>
				</div>
			</form>

			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='482'
				height='2'
				viewBox='0 0 482 2'
				fill='none'
				className={cf(s.wMax, a.strike)}
			>
				<path
					d='M0 1H482'
					stroke='#B2B2B2'
					strokeWidth='0.5'
				/>
			</svg>

			<span className={cf(s.wMax, s.flex, s.flexCenter, s.tCenter, a.instead)}>
				Want to fill this later?&nbsp;
				<Link
					to={'/account/kyc/employment'}
					className={cf(s.flex, a.alt)}
				>
					Skip this step
				</Link>
			</span>
		</div>
	)
}

export default Step
