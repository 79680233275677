import React, { useState } from 'react'
import o from '../../styles/Onboarding.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import { OnboardingProgress } from '../OnboardingProgress'
import Step from './Step'

const Steps = () => {
	const [steps] = useState([
		{ step: 1, tag: 'Personal' },
		{ step: 2, tag: 'Identity' },
		{ step: 3, tag: 'Employment' },
		{ step: 4, tag: 'Finance' },
		{ step: 5, tag: 'Investment' },
	])
	return (
		<div className={cf(s.wMax, s.flex, s.flexCenter, o.stepsCon)}>
			<div className={cf(s.wMax, s.flex, s.flexCenter)}>
				<OnboardingProgress />
			</div>
			<div className={cf(s.wMax, s.flex, s.flexCenter, o.stepsWrapper)}>
				{steps.map((el, i) => (
					<Step
						stepInfo={el}
						key={i}
					/>
				))}
			</div>
		</div>
	)
}

export default Steps
