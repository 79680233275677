import axios from 'axios'

const serverURI = process.env.REACT_APP_BACKEND_BASE_URI

const axiosCall = axios.create({
	baseURL: `${serverURI}/v1/`,
	headers: {
		'Content-Type': 'application/json',
	},
	// withCredentials: true,
})

export const request = async ({
	path = '',
	method = 'get',
	accessToken = '',
	body = {},
	prop = 'data',
} = {}) => {
	const flags = {
		headers: {},
	}
	if (accessToken) flags.headers['Authorization'] = `Bearer ${accessToken}`
	try {
		if (method === 'get') {
			const res = await axiosCall.get(path, flags)
			return { [prop]: res.data, success: true }
		}
		if (method === 'delete') {
			const res = await axiosCall.delete(path, flags)
			return { [prop]: res.data, success: true }
		}
		const res = await axiosCall?.[method](path, body, flags)
		return { [prop]: res.data, success: true }
	} catch (err) {
		if (err.response)
			return {
				...err.response.data,
				status: err.response.status,
				headers: { ...err.response.headers },
				success: false,
			}
		else {
			return { ...err, success: false }
		}
	}
}

export const refreshRequest = async () => {
	try {
		const res = await axiosCall.get(
			'auth/refresh?refresh_token=' + window.localStorage.getItem(`cribvest`)
		)
		return { ...res.data, success: true }
	} catch (err) {
		if (err.response)
			return {
				...err.response.data,
				status: err.response.status,
				headers: { ...err.response.headers },
				success: false,
			}
		else {
			return { ...err, success: false }
		}
	}
}

export const requestUpload = async ({
	path = '',
	method = 'get',
	accessToken = '',
	file = {},
	prop = 'data',
} = {}) => {
	const flags = {
		headers: {},
	}
	if (accessToken) flags.headers['Authorization'] = `Bearer ${accessToken}`
	flags.headers['Content-Type'] = 'multipart/form-data'
	try {
		const formData = new FormData()
		formData.append('file', file)
		const res = await axiosCall?.[method](path, formData, flags)
		return { [prop]: res.data, success: true }
	} catch (err) {
		if (err.response)
			return {
				...err.response.data,
				status: err.response.status,
				headers: { ...err.response.headers },
				success: false,
			}
		else {
			return { ...err, success: false }
		}
	}
}
