import React, { useEffect, useState } from 'react'
import close from '../../assets/svg/close.svg'
import radio from '../../assets/svg/radio.svg'
import { useMain } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf, currencyFormat } from '../../utils'
import { FundContinue } from '../FundContinue'
import { WalletInput } from '../WalletInput'

const PhaseOne = ({
	setShowCashOut,
	setSentOtp,
	handleSubmitHandler,
	requestBody,
	handleChange,
	isDisabled,
	bankHandler,
	options,
}) => {
	const { userDashboard = {} } = useMain()

	return (
		<div className={cf(s.flex, s.flex_dColumn, s.flexLeft, d.popup)}>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.popupTitleCon
				)}
			>
				<span className={cf(s.flex, s.flexCenter, d.popupTitle)}>Withdraw</span>
				<div
					className={cf(s.flex, s.flexCenter, d.popupCloseCon, s.pointer)}
					onClick={() => setShowCashOut((x) => false)}
				>
					<img
						src={close}
						alt={'close button'}
						className={cf(s.flex, s.flexCenter, d.popupClose)}
					/>
				</div>
			</div>
			<div className={cf(s.wMax, s.flex, s.flexCenter, d.popupBody)}>
				<form
					className={cf(
						s.wMax,
						s.flex,
						s['flex_dColumn'],
						s.flexLeft,
						s.g10,
						s.m0,
						d.walletForm
					)}
					onSubmit={handleSubmitHandler}
				>
					<WalletInput
						{...{
							tag: 'bank',
							state: requestBody,
							required: true,
							label: 'Bank',
							handler: bankHandler,
							type: 'select',
							selectOptions: options,
							placeholder: 'Select Bank',
						}}
					/>
					<WalletInput
						{...{
							tag: 'acctNum',
							state: requestBody,
							required: true,
							label: 'Account Number',
							handler: handleChange,
							type: 'number',
						}}
					/>
					<div className={cf(s.wMax, s.flex, s.flexCenter, d.cashOutAmountCon)}>
						<WalletInput
							{...{
								tag: 'amount',
								state: requestBody,
								required: true,
								label: 'Amount (ZAR)',
								handler: handleChange,
								type: 'number',
								placeholder: '0',
							}}
						/>
					</div>
					<span
						className={cf(s.wMax, s.flex, s.flexLeft, d.casOutAmountValueCon)}
					>
						Balance (ZAR){' '}
						{userDashboard.wallet
							? currencyFormat(userDashboard.wallet.balance)
							: 0}
					</span>
					<div
						className={cf(s.wMax, s.flex, s.flexRight, d.walletContinueCon)}
						onClick={() => {
							setSentOtp((x) => true)
						}}
					>
						<FundContinue {...{ isDisabled }} />
					</div>
				</form>
			</div>
		</div>
	)
}

const OTP = ({
	setShowCashOut,
	handleSubmitHandler,
	requestBody,
	handleChange,
	isDisabled,
	setOtpSent,
}) => {
	return (
		<div className={cf(s.flex, s.flex_dColumn, s.flexLeft, d.popup)}>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.popupTitleCon
				)}
			>
				<span className={cf(s.flex, s.flexCenter, d.popupTitle)}>
					Fund Wallet
				</span>
				<div
					className={cf(s.flex, s.flexCenter, d.popupCloseCon, s.pointer)}
					onClick={() => setShowCashOut((x) => false)}
				>
					<img
						src={close}
						alt={'close button'}
						className={cf(s.flex, s.flexCenter, d.popupClose)}
					/>
				</div>
			</div>
			<div className={cf(s.wMax, s.flex, s.flexCenter, d.popupBody)}>
				<div
					className={cf(s.wMax, s.flex, s.flexCenter, s.m0, d.txnTypeConParent)}
				>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.spaceXBetween,
							s.spaceYStart,
							d.txnTypeCon
						)}
					>
						<img
							src={radio}
							alt={'radio button'}
							className={cf(s.flex, s.flexCenter, d.fundRadio)}
						/>
						<div
							className={cf(
								s.flex,
								s.flexOne,
								s.flex_dColumn,
								s.flexLeft,
								s.g10,
								d.fundDes
							)}
						>
							<span className={cf(s.wMax, s.flex, s.flexLeft, d.fundCTU)}>
								Card • Transfer
							</span>
							<span className={cf(s.wMax, s.flex, s.flexLeft, d.fundLilDes)}>
								Top-up your wallet with your debit card, bank transfer, bank login and more.
							</span>
						</div>
					</div>
				</div>
				<form
					className={cf(
						s.wMax,
						s.flex,
						s['flex_dColumn'],
						s.flexLeft,
						s.g10,
						s.m0,
						d.walletForm
					)}
					onSubmit={handleSubmitHandler}
				>
					<WalletInput
						{...{
							tag: 'amount',
							state: requestBody,
							required: true,
							label: 'Amount (ZAR)',
							handler: handleChange,
							type: 'text',
						}}
					/>

					<div className={cf(s.wMax, s.flex, s.flexRight, d.walletContinueCon)}>
						<FundContinue {...{ isDisabled }} />
					</div>
				</form>
			</div>
		</div>
	)
}

const FundWalletSuccess = () => {
	const { setShowCashOut, setWTxnSuccessful } = useMain()
	const [requestBody, setRequestBody] = useState(true)
	const [isDisabled, setIsDisabled] = useState(true)
	const [otpSent, setOtpSent] = useState(false)

	const [options] = useState([
		{ value: 'bank', label: 'Cribvest' },
		// { value: 'fulltime', label: 'Employed: Full-time' },
		// { value: 'parttime', label: 'Employed: Part-time' },
		// { value: 'selfemployed', label: 'Self-employed' },
	])

	const bankHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			bank: x,
		}))

	const handleChange = (e) => {
		const name = e.target.name
		let value = e.target.value
		const obj = {
			amount: true,
		}
		switch (name) {
			default:
				if (obj[name]) {
					setRequestBody((x) => ({
						...x,
						[name]: value,
					}))
				}
				break
		}
	}

	const handleSubmitHandler = async (e) => {
		// TODO Update functionality
		e.preventDefault()
		setOtpSent((x) => true)

		// await requestTopUp(requestBody.amount ?? 0)
	}

	const handleSubmitHandler_ = async (e) => {
		// TODO Update functionality
		e.preventDefault()
		setWTxnSuccessful((x) => true)

		// await requestTopUp(requestBody.amount ?? 0)
	}

	useEffect(() => {
		setIsDisabled((x) => !requestBody['amount'])
	}, [requestBody])

	return (
		<div className={cf(s.p_fixed, s.flex, s.flexTop, d.popupCon)}>
			<div className={cf(s.vWMax, s.vHMax, s.p_fixed, d.popUpMask)}></div>
			{!otpSent ? (
				<PhaseOne
					{...{
						setShowCashOut,
						handleSubmitHandler,
						requestBody,
						handleChange,
						isDisabled,
						options,
						bankHandler,
						setOtpSent,
					}}
				/>
			) : (
				<OTP
					{...{
						setShowCashOut,
						handleSubmitHandler: handleSubmitHandler_,
						requestBody,
						handleChange,
						isDisabled,
						setOtpSent,
					}}
				/>
			)}
		</div>
	)
}

export default FundWalletSuccess
