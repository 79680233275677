import React, { useRef } from 'react'
import appleIcon from '../../assets/svg/apple.svg'
import googleIcon from '../../assets/svg/google.svg'
import microsoftIcon from '../../assets/svg/microsoft.svg'
import { useAuth } from '../../hooks'
import s from '../../styles/Shared.module.css'
import o from '../../styles/SingleSignOn.module.css'
import { cf } from '../../utils'

const SingleSignOn = ({
	login = true,
	type = 'google',
	fill = false,
	greyed = false,
}) => {
	const iconRef = useRef()
	const icon = { appleIcon, googleIcon, microsoftIcon }[type + 'Icon']
	const { ssoLogin } = useAuth()

	const handler = () => {
		if (!greyed) {
			const link = ssoLogin(type)
			window.open(link, '_self')
		}
	}

	return (
		<div
			className={cf(
				s.flex,
				s.flexCenter,
				o.sso,
				o.sso_style,
				fill ? s.wMax : '',
				greyed ? o.greyed : '',
				!greyed ? s.shadow : '',
				!greyed ? s.pointer : ''
			)}
			onClick={handler}
		>
			<img
				src={icon}
				ref={iconRef}
				alt={`${type} single-sign-on`}
				className={cf(s.flex, o.sso_icon, o?.['sso_icon_' + type + '_style'])}
			/>
			<span className={cf(s.flex, s.flexCenter, o.ssoTxt, fill ? o.fill : '')}>
				Sign {login ? 'in' : 'up'}
				{fill
					? ` with ${String(type).slice(0, 1).toUpperCase()}${String(
							type
					  ).slice(1)}`
					: ''}
			</span>
		</div>
	)
}

export default SingleSignOn
