import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import checkThick from '../../../../assets/svg/checkThick.svg'
import chevronWhite from '../../../../assets/svg/chevronWhite.svg'
import { useAuth, useMain } from '../../../../hooks'
import a from '../../../../styles/Account.module.css'
import d from '../../../../styles/Dashboard.module.css'
import o from '../../../../styles/Onboarding.module.css'
import p from '../../../../styles/Properties.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf, currencyFormat } from '../../../../utils'

const PurchaseProperty = ({ progressRef }) => {
	const { property: x } = useMain()
	return (
		<div
			className={cf(
				s.flex,
				s.flex_dColumn,
				s.flexLeft,
				p.investMetricsParent,
				p.investMetricsParent_
			)}
		>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					p.pPropMetricCon,
					p.pPropMetricCon_
				)}
			>
				<div
					className={cf(s.flex, s.flex_dColumn, s.flexTop, p.firstPropMetric)}
				>
					<span className={cf(s.flex, s.flexLeft, p.propMetricTag)}>
						Total Supply
					</span>
					<span className={cf(s.flex, s.flexRight, p.propMetricVal)}>
						{currencyFormat(x.volume, false)}
					</span>
				</div>
				<div className={cf(s.flex, s.flexOne, s.flex_dColumn, s.flexBottom)}>
					<span className={cf(s.flex, s.flexLeft, p.propMetricTag)}>
						Available Supply
					</span>
					<span className={cf(s.flex, s.flexRight, p.propMetricVal)}>
						{currencyFormat(Number(x.volume) - Number(x.sold), false)}
					</span>
				</div>
			</div>
			<div className={cf(s.wMax, s.flex, s.flexCenter)}>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter,
						p.propertyListing
					)}
				>
					<span className={cf(s.flex, s.flexLeft, p.propMetricTag, p.lineH30)}>
						Investment Target:
					</span>
					<span className={cf(s.flex, s.flexRight, p.propMetricVal, p.lineH30)}>
						R {currencyFormat(Number(x.price) * Number(x.volume))}
					</span>
				</div>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						o.onboardingProgress,
						d.iPropertyProgress
					)}
				>
					<div className={cf(s.wMax, s.hMax, s.flex, s.flexCenter)}>
						<div
							className={cf(
								s.wMax,
								s.flex,
								s['flex_dColumn'],
								s.flexLeft,
								s.g10,
								o.progressCon2
							)}
						>
							<div
								className={cf(
									s.wMax,
									s.hMax,
									s.flex,
									s.flexCenter,
									o.progressBarCon
								)}
							>
								<div
									className={cf(s.wMax, o.progressBarComplete2, o.l0)}
									ref={progressRef}
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const PurchaseSuccess = () => {
	return (
		<div
			className={cf(
				s.wMax,
				s.flex,
				s.flex_dColumn,
				s.flexLeft,
				p.popup,
				p.investMetricsParent,
				p.investMetricsParent___
			)}
		>
			<div className={cf(s.wMax, s.flex, s.flexCenter)}>
				<div className={cf(s.wMax, s.flex, s.flexCenter, s.m0, s.p0)}>
					<div
						className={cf(
							s.flex,
							s.flexCenter,
							d.popupCheckCon,
							p.popupCheckCon
						)}
					>
						<img
							src={checkThick}
							alt={'check button'}
							className={cf(s.flex, s.flexCenter, d.popupCheck, p.popupCheck)}
						/>
					</div>
				</div>
				<div className={cf(s.wMax, s.flex, s.flexCenter, s.m0, s.p0)}>
					<span
						className={cf(s.flex, s.flexCenter, s.tCenter, p.popupSuccessTitle)}
					>
						Congratulations!
						<br />
						Shares purchase was successful
					</span>
				</div>
				<div className={cf(s.wMax, s.flex, s.flexCenter, s.m0, s.p0)}>
					<span
						className={cf(
							s.flex,
							s.flexCenter,
							s.tCenter,
							p.popupSuccessMessage
						)}
					>
						Your waterfall place property shares was purchased successfully and
						added to your portfolio.
					</span>
				</div>
				<div className={cf(s.wMax, s.flex, s.flexCenter, p.viewPortCon)}>
					<Link
						to={'/dashboard/investments'}
						className={cf(
							s.wMax,
							s.flex,
							s.flexCenter,
							s.pointer,
							p.viewPortButton
						)}
					>
						View Portfolio
					</Link>
				</div>
			</div>
		</div>
	)
}

const Info = ({ title, subtitle, position, hasTopBorder = false }) => {
	const positions = {
		left: cf(s.tLeft),
		right: cf(s.tRight),
		center: cf(s.tCenter),
	}
	return (
		<div
			className={cf(
				s.flex,
				s.flex_dColumn,
				s.flexLeft,
				p.xInfo,
				hasTopBorder ? p.xInfoWithBorder : '',
				hasTopBorder ? s.wMax : ''
			)}
		>
			<span className={cf(s.wMax, positions[position], p.xInfoTitle)}>
				{title}
			</span>
			<span className={cf(s.wMax, positions[position], p.xInfoSubtitle)}>
				{subtitle}
			</span>
		</div>
	)
}

const Cost = ({ tag, value }) => {
	return (
		<div
			className={cf(s.wMax, s.flex, s.spaceXBetween, s.spaceYCenter, p.xCost)}
		>
			<span className={cf(s.flex, p.xCostTag)}>{tag}</span>
			<span className={cf(s.flex, p.xCostValue)}>{value}</span>
		</div>
	)
}

const Checkout = () => {
	const { userDashboard } = useAuth()
	const { isTiny, property: x, purchaseProperty, alertThis } = useMain()
	const [amount, setAmount] = useState(0)
	const [units, setUnits] = useState(0)
	const [agreed, setAgreed] = useState(false)
	const navigate = (x) => window.open(x, '_blank')
	const [payable, setPayable] = useState(0)
	const [fee, setFee] = useState(units * x.transFee)

	const handleChange = (e) => {
		setAmount((x) => e.target.value)
	}

	const handler = (e) => {
		setAgreed((x) => e.target.checked)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		const units = Math.floor(amount / x.price)
		const res = await purchaseProperty(x.id, units)
		if (!res.success)
			alertThis({
				message: res.message,
				forConfirmation: false,
				canClear: false,
			})
	}

	useEffect(() => {
		const calculatedUnits = +amount / +x.price;
		const roundedUnits = parseFloat(calculatedUnits.toFixed(2)); // Round units to two decimal places
		setUnits(roundedUnits);

		const calculatedFee = roundedUnits * x.transFee;
		setFee(calculatedFee);

		const calculatedPayable = roundedUnits * x.price + calculatedFee;
		setPayable(calculatedPayable);
	}, [x, amount]);


	return (
		<form
			className={cf(s.wMax, s.flex, s.flexTop)}
			onSubmit={handleSubmit}
		>
			<div className={cf(s.wMax, s.flex, s.flexTop, p.xCheckout)}>
				<span className={cf(s.flex, s.flexCenter, p.xCheckoutTitle)}>
					How much would you like to invest?
				</span>
				<label
					htmlFor={'amount'}
					className={cf(
						s.wMax,
						s.flex,
						s.flex_dColumn,
						s.flexEnd,
						p.xCheckoutLabel
					)}
				>
					<span
						className={cf(s.wMax, s.flex, s.flexLeft, p.xCheckoutLabelSpan)}
					>
						Amount to invest
					</span>
					<div className={cf(s.wMax, s.flex, s.flexLeft, p.xCheckoutHiddenDiv)}>
						<span className={cf(s.flex, p.xCheckoutHiddenR)}>R</span>
						<input
							type={'number'}
							id={'amount'}
							name={'amount'}
							step={1}
							min={0}
							max={userDashboard?.wallet?.balance ?? 0}
							onChange={handleChange}
							placeholder={'0'}
							className={cf(s.flex, p.xCheckoutInput)}
						/>
					</div>
				</label>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter,
						p.xInfoCon
					)}
				>
					<Info
						{...{
							title: 'Your Available Balance',
							subtitle: `R ${currencyFormat(
								userDashboard?.wallet?.balance ?? 0
							)}`,
							position: 'left',
						}}
					/>
					<Info
						{...{
							title: 'Property Share Price',
							subtitle: `R ${currencyFormat(x.price)}`,
							position: isTiny ? 'right' : 'center',
						}}
					/>
					<Info
						{...{
							title: 'No. Of Shares',
							subtitle: `${currencyFormat(units)}`,
							position: isTiny ? 'center' : 'right',
							hasTopBorder: isTiny,
						}}
					/>
				</div>
				<div className={cf(s.wMax, s.flex, s.flexTop, p.xCostCon)}>
					<Cost
						{...{
							tag: 'Investment Value:',
							value: `R ${currencyFormat(units * x.price)}`,
						}}
					/>
					<Cost
						{...{
							tag: 'Investment Cost (including VAT):',
							value: `R ${currencyFormat(fee)}`,
						}}
					/>
					<Cost
						{...{
							tag: 'Amount Due:',
							value: `${currencyFormat(payable)}`,
						}}
					/>
				</div>
			</div>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYStart,
					p.xCheckoutNotice
				)}
			>
				<div
					className={cf(
						s.flex,
						s.flexStart,
						s.flexOne,
						s.g10,
						p.xCheckoutAgreement
					)}
				>
					<label
						className={cf(
							s.flex,
							s.flexCenter,
							a.rememberCon,
							s.pointer,
							agreed ? a.doRemember : ''
						)}
						htmlFor={'agreed'}
					>
						{agreed ? (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='18'
								height='18'
								viewBox='0 0 18 18'
								fill='none'
								className={cf(a.remember)}
							>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M3.49372 8.41075C3.15201 8.08532 2.59799 8.08532 2.25628 8.41075C1.91457 8.73619 1.91457 9.26383 2.25628 9.58926L6.63128 13.7559C6.98577 14.0935 7.56502 14.079 7.90038 13.7241L15.7754 5.39081C16.0987 5.04872 16.0695 4.52181 15.7103 4.21393C15.3511 3.90605 14.7979 3.93378 14.4746 4.27587L7.21658 11.9563L3.49372 8.41075Z'
									fill='white'
								/>
							</svg>
						) : (
							<></>
						)}
					</label>
					<span
						className={cf(s.flexOne, s.dInlineBlock, p.xCheckoutNoticeText)}
					>
						I accept the Cribvest&apos;s{' '}
						<span
							onClick={() =>
								navigate('https://cribvest.com/terms-and-conditions/')
							}
							className={cf(p.xCheckoutNoticeTextLink, s.pointer)}
						>
							Terms of Service
						</span>
						,&nbsp;
						<span
							onClick={() => navigate('https://cribvest.com/privacy-policy/')}
							className={cf(p.xCheckoutNoticeTextLink, s.pointer)}
						>
							Privacy Policy
						</span>
						&nbsp;and acknowledge that I have had access to the Prospectus.<br />
						I acknowledge and confirm that all investment decisions are
						made solely by me and at my own risk as outlined in the&nbsp;
						<span
							onClick={() => navigate('https://cribvest.com/disclaimer/')}
							className={cf(p.xCheckoutNoticeTextLink, s.pointer)}
						>Disclaimers</span>.
					</span>
					<input
						type={'checkbox'}
						id={'agreed'}
						name={'agreed'}
						className={cf(s.flex, a.rememberInput, s.p_absolute)}
						onChange={handler}
					/>
				</div>
				<button
					type={'submit'}
					className={cf(
						s.flex,
						s.flexCenter,
						!(
							units &&
							agreed &&
							(userDashboard?.wallet?.balance ?? 0) >= payable
						)
							? s.shadow
							: '',
						!(
							units &&
							agreed &&
							(userDashboard?.wallet?.balance ?? 0) >= payable
						)
							? cf(a.proceedDisabled, s.pointerDisabled)
							: '',
						p.purchaseButton
					)}
					disabled={
						!(
							units &&
							agreed &&
							(userDashboard?.wallet?.balance ?? 0) >= payable
						)
					}
				>
					Purchase - R {currencyFormat(payable)}
				</button>
			</div>
		</form>
	)
}

const PropertyInvest = ({ setShowInvest }) => {
	const {
		isSmall,
		property: x,
		purchaseSuccess,
		setPurchaseSuccess,
		setShowImagesView,
	} = useMain()
	const container = cf(s.wMax, s.flex, p.innerMain)
	const [showMore, setShowMore] = useState(false)
	const [selected, setSelected] = useState(0)
	const [price, setPrice] = useState(0)
	const [cost] = useState(x.price)

	const progressRef = useRef()
	const propertyRef = useRef()

	useEffect(() => {
		const _ = Number(x.price) * Number(x.sold)
		const __ = Number(x.price) * Number(x.volume)
		const ___ = (_ / __) * 100
		const ____ = Math.round(100 - ___)

		// timer.current = setTimeout(() => {
		if (progressRef.current) progressRef.current.style.right = `${____}%`
		// }, 2000)
		if (propertyRef.current) {
			propertyRef.current.style.background = `url(${x.image[0]})`
			propertyRef.current.style.backgroundPosition = 'center'
			propertyRef.current.style.backgroundRepeat = 'no-repeat'
			propertyRef.current.style.backgroundSize = 'cover'
		}
	}, [x.volume, x.price, x.sold, x.image])

	useEffect(() => {
		if (isSmall === false && showMore === true) {
			setShowMore((x) => false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSmall])

	useEffect(() => {
		setPrice((x) => selected * cost)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected])

	useEffect(() => {
		return () => {
			setPurchaseSuccess((x) => false)
			setShowImagesView((x) => false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={cf(s.wMax, s.flex, s.flex_dColumn, s.flexLeft)}>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.flexCenter,
					s.p_relative,
					p.propertyBannerCon
				)}
				ref={propertyRef}
			>
				<div className={cf(container, s.wMax, s.hMax, s.flexTop)}>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.flex_dColumn,
							s.spaceXBetween,
							s.spaceYStart,
							p.propertyBanner,
							p.propertyBanner_
						)}
					>
						<div className={cf(s.wMax, s.flex, s.spaceXBetween, s.spaceYEnd)}>
							<button
								className={cf(s.flex, s.flexCenter, p.pPGoBackBtn)}
								onClick={() => {
									setShowInvest((x) => false)
								}}
							>
								<img
									src={chevronWhite}
									alt={'go back icon'}
									className={cf(s.flex, s.flexCenter, s.pPGoBackIcon)}
								/>
								{!isSmall ? (
									<span className={cf(s.flex, s.flexCenter, p.pPGoBackTxt)}>
										Go back
									</span>
								) : (
									<></>
								)}
							</button>
						</div>
						<div className={cf(s.wMax, s.flex, s.flexCenter, p.propThemeTitle)}>
							<div
								className={cf(
									s.flex,
									s.flexLeft,
									s.flex_dColumn,
									s.flexTop,
									p.pPLocationInfoCon,
									p.pPLocationInfoCon_
								)}
							>
								<span
									className={cf(
										s.wMax,
										s.flex,
										s.flexLeft,
										p.youAreInvestingIn
									)}
								>
									You are investing in
								</span>
								<h1 className={cf(s.wMax, s.flex, s.m0, s.p0, p.propertyTheme)}>
									{x.name}
								</h1>
							</div>
						</div>
					</div>
					<div className={cf(s.flex, s.flexTop, s.flex_dColumn, p.modalParent)}>
						{purchaseSuccess ? (
							<PurchaseSuccess />
						) : (
							<>
								<PurchaseProperty
									{...{
										progressRef,
										setSelected,
										selected,
										price,
										setPurchaseSuccess,
									}}
								/>
								<Checkout />
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PropertyInvest
