import React from 'react'
import checkThick from '../../assets/svg/checkThick.svg'
import close from '../../assets/svg/close.svg'
import { useMain } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const CashOutSuccess = () => {
	// const avatarRef = useRef()
	// useEffect(() => {}, [])
	const { setTxnSuccessful } = useMain()

	return (
		<div
			className={cf(s.vWMax, s.vHMax, s.p_fixed, s.flex, s.flexTop, d.popupCon)}
		>
			<div className={cf(s.vWMax, s.vHMax, s.p_fixed, d.popUpMask)}></div>
			<div
				className={cf(
					s.flex,
					s.flex_dColumn,
					s.flexLeft,
					s.p_relative,
					d.popup,
					d.popupSuccess
				)}
			>
				{/* <div
					className={cf(
						s.wMax,
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter,
						d.popupTitleCon
					)}
				>
					<span className={cf(s.flex, s.flexCenter, d.popupTitle)}>
						Fund Wallet
					</span>
				</div> */}
				<div
					className={cf(
						s.flex,
						s.flexCenter,
						s.p_absolute,
						d.popupCloseCon,
						d.popupCloseConSuccess,
						s.pointer
					)}
					onClick={() => setTxnSuccessful((x) => false)}
				>
					<img
						src={close}
						alt={'close button'}
						className={cf(s.flex, s.flexCenter, d.popupClose)}
					/>
				</div>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						d.popupBody,
						d.popupBodySuccess
					)}
				>
					<div className={cf(s.wMax, s.flex, s.flexCenter, s.m0, s.p0)}>
						<div className={cf(s.flex, s.flexCenter, d.popupCheckCon)}>
							<img
								src={checkThick}
								alt={'check button'}
								className={cf(s.flex, s.flexCenter, d.popupCheck)}
							/>
						</div>
					</div>
					<div className={cf(s.wMax, s.flex, s.flexCenter, s.m0, s.p0)}>
						<span
							className={cf(
								s.flex,
								s.flexCenter,
								s.tCenter,
								d.popupSuccessTitle
							)}
						>
							Congratulations! Transaction Successful
						</span>
					</div>
					<div className={cf(s.wMax, s.flex, s.flexCenter, s.m0, s.p0)}>
						<span
							className={cf(
								s.flex,
								s.flexCenter,
								s.tCenter,
								d.popupSuccessMessage
							)}
						>
							Your transaction has been successfully initiated. Kindly refer to
							your account dashboard to view your wallet balance.
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CashOutSuccess
