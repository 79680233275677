import React, { useEffect, useState } from 'react'
import close from '../../assets/svg/close.svg'
import radio from '../../assets/svg/radio.svg'
import { useMain } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import { FundContinue } from '../FundContinue'
import { WalletInput } from '../WalletInput'

const FundWalletSuccess = () => {
	const { setShowFundWallet, requestTopUp } = useMain()
	const [requestBody, setRequestBody] = useState(true)
	const [isDisabled, setIsDisabled] = useState(true)

	const handleChange = (e) => {
		const name = e.target.name
		let value = e.target.value
		const obj = {
			amount: true,
		}
		switch (name) {
			default:
				if (obj[name]) {
					setRequestBody((x) => ({
						...x,
						[name]: value,
					}))
				}
				break
		}
	}

	const handleSubmitHandler = async (e) => {
		// TODO Update functionality
		e.preventDefault()
		await requestTopUp(requestBody.amount ?? 0)
	}

	useEffect(() => {
		setIsDisabled((x) => !requestBody['amount'])
	}, [requestBody])

	return (
		<div className={cf(s.p_fixed, s.flex, s.flexTop, d.popupCon)}>
			<div className={cf(s.vWMax, s.vHMax, s.p_fixed, d.popUpMask)}></div>
			<div className={cf(s.flex, s.flex_dColumn, s.flexLeft, d.popup)}>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.spaceXBetween,
						s.spaceYCenter,
						d.popupTitleCon
					)}
				>
					<span className={cf(s.flex, s.flexCenter, d.popupTitle)}>
						Fund Wallet
					</span>
					<div
						className={cf(s.flex, s.flexCenter, d.popupCloseCon, s.pointer)}
						onClick={() => setShowFundWallet((x) => false)}
					>
						<img
							src={close}
							alt={'close button'}
							className={cf(s.flex, s.flexCenter, d.popupClose)}
						/>
					</div>
				</div>
				<div className={cf(s.wMax, s.flex, s.flexCenter, d.popupBody)}>
					<div
						className={cf(
							s.wMax,
							s.flex,
							s.flexCenter,
							s.m0,
							d.txnTypeConParent
						)}
					>
						<div
							className={cf(
								s.wMax,
								s.flex,
								s.spaceXBetween,
								s.spaceYStart,
								d.txnTypeCon
							)}
						>
							<img
								src={radio}
								alt={'radio button'}
								className={cf(s.flex, s.flexCenter, d.fundRadio)}
							/>
							<div
								className={cf(
									s.flex,
									s.flexOne,
									s.flex_dColumn,
									s.flexLeft,
									s.g10,
									d.fundDes
								)}
							>
								<span className={cf(s.wMax, s.flex, s.flexLeft, d.fundCTU)}>
									Card • Transfer
								</span>
								<span className={cf(s.wMax, s.flex, s.flexLeft, d.fundLilDes)}>
									Top-up your wallet with your debit card, bank transfer, bank login and more.
								</span>
							</div>
						</div>
					</div>
					<form
						className={cf(
							s.wMax,
							s.flex,
							s['flex_dColumn'],
							s.flexLeft,
							s.g10,
							s.m0,
							d.walletForm
						)}
						onSubmit={handleSubmitHandler}
					>
						<WalletInput
							{...{
								tag: 'amount',
								state: requestBody,
								required: true,
								label: 'Amount (ZAR)',
								handler: handleChange,
								type: 'text',
							}}
						/>
						<div
							className={cf(s.wMax, s.flex, s.flexRight, d.walletContinueCon)}
						>
							<FundContinue {...{ isDisabled }} />
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default FundWalletSuccess
