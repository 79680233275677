import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CashOut, CashOutSuccess } from '../components/CashOut'
import { Footer } from '../components/Footer'
import { FundSuccess, FundWallet } from '../components/FundWallet'
import { ImagesView } from '../components/ImagesView'
import { LoadingPreview } from '../components/LoadingPreview'
import { NavBar } from '../components/NavBar'
import { NavBarDashboard } from '../components/NavBarDashboard'
import { NavBarProperties } from '../components/NavBarProperties'
import { useMain } from '../hooks'
import a from '../styles/App.module.css'
import '../styles/Global.module.css'
import s from '../styles/Shared.module.css'
import { cf } from '../utils'

const App = ({ children }) => {
	const location = useLocation()
	const navigate = useNavigate()
	const {
		processing,
		txnSuccessful,
		showFundWallet,
		showCashOut,
		wTxnSuccessful,
		showImagesView,
	} = useMain()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		})
	}, [location])

	return (
		<div
			className={cf(
				s.wMax,
				s.flex,
				s.flex_dColumn,
				s.p0,
				s.m0,
				s.window,
				s.p_relative,
				a.app,
				location.pathname.indexOf('/properties') === 0
					? s.flexLeft
					: cf(s.spaceXBetween, s.spaceYCenter),
				location.pathname.indexOf('/dashboard') === 0 ? a.dashboard : ''
			)}
		>
			{location.pathname.indexOf('/dashboard') === 0 ? (
				<NavBarDashboard />
			) : location.pathname.indexOf('/properties') === 0 ? (
				<NavBarProperties />
			) : (
				<NavBar />
			)}
			<div className={cf(s.wMax)}>{children}</div>
			{(location.pathname.indexOf('/account') !== 0 ||
				location.pathname.indexOf('/account/kyc') === 0) &&
			location.pathname.indexOf('/properties') !== 0 &&
			location.pathname.indexOf('/dashboard') !== 0 ? (
				<Footer />
			) : (
				<></>
			)}
			{processing && <LoadingPreview />}
			{txnSuccessful ? <FundSuccess /> : <></>}
			{showFundWallet ? <FundWallet /> : <></>}
			{wTxnSuccessful ? <CashOutSuccess /> : <></>}
			{showCashOut ? <CashOut /> : <></>}
			{showImagesView ? <ImagesView /> : <></>}
		</div>
	)
}

export default App
