import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useAuth, useMain } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
// import Helmet from 'react-helmet'
import { UnknownRouteHandler } from '../404'
import { DashboardPage } from './DashboardPage'
import { InvestmentsPage } from './InvestmentsPage'
import { SettingsPage } from './SettingsPage'
import { SupportPage } from './SupportPage'
import { TransactionsPage } from './TransactionsPage'

const Dashboard = () => {
	const {
		authorized,
		// userDetails, setUserDetails
	} = useAuth()
	const { showTicketingView } = useMain()
	const navigate = useNavigate()
	const location = useLocation()
	// useEffect(() => {
	// 	if (!authorized) navigate('/account/login')
	// 	// const { kyc = {} } = userDetails
	// 	// setUserDetails((x) => ({
	// 	// 	...userDetails,
	// 	// 	kyc: {
	// 	// 		...kyc,
	// 	// 		status: 'approved',
	// 	// 	},
	// 	// }))
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [authorized])

	return (
		<div
			className={cf(s.wMax, s.flex, s.flex_dColumn, s.flexLeft, d.mainParent)}
		>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.flex_dColumn,
					s.flexLeft,
					s.p_relative,
					s.parent,
					d.main,
					// d.innerMain,
					location.pathname === '/dashboard/support' && showTicketingView
						? d.showingTicketingView
						: ''
				)}
			>
				{location.pathname === '/dashboard/support' && showTicketingView ? (
					<div
						className={cf(
							s.flex,
							s.flexCenter,
							s.p_absolute,
							d.ticketingViewArc
						)}
					></div>
				) : (
					<></>
				)}
				<Routes>
					<Route
						path='/'
						element={<DashboardPage />}
					/>
					<Route
						path='investments'
						element={<InvestmentsPage />}
					/>
					<Route
						path='transactions'
						element={<TransactionsPage />}
					/>
					<Route
						path='settings'
						element={<SettingsPage />}
					/>
					<Route
						path='support'
						element={<SupportPage />}
					/>
					<Route
						path='/*'
						element={<UnknownRouteHandler />}
					/>
				</Routes>
			</div>
		</div>
	)
}

export default Dashboard
