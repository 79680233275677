import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import AuthContextProvider from './context/AuthContext'
import MainContextProvider from './context/MainContext'
import { Account, Dashboard, NotFound, UnknownRouteHandler } from './layouts'
import AppView from './views/App'
// Manrope
import './assets/fonts/Manrope/Manrope-VariableFont_wght.ttf'
import './assets/fonts/Manrope/static/Manrope-Bold.ttf'
import './assets/fonts/Manrope/static/Manrope-ExtraBold.ttf'
import './assets/fonts/Manrope/static/Manrope-ExtraLight.ttf'
import './assets/fonts/Manrope/static/Manrope-Light.ttf'
import './assets/fonts/Manrope/static/Manrope-Medium.ttf'
import './assets/fonts/Manrope/static/Manrope-Regular.ttf'
import './assets/fonts/Manrope/static/Manrope-SemiBold.ttf'
// ClashDisplay
import './assets/fonts/ClashDisplay/ClashDisplay-Variable.ttf'
import './assets/fonts/ClashDisplay/static/ClashDisplay-Bold.otf'
import './assets/fonts/ClashDisplay/static/ClashDisplay-Extralight.otf'
import './assets/fonts/ClashDisplay/static/ClashDisplay-Light.otf'
import './assets/fonts/ClashDisplay/static/ClashDisplay-Medium.otf'
import './assets/fonts/ClashDisplay/static/ClashDisplay-Regular.otf'
import './assets/fonts/ClashDisplay/static/ClashDisplay-Semibold.otf'
// Mulish
import './assets/fonts/Mulish/Mulish-VariableFont_wght.ttf'
import './assets/fonts/Mulish/static/Mulish-Regular.ttf'
import { Redirect } from './components/Redirect'
import { Onboarding } from './layouts/account/onboarding'
import { Properties } from './layouts/properties'

const App = () => {
	return (
		<Router>
			<AuthContextProvider>
				<MainContextProvider>
					<AppView>
						<Routes>
							<Route
								path='/'
								element={<Redirect to={'/account'} />}
							/>
							{/* <Route
								path='/account'
								element={<Account />}
							/> */}
							<Route
								path='/account/*'
								element={<Account />}
							/>
							<Route
								path='/dashboard/*'
								element={<Dashboard />}
							/>
							<Route
								path='/onboarding/*'
								element={<Onboarding />}
							/>
							<Route
								path='/properties/*'
								element={<Properties />}
							/>
							<Route
								path='/404'
								element={<NotFound />}
							/>
							<Route
								path='/*'
								element={<UnknownRouteHandler />}
							/>
						</Routes>
					</AppView>
				</MainContextProvider>
			</AuthContextProvider>
		</Router>
	)
}

export default App
