import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { InputField } from '../../../../components/InputField'
import { useAuth, useMain } from '../../../../hooks'
import a from '../../../../styles/Account.module.css'
import o from '../../../../styles/Onboarding.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf } from '../../../../utils'

const Step = () => {
	const [requestBody, setRequestBody] = useState({})
	const [isDisabled, setIsDisabled] = useState(true)
	const { setCurrentStep } = useMain()
	const { doUpdateKYC, uncompleted } = useAuth()
	const navigate = useNavigate()
	const [rangeOptions] = useState([
		{ value: '199999', label: 'Less than R200,000' },
		{ value: '499999', label: 'R200,000 - R500,000' },
		{ value: '999999', label: 'R500,001 - R1,000,000' },
		{ value: '2000000', label: 'Greater than R1,000,001' },
	])

	useLayoutEffect(() => {
		setCurrentStep((x) => 4)
		if (uncompleted[4]) {
			navigate('/account/kyc/investment')
		}
		return () => {
			setCurrentStep((x) => 4)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uncompleted])

	// const annualHandler = (x) =>
	// 	setRequestBody((e) => ({
	// 		...e,
	// 		annual: x,
	// 	}))

	// const householdHandler = (x) =>
	// 	setRequestBody((e) => ({
	// 		...e,
	// 		household: x,
	// 	}))

	// const investmentsHandler = (x) =>
	// 	setRequestBody((e) => ({
	// 		...e,
	// 		investments: x,
	// 	}))

	const annualHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			annual: x,
		}))

	const householdHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			household: x,
		}))

	const investmentsHandler = (x) =>
		setRequestBody((e) => ({
			...e,
			investments: x,
		}))

	const handleSubmitHandler = async (e) => {
		e.preventDefault()
		let i = 0
		const keys = Object.keys(requestBody)
		const length = keys.length,
			reqBody = {}
		for (i; i < length; i++) {
			const el = requestBody[keys[i]]
			if (typeof el === 'object' && el.hasOwnProperty('value')) {
				reqBody[keys[i]] = el.value
			} else {
				reqBody[keys[i]] = el
			}
			if (Array.isArray(el)) {
				reqBody[keys[i]] = []
				let o = 0
				const keys_ = Object.keys({ label: true, value: true })
				const length = keys_.length
				for (o; o < length; o++) {
					if (typeof el[o] === 'object' && el[o].hasOwnProperty('value')) {
						reqBody[keys[i]].push(el[o].value)
					}
				}
			}
		}
		const { annual, household, investments } = reqBody
		const body = {
			annualIncome: Number(annual),
			householdIncome: Number(household),
			investmentsValue: Number(investments),
		}
		doUpdateKYC(body)
	}

	useEffect(() => {
		setIsDisabled(
			(x) =>
				!(
					requestBody['annual'] &&
					requestBody['household'] &&
					requestBody['investments']
				)
		)
	}, [requestBody])

	return (
		<div className={cf(s.flex, s.flexCenter, a.accountChild)}>
			<h1 className={cf(s.wMax, s.tLeft, s.m0, s.p0, a.theme)}>
				Financial Information
			</h1>
			<span className={cf(s.wMax, s.flex, s.flexLeft, s.tLeft, o.underTheme)}>
				Provide the following information to continue.
			</span>

			<form
				className={cf(s.wMax, s.flex, s.flexCenter, a.form)}
				onSubmit={handleSubmitHandler}
			>
				<div
					className={cf(
						s.wMax,
						s.flex,
						s.flex_dColumn,
						s.flexCenter,
						a.inputFieldCon
					)}
				>
					{/* <InputField
						tag={'annual'}
						state={requestBody}
						handler={annualHandler}
						type={'select'}
						selectOptions={optionsAnnual}
						placeholder={'Annual Income'}
					/>
					<InputField
						tag={'household'}
						state={requestBody}
						handler={householdHandler}
						type={'select'}
						selectOptions={optionsHousehold}
						placeholder={'Household Income'}
					/>
					<InputField
						tag={'investments'}
						state={requestBody}
						handler={investmentsHandler}
						type={'select'}
						selectOptions={optionsInvestments}
						placeholder={'Value of current investments'}
					/> */}
					<InputField
						tag={'annual'}
						state={requestBody}
						handler={annualHandler}
						placeholder={'Annual Income'}
						type={'select'}
						selectOptions={rangeOptions}
					/>
					<InputField
						tag={'household'}
						state={requestBody}
						handler={householdHandler}
						placeholder={'Household Income'}
						type={'select'}
						selectOptions={rangeOptions}
					/>
					<InputField
						tag={'investments'}
						state={requestBody}
						handler={investmentsHandler}
						placeholder={'Value of current investments'}
						type={'select'}
						selectOptions={rangeOptions}
					/>
				</div>

				<button
					type={'submit'}
					className={cf(
						s.wMax,
						s.flex,
						s.flexCenter,
						a.submitBtn,
						!isDisabled ? s.shadow : '',
						isDisabled ? cf(a.proceedDisabled, s.pointerDisabled) : ''
					)}
					disabled={isDisabled}
				>
					{isDisabled ? 'Fill the form' : 'Continue'}
				</button>

				<div
					className={cf(s.wMax, s.flex, s.flexLeft, a.rememberToForget, s.p5)}
				>
					<span className={cf(s.wMax, s.dInlineBlock, s.tLeft, a.notice)}>
						The information provided would be saved securely as per our&nbsp;
						<Link
							to={'https://cribvest.com/terms-and-conditions/'}
							className={cf(s.link)}
						>
							Terms of Service
						</Link>
						&nbsp;&&nbsp;
						<Link
							to={'https://cribvest.com/privacy-policy/'}
							className={cf(s.link)}
						>
							Privacy Policy
						</Link>
					</span>
				</div>
			</form>

			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='482'
				height='2'
				viewBox='0 0 482 2'
				fill='none'
				className={cf(s.wMax, a.strike)}
			>
				<path
					d='M0 1H482'
					stroke='#B2B2B2'
					strokeWidth='0.5'
				/>
			</svg>

			<span className={cf(s.wMax, s.flex, s.flexCenter, s.tCenter, a.instead)}>
				Want to fill this later?&nbsp;
				<Link
					to={'/account/kyc/investment'}
					className={cf(s.flex, a.alt)}
				>
					Skip this step
				</Link>
			</span>
		</div>
	)
}

export default Step
