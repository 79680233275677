import React from 'react'
import emptyTeller from '../../assets/svg/emptyTeller.svg'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import Transaction from './Transaction'

const Transactions = ({ txns }) => {
	return (
		<div
			className={cf(
				s.wMax,
				s.flex,
				s.flex_dColumn,
				s.flexLeft,
				d.txnsParent,
				d.txnsParent_,
				d.tBorder25,
				!txns.length ? d.bgBlank : ''
			)}
		>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.flex_dColumn,
					s.flexLeft,
					d.txnsCon,
					d.txnsCon_
				)}
			>
				{txns.length ? (
					txns.map((el, i) => (
						<Transaction
							txn={el}
							key={i}
						/>
					))
				) : (
					<div className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQuery)}>
						<img
							src={emptyTeller}
							alt={'empty teller'}
							className={cf(s.flex, s.flexCenter, d.emptyRealEstateMini)}
						/>
						<div className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQueryTxt)}>
							<span
								className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQueryTitle)}
							>
								No transaction yet
							</span>
							<span className={cf(s.flex, s.flexCenter, d.emptyQueryMessage)}>
								You haven’t made any transaction yet. All transaction performed
								on your account would be displayed here.
							</span>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Transactions
