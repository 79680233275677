import React from 'react'
import { useAuth, useMain } from '../../hooks'
import d from '../../styles/Dashboard.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'
import { ViewAll } from '../ViewAll'
import Transaction from './Transaction'

const Transactions = () => {
	const { userDashboard } = useAuth()
	const { isSmall } = useMain()

	return (
		<div
			className={cf(s.wMax, s.flex, s.flex_dColumn, s.flexLeft, d.txnsParent)}
		>
			<div
				className={cf(
					s.wMax,
					s.flex,
					s.spaceXBetween,
					s.spaceYCenter,
					d.txnsTitleCon
				)}
			>
				<span className={cf(s.flex, d.txnsTitle)}>Recent Transactions</span>
				<ViewAll to={'/dashboard/transactions'} />
			</div>
			{userDashboard.transactions && userDashboard.transactions.length ? (
				<div
					className={cf(s.wMax, s.flex, s.flex_dColumn, s.flexLeft, d.txnsCon)}
				>
					{userDashboard.transactions
						.filter((el, i) => i < (isSmall ? 5 : 3))
						.map((el, i) => (
							<Transaction
								txn={el}
								key={i}
							/>
						))}
				</div>
			) : (
				<div className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQuery)}>
					<div className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQueryTxt)}>
						<span
							className={cf(s.wMax, s.flex, s.flexCenter, d.emptyQueryTitle)}
						>
							No transaction yet
						</span>
						<span className={cf(s.flex, s.flexCenter, d.emptyQueryMessage)}>
							You haven’t made any transaction yet. All recent transaction
							performed on your account would be displayed here.
						</span>
					</div>
				</div>
			)}
		</div>
	)
}

export default Transactions
