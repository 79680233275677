import React, { useState } from 'react'
import roulette from '../../assets/svg/roulette.svg'
import { useMain } from '../../hooks'
import p from '../../styles/Properties.module.css'
import s from '../../styles/Shared.module.css'
import { cf } from '../../utils'

const LoadMore = () => {
	const [loading, setLoading] = useState(false)
	const { getMoreProperties: handler } = useMain()
	const handleClick = async () => {
		setLoading((x) => true)
		await handler()
		setLoading((x) => false)
	}

	return (
		<button
			className={cf(s.flex, s.flexCenter, p.loadMore, loading ? p.loading : '')}
			onClick={handleClick}
		>
			<span className={cf(s.flex, s.flexCenter, p.loadMoreTxt)}>Load more</span>
			<img
				src={roulette}
				alt={'load more icon'}
				className={cf(s.flex, s.flexCenter, p.loadMoreIcon)}
			/>
		</button>
	)
}

export default LoadMore
