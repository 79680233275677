import React from 'react'
import d from '../../../../styles/Dashboard.module.css'
import s from '../../../../styles/Shared.module.css'
import { cf } from '../../../../utils'
import { Base } from './Base'
import { ChangePassword } from './ChangePassword'

const Privacy = ({ view, setView }) => {
	return view === 'base' ? (
		<Base {...{ setView }} />
	) : view === 'changePassword' ? (
		<ChangePassword {...{ setView }} />
	) : view === 'twoFactorAuth' ? (
		<div className={cf(s.flex, s.flexCenter, d.settingsView)}></div>
	) : (
		<></>
	)
}

export default Privacy
